import React from "react";

import { ComponentSize, componentSizes } from "src/types";
import classes from "./Tag.module.scss";

type Props = {
  children: React.ReactNode;
  variant?: "green" | "grey" | "red" | "blue";
  size?: ComponentSize;
  backgroundColor?: string;
  color?: string;
};

const UnmemoTag: React.FC<Props> = ({
  children,
  variant = "grey",
  size = componentSizes.regular,
  ...rest
}) => {
  return (
    <span
      className={`${classes.tag} ${classes[variant]} ${classes[size]}`}
      style={{ ...rest }}
    >
      {children}
    </span>
  );
};

export const Tag = React.memo(UnmemoTag);
