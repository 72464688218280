import React, { ChangeEvent } from "react";
import { SelectInput } from "src/components";
import { CABINS } from "src/configs";
import { ActionType, useSearchFlightContext } from "src/context";
import { Cabin } from "src/types/travelPolicy";

type Props = {
  disabled?: boolean;
};

const UnmemoFlightMaxCabinInput: React.FC<Props> = ({ disabled = false }) => {
  const { dispatch, state } = useSearchFlightContext();

  const onClassChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    dispatch({
      type: ActionType.updateCabin,
      payload: value as Cabin,
    });
  };

  return (
    <SelectInput
      name="flightMaxCabinClass"
      required
      defaultValue={state.cabin}
      onChange={onClassChange}
      options={CABINS}
      disabled={disabled}
      icon={"cabin"}
    />
  );
};

export const FlightMaxCabinInput = React.memo(UnmemoFlightMaxCabinInput);
