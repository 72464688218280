import React from "react";
import { Line, SideDrawer, Text } from "src/components";
import { FareCondition, FlightRouteDetailed } from "src/sections";
import { FlightByIDResponse, LegDescription } from "src/types/flight";
import { formatDateInMonth } from "src/utils";
import classes from "./FilterDetail.module.scss";

type Props = Omit<
  React.ComponentProps<typeof SideDrawer>,
  "title" | "children" | "isActive"
> & {
  flight: FlightByIDResponse["itineraryDetails"];
  legDescriptions: LegDescription[];
  selectFlight?: () => void;
  onApprove?: () => void;
  onReject?: () => void;
};

const UnmemoFilterDetail: React.FC<Props> = ({
  flight,
  legDescriptions,
  selectFlight,
  onApprove,
  onReject,
  ...rest
}) => {
  return (
    <SideDrawer
      title="Flight Details"
      isActive={Boolean(flight)}
      cta={
        selectFlight
          ? { name: "Select", action: selectFlight }
          : onApprove
          ? { name: "Approve", action: onApprove }
          : undefined
      }
      secondaryCta={onReject ? { name: "Reject", action: onReject } : undefined}
      {...rest}
    >
      {flight?.itinerary.originDestinationOptions.originDestinationOption.map(
        ({ flightSegments, layoverSummary }, i) => (
          <div key={i}>
            <Text tag="h3" className={classes.date}>
              {formatDateInMonth(legDescriptions[i].departureDate)}
            </Text>
            {flightSegments.map((segment, j) => (
              <FlightRouteDetailed
                key={`key${j}`}
                {...segment}
                layover={layoverSummary[j] ? layoverSummary[j] : undefined}
                departureDate={legDescriptions[i].departureDate}
              />
            ))}
            <Line />
          </div>
        )
      )}

      {flight && flight.penaltyInfo && (
        <>
          <FareCondition {...flight.penaltyInfo} bags={flight.baggageInfo} />
          <Line />
        </>
      )}
    </SideDrawer>
  );
};

export const FilterDetail = React.memo(UnmemoFilterDetail);
