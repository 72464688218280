import { createApprovalProcess } from "./createApprovalProcess";
import { deleteApprovalProcess } from "./deleteApprovalProcess";
import { editApprovalProcess } from "./editApprovalProcess";
import { getApprovalProcessByID } from "./getApprovalProcessByID";
import { getApprovalProcesses } from "./getApprovalProcesses";
import { searchApprovalProcess } from "./searchApprovalProcess";

export const ApprovalProcessService = {
  create: createApprovalProcess,
  update: editApprovalProcess,
  get: getApprovalProcesses,
  getByID: getApprovalProcessByID,
  delete: deleteApprovalProcess,
  search: searchApprovalProcess,
};
