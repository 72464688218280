import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { DropDown, Image, Input, Text } from "src/components";
import { BasicLocation as Location } from "src/types/location";

import { assets } from "src/configs";
import { LocationService } from "src/services";
import { componentSizes } from "src/types";
import classes from "./LocationInput.module.scss";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type Props = InputProps & {
  selectedLocation: Location | null;
  onInputChange: (location: Location) => void;
  isFlightFrom?: boolean;
};

const UnmemoLocationInput: React.FC<Props> = ({
  onInputChange,
  selectedLocation,
  isFlightFrom = true,
  disabled,
  value,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const [location, setLocation] = useState<Location | null>(selectedLocation);
  const [searchedLocations, setSearchedLocations] = useState<Location[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>(
    selectedLocation?.cityName || ""
  );

  const [isActive, setActive] = useState(false);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const saveLocations = async () => {
    setIsFetching(true);
    const response = await LocationService.search({ location: searchQuery });

    if (response.success && response.locations) {
      setSearchedLocations(
        response.locations.map(
          ({ cityName, airportIATA_Code, countryName, airportName }) => ({
            cityName: `${cityName} (${airportIATA_Code})`,
            code: airportIATA_Code,
            countryName: countryName,
            airportName: airportName,
          })
        )
      );
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (searchQuery.length > 1) {
      saveLocations();
    } else {
      setSearchedLocations([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);
    setLocation({
      cityName: value,
      code: "",
    });
  };

  useEffect(() => {
    if (searchedLocations.length > 0) setActive(true);
    else setActive(false);
  }, [searchQuery, searchedLocations]);

  useEffect(() => {
    if (location) {
      onInputChange(location);
      if (location.code !== "" && !location.countryName) {
        setSearchQuery(location.cityName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const locationOptions = [
    // Add the disabled option as the first item
    {
      name: "Select a location",
      props: { value: "", disabled: true, selected: true },
    },
    ...searchedLocations.map(
      ({ cityName, code, countryName, airportName }) => ({
        name: (
          <>
            <Text tag="h5">{cityName}</Text>
            {airportName && (
              <Text size={componentSizes.small}>{airportName}</Text>
            )}
            {countryName && (
              <Text size={componentSizes.small}>{countryName}</Text>
            )}
          </>
        ),
        props: { value: code },
      })
    ),
  ];

  return (
    <div className={classes.wrapper} ref={ref}>
      <Input
        type="text"
        icon={isFlightFrom ? "flightFrom" : "flightTo"}
        onChange={onChange}
        onFocus={onChange}
        value={value}
        {...rest}
        disabled={disabled}
      />
      {searchedLocations.length > 0 && !disabled && (
        <DropDown
          isActive={isActive}
          onClick={(value: any) => {
            setLocation(
              searchedLocations.find(({ code }) => code === value) || null
            );
            setActive(false);
          }}
          options={locationOptions}
        />
      )}
      {isFetching && (
        <div className={classes.spinner}>
          <Image src={assets.spinner} width={20} height={20} alt="" />
        </div>
      )}
    </div>
  );
};

export const LocationInput = React.memo(UnmemoLocationInput);
