import React, { useState } from "react";
import { FormGroup, Popup, SelectInput } from "src/components";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "src/configs";
import { useAppContext, useLoadingContext, useLoginContext } from "src/context";
import { TravelPolicyService } from "src/services";
import { AssignTravelPolicyParams } from "src/types/travelPolicy";
import { onInputChange } from "src/utils";

type Props = {
  policyId?: number;
  onHide: () => void;
};

const UnmemoAssignPolicy: React.FC<Props> = ({ onHide, policyId }) => {
  const { loading } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();
  const { user } = useLoginContext();

  const [assignFormData, setAssignFormData] =
    useState<AssignTravelPolicyParams>({
      userIds: user.travelers[0].id,
      policyId:
        policyId && policyId !== 0 ? policyId : user.travelPolicies[0].id,
    });

  const assignPolicyToUser = async () => {
    loading.start();
    if (assignFormData.userIds && assignFormData.policyId) {
      const response = await TravelPolicyService.assign({
        ...assignFormData,
      });
      if (response.success)
        showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
      else showError(response.message ? response.message : ERROR_MESSAGE);
    } else {
      showError("Please fill all fields!");
    }
    loading.stop();
  };

  return (
    <Popup
      title="Assign Policy"
      onClose={{ ctaAction: onHide }}
      cta={{ ctaName: "Assign", ctaAction: assignPolicyToUser }}
    >
      <FormGroup>
        <SelectInput
          name="userIds"
          required
          defaultValue={assignFormData.userIds}
          placeholder="Enter User Name/Email"
          onChange={(e) => onInputChange(e, assignFormData, setAssignFormData)}
          options={
            user && user.travelers
              ? user.travelers.map((traveler) => ({
                  name: `${traveler.firstName} ${traveler.lastName}`,
                  props: { value: traveler.id },
                }))
              : []
          }
        />
      </FormGroup>

      {user.travelPolicies.length > 0 && (
        <FormGroup>
          <SelectInput
            name="policyId"
            required
            defaultValue={assignFormData.policyId}
            placeholder="Travel Policy to Assign"
            onChange={(e) =>
              onInputChange(e, assignFormData, setAssignFormData)
            }
            options={
              user && user.travelPolicies
                ? user.travelPolicies.map((policy) => ({
                    name: policy.name,
                    props: { value: policy.id },
                  }))
                : []
            }
          />
        </FormGroup>
      )}
    </Popup>
  );
};

export const AssignPolicy = React.memo(UnmemoAssignPolicy);
