import { Tag, TravelerList } from "src/components";

import React from "react";
import { TravelPolicyFull, colorsMapper } from "src/types/travelPolicy";

export type TravelPolicyTable = {
  key: TravelPolicyFull;
  id: TravelPolicyFull["id"];
  name: React.ReactNode;
  createdAt: TravelPolicyFull["createdAt"];
  travelers: React.ReactNode;
};

const createTravelNode = (travelPolicy: TravelPolicyFull): React.ReactNode => {
  const { travelers } = travelPolicy;

  return <TravelerList travelers={travelers} />;
};

export const transformTravelPolicyList = (list: TravelPolicyFull[]) => {
  const transformedList: TravelPolicyTable[] = list.map((travelPolicy) => {
    const color = colorsMapper[travelPolicy.themeColor] || undefined;
    return {
      key: travelPolicy,
      id: travelPolicy.id,
      name: (
        <Tag
          variant="grey"
          backgroundColor={color ? color.bg : undefined}
          color={color ? color.color : undefined}
        >
          {travelPolicy.name}
        </Tag>
      ),
      createdAt: travelPolicy.createdAt,
      travelers: createTravelNode(travelPolicy),
    };
  });

  return transformedList;
};
