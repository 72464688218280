import React from "react";

import { useNavigate } from "react-router-dom";
import { Icon, Link, ProfileImage, Text } from "src/components";
import { icons, pageLinks } from "src/configs";
import { useLoginContext } from "src/context";
import { componentSizes } from "src/types";
import classes from "./ProfileDropdown.module.scss";

type DDLink = {
  title: string;
  link: string;
  icon: string;
  title2: string;
  link2:string;
  icon2: string;
};  



const LINKS: DDLink[] = [
  {
    title: pageLinks.setting.name,
    link: pageLinks.setting.link,
    icon: icons.setting.color,
    title2: pageLinks.loyalty.name,
    link2: pageLinks.loyalty.link,
    icon2: icons.loyalty.color,
  },
];

type Props = {
  isVisible: boolean;
};

const UnmemoProfileDropdown: React.FC<Props> = ({ isVisible }) => {
  const { logout: onLogout, user } = useLoginContext();
  const navigate = useNavigate();

  if (!isVisible || !user) {
    return <></>;
  }

  const logout = () => {
    onLogout();
    navigate(pageLinks.login.link);
  };

  const { profilePictureUrl, firstName, lastName, email } = user;

  return (
    <div className={classes.dropdown}>
      <div className={classes.detail}>
        <div className={classes.img}>
          <ProfileImage
            size={componentSizes.medium}
            name={`${firstName} ${lastName}`}
            profilePictureUrl={profilePictureUrl}
          />
        </div>
        <Text tag="h5">
          {firstName} {lastName}
        </Text>
        <Text tag="h6">{email}</Text>
      </div>
      {LINKS.map(({ title, link, icon, title2, link2, icon2  }, i) => (
        <>
         <Link to={link2} className={classes.link} key={i}>
         <Icon src={icon2} />
         <Text tag="h5">{title2}</Text>
       </Link>
        <Link to={link} className={classes.link} key={i}>
          <Icon src={icon} />
          <Text tag="h5">{title}</Text>
        </Link>
        
       </>
      ))}
      <div className={classes.logout}>
        <button className={classes.link} onClick={logout}>
          <Icon src={icons.logout.color} />
          <Text tag="h5">Logout</Text>
        </button>
      </div>
    </div>
  );
};

export const ProfileDropdown = React.memo(UnmemoProfileDropdown);
