import React from "react";
import { Image, Link } from "src/components";
import {
  NAV_LINKS,
  NAV_LINKS_LIMITED,
  app,
  logo,
  pageLinks,
} from "src/configs";

import { useLoginContext, useSearchFlightContext } from "src/context";
import { componentSizes, componentVariants } from "src/types";
import classes from "./Header.module.scss";
import { Profile } from "./component";

type Props = {
  isBGWhite?: boolean;
};

const UnmemoHeader: React.FC<Props> = ({ isBGWhite = false }) => {
  const { isLogin } = useLoginContext();
  const { state } = useSearchFlightContext();

  const { isAdmin } = state;

  if (isLogin) {
    return (
      <div
        className={`${classes.header} ${classes.loginNav} ${
          isBGWhite ? classes.bgWhite : ""
        }`}
      >
        <div className={classes.logo}>
          <Link to={pageLinks.home.link}>
            <Image
              src={isBGWhite ? logo.white : logo.colored}
              alt={`${app.name} logo`}
              className={classes.logo}
            />
          </Link>
        </div>
        <div className={classes.details}>
          <div className={classes.nav}>
            {(isAdmin ? NAV_LINKS : NAV_LINKS_LIMITED).map(
              ({ title, link }, i) => (
                <Link
                  to={link}
                  variant={componentVariants.secondary}
                  size={componentSizes.small}
                  key={i}
                  className={
                    window.location.pathname === link ? `${classes.active}` : ""
                  }
                >
                  {title}
                </Link>
              )
            )}
          </div>
          {/* <Notification isBGWhite={isBGWhite} /> */}
          <Profile isBGWhite={isBGWhite} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.header}>
      <div className={classes.logo}>
        <Link to={pageLinks.home.link}>
          <Image
            src={isBGWhite ? logo.white : logo.colored}
            alt={`${app.name} logo`}
          />
        </Link>
      </div>
    </div>
  );
};

export const Header = React.memo(UnmemoHeader);
