import { BasicResponse, OrderBy, Pagination } from ".";
import {
  BookingStatus,
  GetTravelerCheckoutResponse,
  OrderTotal,
} from "./checkout";
import { FlightByIDResponse, GroupDescription, ItineraryGroup } from "./flight";
import { Cabin, Colors } from "./travelPolicy";

export const roles = {
  corporateAdmin: "Corporate Admin",
  travelerManager: "Travel Manager",
  traveler: "Traveler",
  guest: "Guest",
} as const;

export const rolesSystemName = {
  corporateAdmin: "CorporateAdmin",
  travelManager: "TravelManager",
  traveler: "Traveler",
  guest: "Guest",
} as const;

export type Role = (typeof roles)[keyof typeof roles];

export const status = {
  active: "A",
  inActive: "I",
} as const;

export type Status = (typeof status)[keyof typeof status];

export type Traveler = {
  firstName: string;
  lastName: string;
  email: string;
  roleName: Role;
  profilePictureUrl?: string;
  id: number;
  status: Status;
  policyId: number;
  phoneNumber: string;
  title: string;
  birthDate: string;
};

export type TravelerInformation = Traveler & {
  title?: string;
  gender: string;
  birthDate?: string;
  phoneNumber?: string;
  roleId: number;
  status: string;
};

export type TravelersListResponse = {
  results?: Pagination & {
    travelers: Traveler[];
  };
  success: boolean;
  message?: string;
};

export type TravelersDeleteParams = {
  ids: number[];
};

export type TravelersDeleteResponse = {
  success: boolean;
  message: string;
};

export type TravelersDeactivateParams = {
  ids: number[];
};

export type TravelersDeactivateResponse = {
  success: boolean;
  message: string;
};

export type TravelersActivateParams = {
  ids: number[];
};

export type TravelersActivateResponse = {
  success: boolean;
  message: string;
};

export type TravelPolicyOverview = {
  id: number;
  name: string;
  isDefault: boolean;
  flightDynamicBudget: string;
  flightIsRefundable: boolean;
  flightMaxCabinClass: string;
  flightMaxCabinClassLongerLeg: Cabin;
  themeColor: Colors;
  flightExtrasRules: {
    preferredAirlines: {
      code: string;
      name: string;
    }[];
    restrictedAirlines: {
      code: string;
      name: string;
    }[];
  };
  travelers?: {
    id: number;
    name: string;
    profilePictureUrl: string;
  }[];
};

export type TripsOverview = {
  itineraryGroup: ItineraryGroup;
  departureDate: string;
  returnDate?: string;
  status: BookingStatus;
};

export type TravelerDetails = {
  success: boolean;
  message?: string;
  travelerInfo?: {
    accountInformation: TravelerInformation;
    travelPolicyOverview: TravelPolicyOverview;
    tripsOverview: TripsOverview[];
    accountSettings: {
      groups: [];
      approvalProcesses: [];
      travelPolicy: {
        name: string;
        id: number;
      };
      id: 0;
    };
  };
};

export type TravelerInviteParams = {
  emails: string;
  roleName: string;
  travelPolicyId: number;
  approvalProcessId?: number;
  haveAccessToTripCater?: boolean;
};

export type TravelerInviteResponse = {
  success: boolean;
  message: string;
};

export type TravelerSearchParams = {
  name?: string;
  showActiveOnly?: boolean;
  roleName?: string;
  pageIndex?: number;
  pageSize?: number;
  sortBy?: string;
  orderBy?: OrderBy;
};

export type TripInfo = {
  id: number;
  itineraryGroup: ItineraryGroup;
  travelerDetails: {
    id: Traveler["id"];
    name: string;
    profilePictureUrl: Traveler["profilePictureUrl"];
  }[];
  departureDate: string;
  returnDate?: string;
  tripCustomNumber?: string;
  createDate?: string;
  changeRequestInfo: {
    requestSubmitted: boolean;
  };
  status: string;
  approvalInfo: {
    canTakeAction: boolean;
  };
};

export type TripsResponse = {
  success: boolean;
  message?: string;
  result?: Pagination & {
    tripInfo: TripInfo[];
  };
};

export type TripsParams = {
  status: BookingStatus;
  pageIndex: number;
  pageSize: number;
};

export type TripByIDResponse = BasicResponse & {
  orderSummary?: {
    id: number;
    orderTotal: OrderTotal;
    travelersInfo: GetTravelerCheckoutResponse["travelers"];
    itineraryDetails: FlightByIDResponse["itineraryDetails"];
    groupDescription: GroupDescription[];
    approvalInfo: {
      canTakeAction: boolean;
    };
  };
};

export const tripStatus = {
  approve: "approve",
  reject: "reject",
} as const;

export type TripStatus = (typeof tripStatus)[keyof typeof tripStatus];

export type TripUpdateParams = {
  id: number;
  status: TripStatus;
  rejectionReason?: string;
};

export const changeRequestType = {
  cancel: "CANCELATION",
  amend: "AMENDMENT",
} as const;

export type ChangeRequestType =
  (typeof changeRequestType)[keyof typeof changeRequestType];

export type ChangeRequestParams = {
  tripId: number;
  type: ChangeRequestType;
  description: string;
};

export type AddTravelerParams = {
  title: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  roleName: string;
};
