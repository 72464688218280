import React from "react";
import { Icon, Image, Tag, Text } from "src/components";
import { flightImagesURL, icons } from "src/configs";
import { componentSizes } from "src/types";
import { FlightSegment, LayoverSummary } from "src/types/flight";
import classes from "./FlightRouteDetailed.module.scss";
import { getAdjustedDate } from "./utils";

type Props = FlightSegment & {
  layover?: LayoverSummary;
  departureDate: string;
};

const UnmemoFlightRouteDetailed: React.FC<Props> = ({
  flightCode,
  carrier,
  flightNumber,
  destination,
  origin,
  layover,
  duration,
  departureDateAdjustment,
  departureDate,
}) => {
  const destinationDateAdjustment = getAdjustedDate(
    departureDate,
    destination.dateAdjustment
  );
  const originDateAdjustment = getAdjustedDate(
    departureDate,
    departureDateAdjustment
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Image
          src={`${flightImagesURL}${flightCode}.png`}
          className={classes.img}
        />
        <div className={classes.content}>
          <div className={classes.row}>
            <Text tag="h4">{carrier.marketingAirline.name}</Text>
            <Tag size={componentSizes.small} variant="blue">
              {carrier.marketingAirline.iataCode} {flightNumber}
            </Tag>
            <Tag size={componentSizes.small} variant="green">
              {carrier.airCraftCode}
            </Tag>
          </div>
          <div className={classes.row}>
            <Text tag="h6">Operated by {carrier.operatingAirline.name}</Text>
          </div>
        </div>
      </div>
      <div className={classes.detail}>
        <div className={classes.row}>
          <div className={classes.image}>
            <Icon src={icons.takeoff.color} />
          </div>
          <div className={classes.content}>
            <Text tag="h4">
              {origin.time}{" "}
              {departureDateAdjustment > 0 && (
                <Tag size={componentSizes.small} variant="red">
                  Departure on {originDateAdjustment}
                </Tag>
              )}
            </Text>
            <Text tag="h6">
              {origin.airportName} ({origin.city})
            </Text>
            {origin.terminal && (
              <Text tag="h6">Terminal: {origin.terminal}</Text>
            )}
          </div>
        </div>
        <div className={classes.row}>
          <Text tag="h6">Duration: {duration}</Text>
        </div>
        <div className={classes.row}>
          <div className={classes.image}>
            <Icon src={icons.land.color} />
          </div>
          <div className={classes.content}>
            <Text tag="h4">
              {destination.time}{" "}
              {destination.dateAdjustment > 0 && (
                <Tag size={componentSizes.small} variant="red">
                  Arrives on {destinationDateAdjustment}
                </Tag>
              )}
            </Text>
            <Text tag="h6">
              {destination.airportName} ({destination.city})
            </Text>
            {destination.terminal && (
              <Text tag="h6">Terminal: {destination.terminal}</Text>
            )}
          </div>
        </div>
      </div>
      {layover && (
        <div className={classes.layoverRow}>
          <Text tag="h6" className={classes.layoverText}>
            {layover.duration} - Layover in {layover.airport} ({layover.city})
          </Text>
        </div>
      )}
    </div>
  );
};

export const FlightRouteDetailed = React.memo(UnmemoFlightRouteDetailed);
