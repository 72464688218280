import React, { ChangeEvent, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  FormGroup,
  Icon,
  Input,
  Label,
  Text,
  Toggle,
  UsersInput,
} from "src/components";
import { ERROR_MESSAGE, SUCCESS_MESSAGE, icons, pageLinks } from "src/configs";
import { useAppContext, useLoadingContext, useLoginContext } from "src/context";
import { ApprovalProcessService } from "src/services";
import { componentVariants } from "src/types";
import {
  ApprovalProcessRules,
  CreateApprovalProcessParams,
  approvalAction,
  approvalCondition,
} from "src/types/approval";
import { TravelPolicyFull } from "src/types/travelPolicy";
import { Traveler } from "src/types/traveler";
import { ApprovalRules } from "../ApprovalRules";
import classes from "./ProcessForm.module.scss";

const UnmemoProcessForm: React.FC = () => {
  const location = useLocation();
  const urlQuery = new URLSearchParams(location.search);

  const editId = urlQuery.get("id");

  const { loading } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();
  const { updatePolicies, user } = useLoginContext();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<CreateApprovalProcessParams>({
    name: "",
    approvalNeeded: false,
    approvalProcessRules: [
      {
        action: approvalAction.notify,
        condition: approvalCondition.allTrips,
        reviewerId: user.travelers.length > 0 ? user.travelers[0].id : 0,
        reviewerName: "",
        id: 0,
      },
    ],
  });

  const onApprovalRuleChange = (
    rule: ApprovalProcessRules,
    indexNo: number
  ) => {
    const updatedFormData = { ...formData };
    updatedFormData.approvalProcessRules[indexNo] = rule;
    setFormData(updatedFormData);
  };

  const onDelete = (indexNo: number) => {
    const updatedFormData = { ...formData };
    updatedFormData.approvalProcessRules.splice(indexNo, 1);
    setFormData(updatedFormData);
  };

  const onCreateRule = () => {
    const updatedFormData = { ...formData };
    const newRule = {
      action: approvalAction.notify,
      condition: approvalCondition.allTrips,
      reviewerId: user.travelers.length > 0 ? user.travelers[0].id : 0,
      reviewerName: "",
      id: 0,
    };

    updatedFormData.approvalProcessRules.push(newRule);

    setFormData(updatedFormData);
  };

  const getEditData = async (id: TravelPolicyFull["id"]) => {
    loading.start();

    const response = await ApprovalProcessService.getByID(id);

    if (response.success && response.results) {
      setFormData({ ...response.results });
    } else showError(response.message ? response.message : ERROR_MESSAGE);

    loading.stop();
  };

  useEffect(() => {
    if (editId) getEditData(Number(editId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onAssignUserChange = (users: Traveler[]) => {
    setFormData((prevData) => ({
      ...prevData,
      travelersIds: users.map(({ id }) => id),
    }));
  };

  const onCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const createProcess = async (e: any) => {
    e.preventDefault();
    loading.start();

    const response = editId
      ? await ApprovalProcessService.update({
          ...formData,
          id: Number(editId),
        })
      : await ApprovalProcessService.create({
          ...formData,
        });
    if (response.success) {
      showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
      updatePolicies();
    } else showError(response.message ? response.message : ERROR_MESSAGE);

    loading.stop();

    navigate(pageLinks.approvalProcess.link);
  };

  const onClose = () => {
    navigate(pageLinks.approvalProcess.link);
  };

  return (
    <div className={classes.formWrapper}>
      <form onSubmit={createProcess}>
        <FormGroup>
          <Label>Process Name</Label>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={onInputChange}
            placeholder="Enter Process Name"
            required
          />
        </FormGroup>

        <FormGroup>
          <Label isMainTitle>Is approval needed when booking?</Label>
          <Label>Choose if approval needed</Label>
        </FormGroup>

        <FormGroup>
          <div className={`${classes.horizontalLabel} ${classes.notFull}`}>
            <Text>No</Text>
            <Toggle
              name="approvalNeeded"
              checked={formData.approvalNeeded}
              onChange={onCheckChange}
            />
            <Text>Yes</Text>
          </div>
        </FormGroup>

        <FormGroup>
          <Label>Who is the approval process for?</Label>
          <UsersInput
            // value={formData.name}
            onUpdateUser={onAssignUserChange}
            selectedUsersDefault={
              formData.travelerDetails
                ? user.travelers
                    .filter(
                      (traveler) =>
                        formData.travelerDetails &&
                        formData.travelerDetails.some(
                          (td) => td.id === traveler.id
                        )
                    )
                    .map((traveler) => traveler.id)
                : []
            }
          />
        </FormGroup>

        <FormGroup>
          {formData.approvalNeeded ? (
            <>
              <Label isMainTitle>Approval Rules</Label>
              <Label>
                Add approval rules to define who should approve trips or receive
                email notifications about them.
              </Label>
            </>
          ) : (
            <>
              <Label isMainTitle>Notifications Settings</Label>
              <Label>
                Add notification rules to let others know when these travelers
                book trips.
              </Label>
            </>
          )}
        </FormGroup>

        {formData.approvalProcessRules.map((rule, i) => (
          <ApprovalRules
            key={i}
            indexNo={i}
            rule={rule}
            canDelete={formData.approvalProcessRules.length > 1}
            onChange={onApprovalRuleChange}
            onDelete={onDelete}
            isApprovalNeeded={formData.approvalNeeded}
          />
        ))}

        <FormGroup>
          <div className={classes.addRule} onClick={onCreateRule}>
            <Icon src={icons.add.color} />
            <Text>Add Rule</Text>
          </div>
        </FormGroup>

        <div className={classes.footer}>
          <Button
            variant={componentVariants.secondary}
            onClick={() => onClose()}
          >
            Close
          </Button>
          <Button type="submit">{editId ? "Update" : "Create"}</Button>
        </div>
      </form>
    </div>
  );
};

export const ProcessForm = React.memo(UnmemoProcessForm);
