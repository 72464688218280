import React from "react";

import { ComponentVariant, componentVariants } from "src/types";
import classes from "./Checkbox.module.scss";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type Props = InputProps & {
  variant?: ComponentVariant;
  label?: string | React.ReactNode;
};

const UnmemoCheckbox: React.FC<Props> = ({
  variant = componentVariants.primary,
  label,
  ...rest
}) => {
  return (
    <div className={`${classes.inputBox} ${classes[variant]}`}>
      <input {...rest} type="checkbox" autoComplete="off" />
      {label && <span className={classes.label}>{label}</span>}
      <span className={classes.checkbox}></span>
    </div>
  );
};

export const Checkbox = React.memo(UnmemoCheckbox);
