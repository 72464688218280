import React, { useContext, useState } from "react";
import { ScreenLock, Spinner } from "src/components";

type LoadingContextType = {
  isLoading: boolean;
  loading: {
    start: () => void;
    stop: () => void;
  };
  lockScreen: () => void;
  unlockScreen: () => void;
};

const defaultValues: LoadingContextType = {
  isLoading: false,
  loading: {
    start: () => {},
    stop: () => {},
  },
  lockScreen: () => {},
  unlockScreen: () => {},
};

const LoadingContext = React.createContext<LoadingContextType>(defaultValues);

type Props = {
  children: React.ReactNode;
};

export const LoadingProvider: React.FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLock, setIsLock] = useState<boolean>(false);

  const start = () => setIsLoading(true);

  const stop = () => setIsLoading(false);

  const lockScreen = () => setIsLock(true);

  const unlockScreen = () => setIsLock(false);

  return (
    <LoadingContext.Provider
      value={{ isLoading, loading: { start, stop }, lockScreen, unlockScreen }}
    >
      {/* {isLoading && <Spinner />} */}

      {isLock && <ScreenLock />}
      {children}
    </LoadingContext.Provider>
  );
};

const useLoadingContext = (): LoadingContextType => useContext(LoadingContext);

export { useLoadingContext };
