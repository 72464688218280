import React, { useState } from "react";
import { Checkbox, FormGroup } from "src/components";

type Props = {
  options: {
    name: string;
    value: string;
  }[];
  onChange: (selected: any[]) => void;
};

const UnmemoCheckboxFilter: React.FC<Props> = ({ options, onChange }) => {
  const [selected, setSelected] = useState<string[]>([]);

  const handleCheckboxChange = (
    value: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;

    const updatedSelected = isChecked
      ? [...selected, value]
      : selected.filter((item) => item !== value);

    setSelected(updatedSelected);
    onChange(
      updatedSelected.map((str) => {
        const parts = str.split("_");
        if (parts.length > 1) {
          return parts[1];
        } else {
          return "";
        }
      })
    );
  };

  return (
    <>
      {options.map(({ name, value }, i) => (
        <FormGroup key={i}>
          <Checkbox
            name={value}
            label={name}
            checked={selected.includes(value)}
            onChange={(event) => handleCheckboxChange(value, event)}
          />
        </FormGroup>
      ))}
    </>
  );
};

export const CheckboxFilter = React.memo(UnmemoCheckboxFilter);
