import React from "react";
import { Layout } from "src/Layout";
import { CenteredWrapper } from "src/components";
import { app, pageLinks } from "src/configs";
import { PageHeader, ProcessForm } from "./components";

const UnmemoCreateApprovalProcess: React.FC = () => {
  return (
    <Layout title={`${pageLinks.createApprovalProcess.name} - ${app.name}`}>
      <CenteredWrapper>
        <PageHeader />
        <ProcessForm />
      </CenteredWrapper>
    </Layout>
  );
};

export const CreateApprovalProcess = React.memo(UnmemoCreateApprovalProcess);
