const getToken = () => localStorage.getItem("user_token");

const removeToken = () => localStorage.removeItem("user_token");

const setToken = (token: string) => localStorage.setItem("user_token", token);

const isTokenValid = (message: string) => message !== "Invalid Token";

export const token = {
  get: getToken,
  remove: removeToken,
  set: setToken,
  isValid: isTokenValid,
};
