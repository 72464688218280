import axios from 'axios';
import { API_BASE_URL } from 'src/configs';
import { token } from 'src/utils';
import { TravelerLoyaltyPrograms } from 'src/types/loyalty';

export const updateTravelerLoyaltyPrograms = async (id: number, programNumber: number, programId: number): Promise<TravelerLoyaltyPrograms> => {
  try {
    const response = await axios.put<TravelerLoyaltyPrograms>(
      `${API_BASE_URL}traveler/loyalty-program/edit`,
      { id, programNumber, programId },
      {
        headers: {
          accept: 'application/json, text/plain, */*',
          authorization: `Bearer ${token.get()}`,
          'cache-control': 'no-cache',
          pragma: 'no-cache',
        }
      }
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      programs: [],
    };
  }
};
