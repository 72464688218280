import React from "react";
import { LocationInput } from "src/components";
import { ActionType, useSearchFlightContext } from "src/context";
import { BasicLocation } from "src/types/location";

type Props = {
  disabled?: boolean;
};

const UnmemoFlightToInput: React.FC<Props> = ({ disabled = false }) => {
  const { dispatch, state } = useSearchFlightContext();

  const onInputChange = (location: BasicLocation) => {
    dispatch({
      type: ActionType.updateFlightTo,
      payload: location,
    });
  };

  return (
    <LocationInput
      selectedLocation={state.flightTo}
      value={state.flightTo?.cityName}
      name="flightTo"
      isFlightFrom={false}
      onInputChange={onInputChange}
      placeholder="Flying To (City or Airport)"
      disabled={disabled}
    />
  );
};

export const FlightToInput = React.memo(UnmemoFlightToInput);
