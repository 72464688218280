import React from "react";

import classes from "./Label.module.scss";

type Props = {
  children: React.ReactNode | string;
  for?: string;
  isMainTitle?: boolean;
};

const UnmemoLabel: React.FC<Props> = ({
  children,
  isMainTitle = false,
  ...rest
}) => {
  return (
    <label
      {...rest}
      className={`${classes.label} ${isMainTitle ? classes.title : ""}`}
    >
      {children}
    </label>
  );
};

export const Label = React.memo(UnmemoLabel);
