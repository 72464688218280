import { OrderBy } from ".";
import { Cabin, TravelPolicy } from "./travelPolicy";
import { Traveler } from "./traveler";

export type FlightRoute = {
  locationCode?: string;
  date: string;
  time: string;
};

export type FlightSearchParams = {
  destinations: {
    departureDate: string;
    departureTime: string;
    fromPlace: {
      code: string;
    };
    toPlace: {
      code: string;
    };
  }[];
  directOnly: boolean;
  travelClasses: Cabin[];
  travelersIds: Traveler["id"][];
  policyId?: TravelPolicy["id"];
};

export type FlightFilterParams = {
  transactionId: string;
  cabinClass: Cabin;
  policyId?: TravelPolicy["id"];
  page: {
    number: number;
    resultsPerPage?: number;
  };
  sorting: FlightSearchSortBy;
  sortOrder?: OrderBy;
  travelPolicies?: boolean;
  stops?: number[];
  cabins?: Cabin[];
  hasBaggage?: boolean;
  airlines?: string[];
  time0?: number[];
  time1?: number[];
};

export const flightSearchStatus = {
  pending: "Pending",
  success: "Succeed",
  failed: "Failed",
} as const;

export type FlightSearchStatus =
  (typeof flightSearchStatus)[keyof typeof flightSearchStatus];

export const flightSearchSortBy = {
  best: "best",
  cheapest: "cheapest",
  fastest: "fastest",
} as const;

export type FlightSearchSortBy =
  (typeof flightSearchSortBy)[keyof typeof flightSearchSortBy];

export type LegDescription = {
  departureDate: string;
  departureLocation: string;
  arrivalLocation: string;
};

export type GroupDescription = {
  legDescriptions: LegDescription[];
};

export const flightFilterOptionID = {
  stops: "STOPS",
  airline: "AIRLINE",
  travelPolicy: "TRAVEL_POLICY",
  baggage: "BAGGAGE",
  time: "TIME",
  time0: "TIME_0",
  time1: "TIME_1",
} as const;

export type FlightFilterOptionID =
  (typeof flightFilterOptionID)[keyof typeof flightFilterOptionID];

export type FilterOption = {
  filterId: FlightFilterOptionID;
  filterType: string;
  name: string;
  items: {
    itemId: string;
    name: string;
  }[];
  filters: {
    groupId: string;
    interval: number;
    name: string;
    minRange: string;
    maxRange: string;
    unit: string;
  }[];
  label?: string | null;
};

export type LayoverSummary = {
  duration: string;
  airport: string;
  city: string;  
};

export type BaggageInfo = {
  provisionType: string;
  included: boolean;
  info?: string | null;
  pieceCount?: number | null;
  weight?: string | null;
  unit?: string | null;
  description1: string;
  description2: string;
};

export type FlightAtAirport = {
  airportName: string;
  city: string;
  country: string;
  time: string;
  dateAdjustment: number;
  locationCode?: string;
  terminal?: string;
  fareInfo?: {
    segment?: {
      bookingCode?: string | null;
      mealCode?: string | null;
      seatsAvailable?: number | null;
    };
  };
};

export type Carrier = {
  marketingAirline: {
    iataCode: string;
    name: string;
  };
  operatingAirline: {
    iataCode: string;
    name: string;
  };
  airCraftCode: string;
};

export type FlightSegment = {
  departureDateAdjustment: number;
  duration: number;
  flightNumber: string;
  flightCode: string;
  destination: FlightAtAirport;
  origin: FlightAtAirport;
  carrier: Carrier;
};

export type Itinerary = {
  originDestinationOptions: {
    originDestinationOption: {
      flightSegments: FlightSegment[];
      totalFlightTime: number;
      totalFlightTimeText: string;
      layoverSummary: LayoverSummary[];
    }[];
  };
};

type Policy = {
  id: number;
  inPolicy: boolean;
  violations: {
    text: string;
    desc: string;
  }[];
} | null;

export type ItineraryGroup = {
  identifier: string;
  nonRefundable: boolean;
  itinerary: Itinerary;
  policy: Policy;
  baggageInfo: BaggageInfo[];
  pricingInformation: {
    currency: string;
    itemTotalAmountInclMarkup: number;
    passengerTotalAmount?: number;
  };
};

export type FlightSearchResponse = {
  results?: {
    transactionId: string;
    status: FlightSearchStatus;
    sortedBy: FlightSearchSortBy;
    sortOrder: OrderBy;
    travelerDetails: {
      id: Traveler["id"];
      name: string;
      email: string;
    }[];
    returnDate: string;
    departureDate: string;
    numberOfAdults: number;
    expirationTime: Date;
    errors: [];
    warnings: [];
    groupDescription: GroupDescription[];
    filterOptions: FilterOption[];
    page: {
      totalResults: number;
      totalPages: number;
      pageNumber: number;
      resultsPerPage: number;
    };
    itineraryGroups: ItineraryGroup[];
  };
  success: boolean;
  message?: string;
};

export type FlightByIDParams = {
  transactionId?: string;
  id: string;
};

export type PenaltyInfo = {
  refundInfo: {
    refundable: boolean;
    conditionsApply: boolean;
    amount: number;
    currency: string;
  };
  exchangeInfo: {
    changeable: boolean;
    amount: 0;
    currency: string;
  };
};

export type FareInfo = {
  fareIdentifier: string;
  fareAmount: string;
  totalFareAmount: string;
  fareBasisCode: string;
  fareCurrency: string;
  brandedFare: {
    brand: {
      brandName: string;
      code: string;
      programCode: string;
      programDescription: string;
      programId: string;
    };
    brandFeatures: {
      application: string;
      commercialName: string;
      serviceGroup: string;
    }[];
  };
  segment: {
    bookingCode: string;
    mealCode: string;
    seatsAvailable: number;
  };
};

export type FlightByIDResponse = {
  success: boolean;
  message?: string;
  itineraryDetails?: {
    identifier: string;
    itinerary: {
      originDestinationOptions: {
        originDestinationOption: {
          totalFlightTime: number;
          totalFlightTimeText: string;
          layoverSummary: LayoverSummary[];
          flightSegments: FlightSegment[];
        }[];
      };
    };
    pricingInformation: {
      baseFareAmount: number;
      totalTaxAmount: number;
      totalPrice: number;
      currency: string;
      itemTotalAmountExclMarkup: number;
      itemTotalAmountInclMarkup: number;
    };
    fareInfo: FareInfo[];
    baggageInfo: BaggageInfo[];
    taxes: {
      amount: number;
      code: string;
      description: string;
      station: string;
    }[];
    policy: Policy;
    penaltyInfo: PenaltyInfo;
  };
};

export type Airline = {
  code: string;
  name?: string;
};

export type AirlineResponse = {
  success: boolean;
  message?: string;
  airlines?: Airline[];
};
