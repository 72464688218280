import React from "react";

import classes from "./Pagination.module.scss";

type Props = {
  children: React.ReactNode;
};

const UnmemoPagination: React.FC<Props> = ({ children }) => {
  return <div className={classes.pagination}>{children}</div>;
};

export const Pagination = React.memo(UnmemoPagination);
