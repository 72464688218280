export const get2Letters = (value: string) => {
  const words = value.trim().split(/\s+/);
  const firstChar = (word: string) => word.charAt(0);

  if (words.length === 1 && words[0].length === 1)
    return words[0].toUpperCase();

  if (words.length === 1) return words[0].substr(0, 2).toUpperCase();

  if (words.length >= 2)
    return (
      firstChar(words[0]).toUpperCase() + firstChar(words[1]).toUpperCase()
    );

  return "";
};
