import React from "react";

import { Input } from "../../Input";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type Props = InputProps;

const UnmemoPhoneInput: React.FC<Props> = ({ ...rest }) => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow backspace key
    if (e.key === "Backspace") {
      return;
    }

    // Allow only numeric characters and the plus sign
    const isValidInput = /^[0-9+]*$/.test(e.key);

    if (!isValidInput) {
      e.preventDefault();
    }
  };

  return <Input {...rest} onKeyDown={handleKeyPress} />;
};

export const PhoneInput = React.memo(UnmemoPhoneInput);
