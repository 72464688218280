import React from "react";

import { Image } from "src/components";
import classes from "./Icon.module.scss";

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

type Props = ImageProps & {
  width?: number;
  height?: number;
};

const UnmemoIcon: React.FC<Props> = ({ ...rest }) => {
  return <Image className={classes.icon} alt="" {...rest} />;
};

export const Icon = React.memo(UnmemoIcon);
