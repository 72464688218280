import React, { useContext, useState } from "react";
import { ToastMessage } from "src/components";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "src/configs";
import { CustomErrors, CustomSuccess } from "src/types";

type AppContextType = {
  isSideBarActive: boolean;
  setSideBarActive: (active: boolean) => void;
  isSideBarActiveMbl: boolean;
  setSideBarActiveMbl: (active: boolean) => void;
  showError: (message: string) => void;
  showSuccess: (message: string) => void;
};

const defaultValues: AppContextType = {
  isSideBarActive: false,
  setSideBarActive: (active: boolean) => {},
  isSideBarActiveMbl: false,
  setSideBarActiveMbl: (active: boolean) => {},
  showError: (message: string) => {},
  showSuccess: (message: string) => {},
};

const AppContext = React.createContext<AppContextType>(defaultValues);

type Props = {
  children: React.ReactNode;
};

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [isSideBarActive, setSideBarActive] = useState(true);
  const [isSideBarActiveMbl, setSideBarActiveMbl] = useState(false);

  const [isError, setError] = useState<CustomErrors | undefined>(undefined);
  const [isSuccess, setSuccess] = useState<CustomSuccess | undefined>(
    undefined
  );

  const showError = (message: string) => {
    setError({ hasError: true, message });

    setTimeout(() => {
      hideError();
    }, 7000);
  };

  const hideError = () => {
    setError(undefined);
  };

  const showSuccess = (message: string) => {
    setSuccess({ message });

    setTimeout(() => {
      setSuccess(undefined);
    }, 7000);
  };

  const hideSuccess = () => {
    setSuccess(undefined);
  };

  return (
    <AppContext.Provider
      value={{
        isSideBarActive,
        setSideBarActive,
        isSideBarActiveMbl,
        setSideBarActiveMbl,
        showError,
        showSuccess,
      }}
    >
      <ToastMessage
        type="error"
        onClose={hideError}
        isActive={Boolean(isError)}
      >
        {isError?.message || ERROR_MESSAGE}
      </ToastMessage>
      <ToastMessage
        type="success"
        onClose={hideSuccess}
        isActive={Boolean(isSuccess)}
      >
        {isSuccess?.message || SUCCESS_MESSAGE}
      </ToastMessage>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = (): AppContextType => useContext(AppContext);

export { useAppContext };
