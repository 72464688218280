import React, { useMemo } from "react";
import countryList from "react-select-country-list";

import { SelectInput } from "src/components";

type InputProps = React.SelectHTMLAttributes<HTMLSelectElement>;

type Props = InputProps;

const UnmemoCountryDropDown: React.FC<Props> = ({ ...rest }) => {
  const options = useMemo(() => countryList().getData(), []);

  return (
    <SelectInput
      options={options.map((option) => ({
        name: option.label,
        props: { value: option.value },
      }))}
      {...rest}
    />
  );
};

export const CountryDropDown = React.memo(UnmemoCountryDropDown);
