import React, { useState } from "react";
import { FormGroup, Popup, SelectInput, TextArea } from "src/components";
import { CHANGE_REQUEST_OPTIONS } from "src/configs";
import { ChangeRequestParams, changeRequestType } from "src/types/traveler";
import { onInputChange } from "src/utils";

type Props = {
  onChange: (params: ChangeRequestParams) => void;
  onHide: () => void;
  id: number;
};

const UnmemoRequestChangesPopup: React.FC<Props> = ({
  onHide,
  onChange,
  id,
}) => {
  const [formData, setFormData] = useState<ChangeRequestParams>({
    tripId: id,
    type: changeRequestType.amend,
    description: "",
  });

  return (
    <Popup
      title="Request Details"
      onClose={{ ctaAction: onHide }}
      cta={{ ctaName: "Submit", ctaAction: () => onChange(formData) }}
    >
      <FormGroup>
        <SelectInput
          name="type"
          value={formData.type}
          onChange={(e) => onInputChange(e, formData, setFormData)}
          options={CHANGE_REQUEST_OPTIONS}
        />
      </FormGroup>
      <FormGroup>
        <TextArea
          name="description"
          value={formData.description}
          onChange={(e) => onInputChange(e, formData, setFormData)}
        />
      </FormGroup>
    </Popup>
  );
};

export const RequestChangesPopup = React.memo(UnmemoRequestChangesPopup);
