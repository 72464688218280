import React, { ChangeEvent } from "react";
import { Checkbox } from "src/components";
import { ActionType, useSearchFlightContext } from "src/context";

type Props = {
  disabled?: boolean;
};

const UnmemoDirectOnlyInput: React.FC<Props> = ({ disabled = false }) => {
  const { dispatch, state } = useSearchFlightContext();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    dispatch({
      type: ActionType.updateDirectFlight,
      payload: checked,
    });
  };

  return (
    <Checkbox
      label="Direct Flights Only"
      checked={state.directOnly}
      onChange={onChange}
    />
  );
};

export const DirectOnlyInput = React.memo(UnmemoDirectOnlyInput);
