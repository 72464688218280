import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { TripInfo } from "src/types/traveler";
import { token } from "src/utils";

export type Response = {
  success: boolean;
  message: string;
};

type DeleteTrip = (id: TripInfo["id"]) => Promise<Response>;

const INVALID_RESPONSE: Response = {
  success: false,
  message: ERROR_MESSAGE,
};

export const deleteTrip: DeleteTrip = async (id) => {
  try {
    const res = await axios.delete<Response>(
      `${API_BASE_URL}${endpoints.trips.delete}/${id}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`DELETE TRIP | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`DELETE TRIP | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
