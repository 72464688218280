import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import classes from "./../../Input.module.scss";

type Props = ReactDatePickerProps & { limitStartFromToday?: boolean };

const UnmemoDateInput: React.FC<Props> = ({
  minDate,
  limitStartFromToday = true,
  ...rest
}) => {
  return (
    <DatePicker
      className={`${classes.input} ${classes.hasIcon} ${classes.date}`}
      minDate={minDate ? minDate : limitStartFromToday ? new Date() : null}
      showYearDropdown
      showMonthDropdown
      dateFormat={"dd/MM/yyyy"}
      dropdownMode="select"
      {...rest}
    />
  );
};

export const DateInput = React.memo(UnmemoDateInput);
