import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Checkbox, Input, Pagination, Text } from "src/components";
import { DataTableLayout } from "src/sections";

import { componentVariants, orderBy } from "src/types";
import classes from "./DataTable.module.scss";
import { ActionBulk, Actions, Nodata } from "./components";

export interface DataTableColumn<T> {
  dataKey: keyof T;
  header: string;
}

export interface DataTableProps<T extends Record<string, any>> {
  data: T[];
  columns: DataTableColumn<T>[];
  hasSort?: {
    onSort: (sortBy: any, orderBy: any) => void;
    sortBy: any;
    orderBy: any;
  };
  onSelectedRowsChange?: (selectedRows: T[]) => void;
  onDelete?: (data: T["key"]) => void;
  onDeleteBulk?: () => void;
  onStatusChange?: (data: T["key"]) => void;
  onDeActiveBulk?: () => void;
  onActivateBulk?: () => void;
  onUpdate?: (data: T["key"]) => void;
  onClick?: (data: T["key"]) => void;
  mainCTA: {
    name: string;
    action: () => void;
  };
  otherCTA?: {
    name: any;
    action: () => void;
  }[];
  rowActions?: {
    name: any;
    icon: string;
    action: (data: T["key"]) => void;
  }[];
  totalPages: number;
  pageNumber: number;
  onChangePagination: (number: number) => void;
  onSearchChange: (value: string) => void;
  onSearch: () => void;
}

function UnmemoDataTable<T extends Record<string, any>>({
  data,
  columns,
  onSelectedRowsChange,
  onDelete,
  onDeleteBulk,
  onUpdate,
  mainCTA,
  onClick = () => {},
  onStatusChange,
  onDeActiveBulk,
  onActivateBulk,
  totalPages,
  pageNumber,
  onChangePagination,
  onSearchChange,
  onSearch,
  hasSort,
  rowActions,
  otherCTA,
}: DataTableProps<T>) {
  const [currentPage, setCurrentPage] = useState<number>(pageNumber - 1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

  const hasRowActions =
    Boolean(onDelete) ||
    Boolean(onStatusChange) ||
    Boolean(onUpdate) ||
    Boolean(rowActions);

  const hasBulkActions =
    Boolean(onDeleteBulk) || Boolean(onDeActiveBulk) || Boolean(onActivateBulk);

  const handleSearch = (event: any) => {
    event.preventDefault();
    onSearch();
    setCurrentPage(0);
  };

  const handleRowSelect = (key: string) => {
    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(key)) {
      updatedSelectedRows.delete(key);
    } else {
      updatedSelectedRows.add(key);
    }
    setSelectedRows(updatedSelectedRows);
  };

  // const filteredData = searchQuery
  //   ? data.filter((item) =>
  //       columns.some((column) =>
  //         item[column.dataKey]
  //           .toString()
  //           .toLowerCase()
  //           .includes(searchQuery.toLowerCase())
  //       )
  //     )
  //   : data;

  const filteredData = data;

  // const paginatedData = sortedData.slice(
  //   currentPage * itemsPerPage,
  //   (currentPage + 1) * itemsPerPage
  // );

  const getRowKey = (item: T) => item[columns[0].dataKey].toString();

  useEffect(() => {
    if (onSelectedRowsChange) {
      onSelectedRowsChange(getSelectedRows());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const getSelectedRows = () =>
    data.filter((item) => selectedRows.has(getRowKey(item)));

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    onChangePagination(page + 1);
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = 0; i < totalPages; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={currentPage === i}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);
    onSearchChange(value);
  };

  const onSort = (sortBy: any, orderBy: any) => {
    hasSort?.onSort(sortBy, orderBy);
  };

  return (
    <DataTableLayout
      input={
        <form onSubmit={handleSearch}>
          <Input
            type="text"
            name="table_search"
            value={searchQuery}
            onChange={onInputChange}
            placeholder="Search"
            icon="search"
          />
        </form>
      }
      actions={
        <>
          {hasBulkActions && (
            <ActionBulk
              onDeActiveBulk={onDeActiveBulk}
              onDeleteBulk={onDeleteBulk}
              onActivateBulk={onActivateBulk}
            />
          )}

          {otherCTA &&
            otherCTA.map(({ name, action }, i) => (
              <Button
                variant={componentVariants.secondary}
                onClick={() => action()}
                key={i}
              >
                {name}
              </Button>
            ))}

          <Button onClick={() => mainCTA.action()}>{mainCTA.name}</Button>
        </>
      }
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th>
              {/* <input
                type="checkbox"
                checked={
                  selectedRows.size === paginatedData.length &&
                  paginatedData.length > 0
                }
                onChange={() => {
                  if (selectedRows.size === paginatedData.length) {
                    setSelectedRows(new Set());
                  } else {
                    setSelectedRows(
                      new Set(paginatedData.map((item) => getRowKey(item)))
                    );
                  }
                }}
              /> */}
            </th>
            {columns.map((column, i) => (
              <th
                key={i}
                onClick={() =>
                  onSort(
                    column.dataKey,
                    hasSort?.orderBy === orderBy.ascending
                      ? orderBy.descending
                      : orderBy.ascending
                  )
                }
              >
                <Text tag="h5">
                  {column.header}
                  {hasSort && hasSort.sortBy === column.dataKey && (
                    <span>
                      {hasSort.orderBy === orderBy.ascending ? " 🔽" : " 🔼"}
                    </span>
                  )}
                </Text>
              </th>
            ))}
            {hasRowActions && <th></th>}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => {
            const isSelected = selectedRows.has(getRowKey(item));

            return (
              <tr key={index} className={isSelected ? classes.selected : ""}>
                <td>
                  <div className={isSelected ? "" : classes.showOnHover}>
                    <Checkbox
                      checked={isSelected}
                      onChange={() => handleRowSelect(getRowKey(item))}
                    />
                  </div>
                </td>
                {columns.map((column, i) => (
                  <td onClick={() => onClick(item.key)} key={i}>
                    {item[column.dataKey]}
                  </td>
                ))}
                {hasRowActions && (
                  <td>
                    <Actions
                      item={item}
                      onDelete={onDelete}
                      onStatusChange={onStatusChange}
                      onUpdate={onUpdate}
                      moreActions={
                        rowActions &&
                        rowActions.map(({ name, action, icon }) => ({
                          name,
                          icon,
                          action: () => action(item.key),
                        }))
                      }
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      {filteredData.length === 0 && <Nodata />}

      {totalPages > 1 && <Pagination>{renderPaginationButtons()}</Pagination>}
    </DataTableLayout>
  );
}

export const DataTable = React.memo(UnmemoDataTable) as <
  T extends Record<string, any>
>(
  props: DataTableProps<T>
) => React.ReactElement;
