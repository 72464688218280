import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { BasicResponse } from "src/types";
import { ApprovalProcessDetailed } from "src/types/approval";
import { token } from "src/utils";

type DeleteApprovalProcess = (
  ids: ApprovalProcessDetailed["id"][]
) => Promise<BasicResponse>;

const INVALID_RESPONSE: BasicResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const deleteApprovalProcess: DeleteApprovalProcess = async (ids) => {
  try {
    const res = await axios.delete<BasicResponse>(
      `${API_BASE_URL}${endpoints.corporate.approvalProcess.delete}`,
      {
        data: ids,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`DELETE APPROVAL PROCESS | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(
      `DELETE APPROVAL PROCESS | ${SERVICE_FAILED_MESSAGE} ${error}`
    );

    return INVALID_RESPONSE;
  }
};
