import { formatDateInMonth } from "src/utils";

export const getAdjustedDate = (departureDate: string, adjustBy: number) => {
  // Assuming departureDate is a string in the format 'YYYY-MM-DD'
  const dateParts = departureDate.split("-");
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // JavaScript months are zero-based
  const day = parseInt(dateParts[2]);

  // Create a new Date object representing the departureDate
  const departureDateTime = new Date(year, month, day);

  // Increment the departureDate by one day
  const nextDay = new Date(departureDateTime);
  nextDay.setDate(departureDateTime.getDate() + adjustBy);

  // Format the result as 'YYYY-MM-DD'
  const formattedDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${nextDay.getDate().toString().padStart(2, "0")}`;

  return formatDateInMonth(formattedDate);
};
