import React, { useEffect, useState } from "react";
import { Layout } from "src/Layout";
import {
  CenteredGrid,
  DataTable,
  DataTableColumn,
  SectionTitle,
} from "src/components";
import { ERROR_MESSAGE, SUCCESS_MESSAGE, app, pageLinks } from "src/configs";
import { TableConfigs } from "src/types";

import { useNavigate } from "react-router-dom";
import { useAppContext, useLoadingContext, useLoginContext } from "src/context";
import { ApprovalProcessService } from "src/services";
import {
  ApprovalProcessDetailed,
  ApprovalProcessList,
  ApprovalProcessSearchParams,
} from "src/types/approval";
import { ProcessDetails } from "./components";
import { DEFAULT_TABLE_CONFIGS } from "./configs";
import { ApprovalProcessTable, transformApprovalProcessList } from "./utils";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const columns: DataTableColumn<ApprovalProcessTable>[] = [
  { dataKey: "id", header: "ID" },
  { dataKey: "name", header: "Name" },
  { dataKey: "createdAt", header: "Created At" },
  { dataKey: "assignees", header: "People Assigned" },
  { dataKey: "approvalNeeded", header: "Is Approval Needed?" },
  { dataKey: "approvers", header: "Approvers" },
];

const UnmemoApprovalProcess: React.FC = () => {
  const { user } = useLoginContext();
  const { loading, unlockScreen } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();

  const navigate = useNavigate();

  const [list, setList] = useState<ApprovalProcessTable[] | null>(null); // For table list

  const [selectedRows, setSelectedRows] = useState<ApprovalProcessTable[]>([]); // For selected rows

  const [tableConfigs, setTableConfigs] = useState<TableConfigs>(
    DEFAULT_TABLE_CONFIGS
  ); // Table configs

  const [openSidebar, setOpenSidebar] = useState(false); // For sidebar

  const [detailedProcessInfo, setDetailedProcessInfo] = useState<
    ApprovalProcessDetailed | undefined
  >(undefined); // Save information of one user

  const [search, setSearch] = useState(false); // To search

  const [searchParams, setSearchParams] = useState<ApprovalProcessSearchParams>(
    {
      pageIndex: 0,
    }
  ); // Search payload

  // -------- Table configurations start --------
  const updateTable = (response: ApprovalProcessList) => {
    if (response.success && response.results) {
      setList(transformApprovalProcessList(response.results.approvalProcesses));

      setTableConfigs({
        pageNumber: response.results.pageNumber,
        pageSize: response.results.pageSize,
        totalItems: response.results.totalItems,
        totalPages: response.results.totalPages,
        firstItem: response.results.firstItem,
        lastItem: response.results.lastItem,
      });
    } else {
      setList([]);

      setTableConfigs(DEFAULT_TABLE_CONFIGS);
    }
  };
  // -------- Table configurations end --------

  // -------- Table Listing start --------
  const getList = async () => {
    loading.start();

    window.scrollTo({ top: 0, behavior: "smooth" });

    const response = await ApprovalProcessService.get();
    updateTable(response);

    loading.stop();
  };

  useEffect(() => {
    if (user.id !== 0) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSearch = async () => {
    loading.start();

    window.scrollTo({ top: 0, behavior: "smooth" });

    const response = await ApprovalProcessService.search(searchParams);
    updateTable(response);

    setSearch(false);

    loading.stop();
  };

  useEffect(() => {
    if (search) {
      onSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onChangePagination = (number: number) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      pageIndex: number - 1,
    }));
    setSearch(true);
  };

  const onSearchChange = (value: string) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      name: value,
    }));
  };

  const onSearchUsers = () => {
    setSearch(true);
  };
  // -------- Table Listing ends --------

  // -------- Create Process Handling start --------
  const showCreateProcess = () =>
    navigate(pageLinks.createApprovalProcess.link);
  // -------- Create Process Handling ends --------

  // -------- Update Handling starts --------
  const onUpdate = (process: ApprovalProcessDetailed) => {
    navigate(`${pageLinks.editApprovalProcess.link}?id=${process.id}`);
  };
  // -------- Update Handling end --------

  // -------- Delete Handling starts --------
  const handleDelete = async (ids: ApprovalProcessDetailed["id"][]) => {
    loading.start();

    const response = await ApprovalProcessService.delete(ids);

    if (response.success)
      showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
    else showError(response.message ? response.message : ERROR_MESSAGE);

    getList();
    loading.stop();
  };

  const onDelete = async (policy: ApprovalProcessDetailed) => {
    handleDelete([policy.id]);
  };

  const onDeleteBulk = async () => {
    const ids: number[] = selectedRows.map((list) => list.key.id);
    handleDelete(ids);
  };
  // -------- Delete Handling ends --------

  // -------- Row click action starts --------
  const onClickRow = async (process: ApprovalProcessDetailed) => {
    loading.start();

    const response = await ApprovalProcessService.getByID(process.id);
    if (response.success && response.results) {
      setDetailedProcessInfo(response.results);
      setOpenSidebar(true);
    } else {
      setDetailedProcessInfo(undefined);
      setOpenSidebar(false);
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  // -------- Sidebar Handling starts --------
  const onCloseSideBar = () => {
    unlockScreen();
    setOpenSidebar(false);
    setDetailedProcessInfo(undefined);
  };
  // -------- Sidebar Handling ends --------
  // -------- Row click action ends --------

  return (
    <Layout title={`${pageLinks.approvalProcess.name} - ${app.name}`}>
      <CenteredGrid>
        <SectionTitle
          title={pageLinks.approvalProcess.name}
          tableData={{
            first: tableConfigs.firstItem,
            last: tableConfigs.lastItem,
            total: tableConfigs.totalItems,
          }}
        />

        {list === null ? (
          <Skeleton height={40} count={10} />
        ) : (
          <DataTable<ApprovalProcessTable>
            data={list}
            columns={columns}
            onSelectedRowsChange={setSelectedRows}
            onDelete={onDelete}
            onDeleteBulk={onDeleteBulk}
            onUpdate={onUpdate}
            onClick={onClickRow}
            mainCTA={{ name: "Create New", action: showCreateProcess }}
            totalPages={tableConfigs.totalPages}
            pageNumber={tableConfigs.pageNumber}
            onChangePagination={onChangePagination}
            onSearchChange={onSearchChange}
            onSearch={onSearch}
            // hasSort={{
            //   onSort,
            //   sortBy: searchParams.sortBy,
            //   orderBy: searchParams.orderBy,
            // }}
          />
        )}

        <ProcessDetails
          isActive={openSidebar}
          onClose={onCloseSideBar}
          processDetails={detailedProcessInfo}
        />
      </CenteredGrid>
    </Layout>
  );
};

export const ApprovalProcess = React.memo(UnmemoApprovalProcess);
