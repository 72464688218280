import Chart from "chart.js/auto";
import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Text } from "src/components";
import classes from "./ChartCard.module.scss";

interface ChartProps {
  id: string;
  frequency: string;
  data: number[];
  labels: string[];
  onFrequencyChange: (id: string, frequency: string) => void;
  title: string;
  total: number;
  totalText: string;
}

const UnmemoChartCard: React.FC<ChartProps & { id: string }> = ({
  id,
  data,
  labels,
  onFrequencyChange,
  title,
  total,
  totalText,
}) => {
  const [frequency, setFrequency] = useState("yearly");
  const chartRef = useRef<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    renderChartCard();
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [frequency]);

  const handleFrequencyChangeLocal = (newFrequency: string) => {
    setFrequency(newFrequency);
    onFrequencyChange(id, newFrequency);
  };

  const renderChartCard = () => {
    const ctx = canvasRef.current;
    if (!ctx) return;

    if (chartRef.current) {
      chartRef.current.destroy();
    }
    chartRef.current = new Chart(ctx, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: title,
            data: data,
            borderColor: "rgb(242, 107, 82)",
            tension: 0.1,
          },
        ],
      },
    });
  };

  return (
    <div className={classes.chartCard}>
      <div className={classes.header}>
        <Text tag="h2">{title}</Text>

        <ButtonGroup size="sm">
          <Button
            className={` ${frequency === "yearly" ? "active" : ""}`}
            onClick={() => handleFrequencyChangeLocal("yearly")}
          >
            Yearly
          </Button>
          <Button
            className={` ${frequency === "monthly" ? "active" : ""}`}
            onClick={() => handleFrequencyChangeLocal("monthly")}
          >
            Monthly
          </Button>
        </ButtonGroup>
      </div>
      <Text tag="h3">
        {total}
        <span>{totalText}</span>
      </Text>

      <div className="chart-card-body">
        <canvas ref={canvasRef}></canvas>
      </div>
    </div>
  );
};

export const ChartCard = React.memo(UnmemoChartCard);
