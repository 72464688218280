import React from "react";
import { Line, SideDrawer } from "src/components";
import { BasicInfo, TravelPolicySummary } from "src/sections";

import { TravelPolicyDetails } from "src/types/travelPolicy";

type Props = Omit<
  React.ComponentProps<typeof SideDrawer>,
  "title" | "children"
> & {
  policyDetails: TravelPolicyDetails["travelPolicy"];
};

const UnmemoPolicyDetails: React.FC<Props> = ({ policyDetails, ...rest }) => {
  if (!policyDetails) {
    return <></>;
  }

  return (
    <SideDrawer title={policyDetails.name} {...rest}>
      <BasicInfo
        info={[
          {
            label: "Name",
            value: `${policyDetails.name}`,
          },
          { label: "Create At", value: `${policyDetails.createdAt}` },
        ]}
      />

      <Line />

      <TravelPolicySummary
        id={policyDetails.id}
        name={policyDetails.name}
        isDefault={policyDetails.isDefault}
        flightDynamicBudget={policyDetails.flightDynamicBudget}
        flightIsRefundable={policyDetails.flightIsRefundable}
        flightMaxCabinClass={policyDetails.flightMaxCabinClass}
        flightMaxCabinClassLongerLeg={
          policyDetails.flightMaxCabinClassLongerLeg
        }
        flightExtrasRules={policyDetails.flightExtrasRules}
        travelers={policyDetails.travelers}
        themeColor={policyDetails.themeColor}
      />
    </SideDrawer>
  );
};

export const PolicyDetails = React.memo(UnmemoPolicyDetails);
