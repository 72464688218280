import React from "react";

import { componentSizes } from "src/types";

import { Text } from "src/components";
import classes from "./PasswordChecker.module.scss";

type Props = {
  validate: number;
};

const UnmemoPasswordChecker: React.FC<Props> = ({ validate }) => {
  return (
    <div
      className={`${classes.passwordChecker} ${
        validate === 2 ? classes.fair : ""
      } ${validate === 3 ? classes.good : ""} ${
        validate === 4 ? classes.excellent : ""
      }`}
    >
      <span
        className={`${classes.check} ${validate >= 1 ? classes.checked : ""}`}
      ></span>
      <span
        className={`${classes.check} ${validate >= 2 ? classes.checked : ""}`}
      ></span>
      <span
        className={`${classes.check} ${validate >= 3 ? classes.checked : ""}`}
      ></span>
      <span
        className={`${classes.check} ${validate >= 4 ? classes.checked : ""}`}
      ></span>
      <Text size={componentSizes.small} className={classes.weak}>
        Weak
      </Text>
      <Text size={componentSizes.small} className={classes.fair}>
        Fair
      </Text>
      <Text size={componentSizes.small} className={classes.good}>
        Good
      </Text>
      <Text size={componentSizes.small} className={classes.excellent}>
        Excellent
      </Text>
    </div>
  );
};

export const PasswordChecker = React.memo(UnmemoPasswordChecker);
