import { activateTraveler } from "./activateTraveler";
import { addTraveler } from "./addTraveler";
import { deactivateTraveler } from "./deactivateTraveler";
import { deleteTraveler } from "./deleteTraveler";
import { getTraveler } from "./getTraveler";
import { getTravelersList } from "./getTravelersList";
import { inviteTraveler } from "./inviteTraveler";
import { searchTraveler } from "./searchTraveler";
import { updateTravelerProfilePicture } from "./updateTravelerProfilePicture";
import { getTravelerLoyaltyPrograms } from "./travelerLoyaltyPrograms";
import { deleteTravelerLoyaltyPrograms } from "./deleteTravelerLoyalty";
import { updateTravelerLoyaltyPrograms } from "./updateTravelerLoyalty";
import { addTravelerLoyaltyPrograms } from "./addTravelerLoyalty";

export const TravelerService = {
  getList: getTravelersList,
  delete: deleteTraveler,
  getById: getTraveler,
  invite: inviteTraveler,
  search: searchTraveler,
  deactivate: deactivateTraveler,
  activate: activateTraveler,
  updateImage: updateTravelerProfilePicture,
  add: addTraveler,
  getTravelerPrograms: getTravelerLoyaltyPrograms,
  deleteTravelerProgram: deleteTravelerLoyaltyPrograms,
  updateTravelerProgram: updateTravelerLoyaltyPrograms,
  addTravelerProgram: addTravelerLoyaltyPrograms
};
