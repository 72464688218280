import React, { useEffect, useState } from "react";
import {
  DateInput,
  FormGroup,
  Input,
  Label,
  PhoneInput,
  Popup,
  SelectInput,
} from "src/components";
import { ERROR_MESSAGE, PERSON_TITLE, SUCCESS_MESSAGE } from "src/configs";
import { useAppContext, useLoadingContext } from "src/context";
import { TravelerService, UserService } from "src/services";
import { UserEditParams } from "src/types/login";
import { onInputChange } from "src/utils";

type Props = {
  id: UserEditParams["id"];
  onUpdate: () => void;
  cancelUpdate: () => void;
  isActive: boolean;
};

const DEFAULT_CONFIGS: Omit<UserEditParams, "id"> = {
  title: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  birthDate: "",
};

const UnmemoEditForm: React.FC<Props> = ({
  id,
  onUpdate,
  isActive,
  cancelUpdate,
}) => {
  const { loading } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();

  const [formData, setFormData] = useState<UserEditParams>({
    id,
    ...DEFAULT_CONFIGS,
  });

  const onDateChange = (date: Date | null) => {
    setFormData((prevData) => ({
      ...prevData,
      birthDate: `${date}`,
    }));
  };

  const onSubmit = async () => {
    loading.start();

    const response = await UserService.edit(formData);
    if (response.success) {
      onUpdate();
      showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
    } else {
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  const onOpenFOrm = async () => {
    loading.start();

    const response = await TravelerService.getById(id);
    if (response.success && response.travelerInfo) {
      const { accountInformation } = response.travelerInfo;
      setFormData({
        id: formData.id,
        title: accountInformation.title || "",
        firstName: accountInformation.firstName,
        lastName: accountInformation.lastName,
        phoneNumber: accountInformation.phoneNumber || "",
        birthDate: accountInformation.birthDate || "",
      });
    } else {
      setFormData({ id, ...DEFAULT_CONFIGS });
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  useEffect(() => {
    if (isActive) {
      onOpenFOrm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isActive]);

  if (!isActive) return <></>;

  return (
    <Popup
      title="Edit User"
      cta={{ ctaName: "Update", ctaAction: onSubmit }}
      onClose={{ ctaName: "Cancel", ctaAction: cancelUpdate }}
    >
      <div className="row">
        <div className="col-lg-12">
          <FormGroup>
            <Label>Title</Label>
            <SelectInput
              name="title"
              value={formData.title}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              options={PERSON_TITLE}
            />
          </FormGroup>
        </div>
        <div className="col-lg-6">
          <FormGroup>
            <Label>First Name</Label>
            <Input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              placeholder="First Name"
              required
            />
          </FormGroup>
        </div>
        <div className="col-lg-6">
          <FormGroup>
            <Label>Last Name</Label>
            <Input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              placeholder="Last Name"
              required
            />
          </FormGroup>
        </div>
        <div className="col-lg-12">
          <FormGroup>
            <Label>Phone Number</Label>
            <PhoneInput
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              placeholder="Enter Phone"
              required
            />
          </FormGroup>
        </div>
        <div className="col-lg-12">
          <FormGroup>
            <Label>Date of Birth</Label>
            <DateInput
              name="birthDate"
              selected={
                formData.birthDate ? new Date(formData.birthDate) : null
              }
              onChange={(date) => onDateChange(date)}
              placeholderText="Enter Date"
              required
              limitStartFromToday={false}
            />
          </FormGroup>
        </div>
      </div>
    </Popup>
  );
};

export const EditForm = React.memo(UnmemoEditForm);
