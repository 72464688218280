import React from "react";

import classes from "./Image.module.scss";

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

type Props = ImageProps & {
  width?: number;
  height?: number;
  fullWidth?: boolean;
  className?: string;
};

const UnmemoImage: React.FC<Props> = ({
  src,
  alt,
  fullWidth = false,
  className,
  ...rest
}) => {
  return (
    <img
      src={src}
      alt={alt}
      {...rest}
      className={`${classes.image} ${fullWidth ? classes.fullWidth : ""} ${
        className ? className : ""
      }`}
    />
  );
};

export const Image = React.memo(UnmemoImage);
