import React from "react";

import { Image, ProfilePlaceholder } from "src/components";
import { ComponentSize, componentSizes } from "src/types";
import classes from "./ProfileImage.module.scss";

type Props = {
  profilePictureUrl?: string;
  name: string;
  size?: ComponentSize;
  className?: string;
};

const UnmemoProfileImage: React.FC<Props> = ({
  profilePictureUrl,
  name,
  size = componentSizes.regular,
  className,
}) => {
  const classNameProps = `${classes.img} ${classes[size]} ${className}`;

  return (
    <>
      {profilePictureUrl ? (
        <Image
          src={profilePictureUrl}
          alt={name}
          className={classNameProps}
          title={name}
        />
      ) : (
        <ProfilePlaceholder
          name={name}
          className={classNameProps}
          size={size}
        />
      )}
    </>
  );
};

export const ProfileImage = React.memo(UnmemoProfileImage);
