import React, { useEffect, useState } from "react";
import { Layout } from "src/Layout";
import { CenteredGrid, Line, Text } from "src/components";
import { ERROR_MESSAGE, SUCCESS_MESSAGE, app, pageLinks } from "src/configs";
import { DetailedFlightInfo } from "src/sections";

import { useLocation, useNavigate } from "react-router-dom";
import {
  useAppContext,
  useLoadingContext,
  useSearchFlightContext,
} from "src/context";
import { CheckoutService, TripService } from "src/services";
import {
  BookingStatus,
  GetTravelerCheckoutResponse,
  OrderTotal,
} from "src/types/checkout";
import { FlightByIDResponse } from "src/types/flight";
import { TripStatus } from "src/types/traveler";
import { formatDateForBE } from "src/utils";
import classes from "./SummaryCheckout.module.scss";
import { OrderSummary, TravelerDetail } from "./components";

const UnmemoSummaryCheckoutApproval: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlQuery = new URLSearchParams(location.search);

  const urlID = urlQuery.get("id");

  const { showError, showSuccess } = useAppContext();
  const { loading } = useLoadingContext();
  const { state } = useSearchFlightContext();

  const [orderSummary, setOrderSummary] = useState<OrderTotal | undefined>(
    undefined
  );

  const [isApproved, setIsApproved] = useState<boolean>(false);

  const { departureDate, returnDate } = state;

  const [travelers, setTravelers] =
    useState<GetTravelerCheckoutResponse["travelers"]>(undefined);

  const [itineraryDetails, setItineraryDetails] = useState<
    FlightByIDResponse["itineraryDetails"] | undefined
  >(undefined);

  const getSummary = async (id: number) => {
    loading.start();

    const response = await TripService.getByID(id);

    if (response.success && response.orderSummary) {
      const { orderTotal, travelersInfo, itineraryDetails } =
        response.orderSummary;
      setOrderSummary(orderTotal);
      setTravelers(travelersInfo);
      setItineraryDetails(itineraryDetails);
    } else {
      setTravelers(undefined);
      setOrderSummary(undefined);
      setItineraryDetails(undefined);
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    const responseVerify = await CheckoutService.verify();

    if (responseVerify.success) setIsApproved(!responseVerify.approvalRequired);
    else setIsApproved(false);

    loading.stop();
  };

  const confirmBooking = async (
    status: TripStatus,
    rejectionReason?: string
  ) => {
    loading.start();

    if (urlID) {
      const response = await TripService.update({
        id: Number(urlID),
        status,
        rejectionReason,
      });

      if (response.success) {
        showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
        navigate(pageLinks.trips.link);
      } else showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  useEffect(() => {
    if (urlID) {
      getSummary(Number(urlID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title={`${pageLinks.approval.checkout.name} - ${app.name}`}>
      <CenteredGrid>
        <div className="row">
          <div className="col-lg-8">
            <div className={classes.row}>
              {itineraryDetails && (
                <DetailedFlightInfo
                  {...itineraryDetails}
                  nonRefundable={false}
                  departureDate={formatDateForBE(departureDate)}
                  returnDate={
                    returnDate ? formatDateForBE(returnDate) : undefined
                  }
                  onSelect={(id: string) => {}}
                  isShort
                />
              )}
            </div>

            <div className={classes.row}>
              <Line />
            </div>

            <div className={classes.row}>
              <Text tag="h3">Travelers</Text>
            </div>

            {travelers &&
              travelers.map(({ ...rest }, i) => (
                <div className={classes.row} key={i}>
                  <TravelerDetail {...rest} />
                </div>
              ))}
          </div>
          <div className="col-lg-4">
            <div className={classes.summary}>
              {orderSummary && (
                <OrderSummary
                  {...orderSummary}
                  onConfirm={(status: BookingStatus) => {}}
                  onApprove={confirmBooking}
                  isApproved={isApproved}
                  canApprove
                  canReject
                />
              )}
            </div>
          </div>
        </div>
      </CenteredGrid>
    </Layout>
  );
};

export const SummaryCheckoutApproval = React.memo(
  UnmemoSummaryCheckoutApproval
);
