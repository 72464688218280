import React from "react";
import { PrimaryLink, Text } from "src/components";
import classes from "./DropDown.module.scss";

type InputProps = React.SelectHTMLAttributes<HTMLSelectElement>;
type Option = React.OptionHTMLAttributes<HTMLOptionElement>;

type Props = InputProps & {
  options: { name: string | React.ReactNode; props: Option }[];
  isActive: boolean;
  onClick: any;
  cta?: {
    name: string;
    action: any;
  };
};

const UnmemoDropDown: React.FC<Props> = ({
  options,
  isActive,
  onClick,
  cta,
}) => {
  if (isActive)
    return (
      <div className={classes.dropdown}>
        {options.map(({ name, props }, i) => (
          <div
            className={`${classes.list} ${
              props.value === "" ? classes.disable : ""
            }`}
            onClick={() => onClick(props.value)}
            key={i}
          >
            {typeof name === "string" ? <Text>{name}</Text> : <>{name}</>}
          </div>
        ))}
        {cta && (
          <div className={classes.cta}>
            <PrimaryLink onClick={cta.action}>{cta.name}</PrimaryLink>
          </div>
        )}
      </div>
    );

  return <></>;
};

export const DropDown = React.memo(UnmemoDropDown);
