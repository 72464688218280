import React, { useState } from "react";
import { FormGroup, Popup, TextArea } from "src/components";

type Props = {
  onReject: (reason: string) => void;
  onHide: () => void;
};

const UnmemoRejectionPopup: React.FC<Props> = ({ onHide, onReject }) => {
  const [rejectionReason, setRejectionReason] = useState<string>("");

  return (
    <Popup
      title="Add Rejection Reason"
      onClose={{ ctaAction: onHide }}
      cta={{ ctaName: "Reject", ctaAction: () => onReject(rejectionReason) }}
    >
      <FormGroup>
        <TextArea
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
        />
      </FormGroup>
    </Popup>
  );
};

export const RejectionPopup = React.memo(UnmemoRejectionPopup);
