import React from "react";
import { LocationInput } from "src/components";
import { ActionType, useSearchFlightContext } from "src/context";
import { BasicLocation } from "src/types/location";

type Props = {
  disabled?: boolean;
};

const UnmemoFlightFromInput: React.FC<Props> = ({ disabled = false }) => {
  const { dispatch, state } = useSearchFlightContext();

  const onInputChange = (location: BasicLocation) => {
    dispatch({
      type: ActionType.updateFlightFrom,
      payload: location,
    });
  };

  return (
    <LocationInput
      selectedLocation={state.flightFrom}
      value={state.flightFrom?.cityName}
      name="flightFrom"
      onInputChange={onInputChange}
      placeholder="Flying From (City or Airport)"
      isFlightFrom
      disabled={disabled}
    />
  );
};

export const FlightFromInput = React.memo(UnmemoFlightFromInput);
