import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { BasicResponse } from "src/types";
import { EditApprovalProcessParams, approvalAction } from "src/types/approval";
import { token } from "src/utils";

type EditApprovalProcess = (
  params: EditApprovalProcessParams
) => Promise<BasicResponse>;

const INVALID_RESPONSE: BasicResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const editApprovalProcess: EditApprovalProcess = async (params) => {
  const updatedParams = params.approvalNeeded
    ? params
    : {
        ...params,
        approvalProcessRules: params.approvalProcessRules.map((process) => ({
          ...process,
          action: approvalAction.notify,
        })),
      };

  try {
    const res = await axios.put<BasicResponse>(
      `${API_BASE_URL}${endpoints.corporate.approvalProcess.edit}`,
      { ...updatedParams },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) return res.data;

    console.error(`EDIT APPROVAL PROCESS | ${SERVICE_FAILED_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`EDIT APPROVAL PROCESS | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
