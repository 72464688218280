import React from "react";
import {
  Button,
  DepartureDateInput,
  DirectOnlyInput,
  FlightFromInput,
  FlightMaxCabinInput,
  FlightToInput,
  FormGroup,
  ReturnDateInput,
} from "src/components";
import classes from "./FilterSection.module.scss";

type Props = {
  getFlights: () => void;
};

const UnmemoFilterSection: React.FC<Props> = ({ getFlights }) => {
  return (
    <div className={classes.filterSection}>
      <div className="row">
        <div className="col-lg-2">
          <FormGroup>
            <FlightFromInput />
          </FormGroup>
        </div>
        <div className="col-lg-2">
          <FormGroup>
            <FlightToInput />
          </FormGroup>
        </div>
        <div className="col-lg-2">
          <FormGroup>
            <DepartureDateInput />
          </FormGroup>
        </div>
        <div className="col-lg-2">
          <FormGroup>
            <ReturnDateInput />
          </FormGroup>
        </div>
        <div className="col-lg-2">
          <FormGroup>
            <FlightMaxCabinInput />
          </FormGroup>
        </div>
        <div className="col-lg-2">
          <Button isFullWidth onClick={() => getFlights()}>
            Search Again
          </Button>
        </div>
      </div>
      <FormGroup>
        <DirectOnlyInput />
      </FormGroup>
    </div>
  );
};

export const FilterSection = React.memo(UnmemoFilterSection);
