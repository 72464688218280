import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { SelectServiceParams, SelectServiceResponse } from "src/types/checkout";
import { token } from "src/utils";

type SelectService = (
  params: SelectServiceParams
) => Promise<SelectServiceResponse>;

const INVALID_RESPONSE: SelectServiceResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const selectService: SelectService = async (params) => {
  try {
    const res = await axios.post<SelectServiceResponse>(
      `${API_BASE_URL}${endpoints.checkout.selectService}`,
      { ...params },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) return res.data;

    console.error(`SELECT SERVICE | ${SERVICE_FAILED_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`SELECT SERVICE | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
