import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout } from "src/Layout";
import { IconAndTitle, Text } from "src/components";
import { app, assets, pageLinks } from "src/configs";
import { useLoadingContext } from "src/context";
import { getActivity } from "src/services/Reporting/activityService";
import { SpendInfo, TopDestination, TripInfo } from "src/types/reporting";
import classes from "./Reporting.module.scss";
import {
  Balance,
  ChartCard,
  ReportDownload,
  TopDestinations,
} from "./components";
type ActivityData = {
  spendInfo: SpendInfo;
  tripInfo: TripInfo;
  topDestinations: TopDestination[];
  creditLimit: string;
  availableBalance: string;
} | null;

const UnmemoReporting: React.FC = () => {
  const [activityData, setActivityData] = useState<ActivityData>(null);
  const [spendFrequency, setSpendFrequency] = useState("yearly");
  const [tripFrequency, setTripFrequency] = useState("yearly");
  const { loading, unlockScreen } = useLoadingContext();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    loading.start();

    try {
      const response = await getActivity();
      if (response.success) {
        setActivityData(response.results);
      } else {
        console.error("Failed to fetch activity data:", response);
      }
    } catch (error) {
      console.error("Error fetching activity data:", error);
    }

    loading.stop();
  };

  const handleFrequencyChange = (id: string, frequency: string) => {
    if (id === "spendChart") {
      setSpendFrequency(frequency);
    } else if (id === "tripChart") {
      setTripFrequency(frequency);
    }
  };

  const getSpendDataByFrequency = () => {
    if (!activityData) return { labels: [], data: [] };

    const data =
      spendFrequency === "yearly"
        ? activityData?.spendInfo.totalSpendYears
        : activityData?.spendInfo.totalSpendMonths;

    const labels = data.map((item) =>
      spendFrequency === "yearly"
        ? (item as any).year.toString()
        : (item as any).month.toString()
    );

    const amounts = data.map((item) => parseFloat(item.amount?.split(" ")[1]));

    return { labels, data: amounts };
  };

  const getTripDataByFrequency = () => {
    if (!activityData) return { labels: [], data: [] };

    const data =
      tripFrequency === "yearly"
        ? activityData.tripInfo.totalTripsYears
        : activityData.tripInfo.totalTripsMonths;

    const labels = data.map((item) =>
      tripFrequency === "yearly"
        ? (item as any).year.toString()
        : (item as any).month.toString()
    );

    const trips = data.map((item) => item.numOfTrips);

    return { labels, data: trips };
  };

  return (
    <Layout title={`${pageLinks.reporting.name} - ${app.name}`}>
      <div className="container">
        <div className={classes.page}>
          <div className={classes.header}>
            <Text tag="h1"> Activity Overview</Text>
            <ReportDownload />
          </div>
          {activityData ? (
            <div className={classes.reporting}>
              <ChartCard
                id="spendChart"
                frequency={spendFrequency}
                data={getSpendDataByFrequency().data}
                labels={getSpendDataByFrequency().labels}
                onFrequencyChange={handleFrequencyChange}
                title="Total Spend"
                totalText={activityData.spendInfo.currency}
                total={activityData.spendInfo.totalSpend}
              />
              <ChartCard
                id="tripChart"
                frequency={tripFrequency}
                data={getTripDataByFrequency().data}
                labels={getTripDataByFrequency().labels}
                onFrequencyChange={handleFrequencyChange}
                title="Total Trips"
                totalText="Trips"
                total={activityData.tripInfo.totalTrips}
              />
              <TopDestinations topDestinations={activityData.topDestinations} />
            </div>
          ) : (
            <div className={classes.skeleton}>
              <div className={classes.cards}>
                <Skeleton height={315} width={360} />
                <Skeleton height={315} width={360} />
                <Skeleton height={315} width={360} />
              </div>
              <Skeleton height={175} />
            </div>
          )}
          <Balance
            creditLimit={activityData ? activityData.creditLimit : ""}
            availableBalance={activityData ? activityData.availableBalance : ""}
          />
        </div>
      </div>
    </Layout>
  );
};

export const Reporting = React.memo(UnmemoReporting);
