import React from "react";
import { Text } from "src/components";
import classes from "./LabelValue.module.scss";

type Props = {
  label: string;
  value?: string;
  node?: React.ReactNode;
  valueNumber?: number;
};

const UnmemoLabelValue: React.FC<Props> = ({ label, value, node, valueNumber }) => {
  return (
    <>
      <Text tag="h6" className={classes.label}>
        {label}
      </Text>
      {value && <Text tag="h5">{value}</Text>}
      {valueNumber && <Text tag="h5">{valueNumber}</Text>}

      {node && node}
    </>
  );
};

export const LabelValue = React.memo(UnmemoLabelValue);
