import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { DropDown, Icon, Image, Input, Tag, Text } from "src/components";

import { assets, flightImagesURL, icons } from "src/configs";
import { AirlineService } from "src/services";
import { Airline } from "src/types/flight";
import classes from "./AirplaneInput.module.scss";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type Props = InputProps & {
  selectedFlights: Airline[] | undefined;
  onInputChange: (airlines: Airline[]) => void;
};

const UnmemoAirplaneInput: React.FC<Props> = ({
  onInputChange,
  selectedFlights,
  disabled,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const [airplane, setAirplane] = useState<Airline[] | undefined>(
    selectedFlights
  );

  const [searchedPlanes, setSearchedPlanes] = useState<Airline[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>("");

  const [isActive, setActive] = useState(false);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const savePlanes = async () => {
    setIsFetching(true);
    const response = await AirlineService.search(searchQuery);

    if (response.success && response.airlines) {
      const airplaneCodes = airplane ? airplane.map((plane) => plane.code) : [];
      setSearchedPlanes(
        response.airlines.filter((plane) => !airplaneCodes.includes(plane.code))
      );
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (searchQuery.length > 1) {
      savePlanes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  useEffect(() => {
    if (airplane) {
      onInputChange(airplane);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airplane]);

  useEffect(() => {
    if (searchedPlanes.length > 0) setActive(true);
    else setActive(false);
  }, [searchedPlanes]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const locationOptions = [
    ...searchedPlanes.map(({ code, name }) => ({
      name: (
        <>
          <Text tag="h5">
            {name} ({code})
          </Text>
        </>
      ),
      props: { value: code },
    })),
  ];

  const removeTag = (code: string) => {
    const updatedSelectedValue = airplane
      ? airplane.filter((value) => value.code !== code)
      : [];
    setAirplane(updatedSelectedValue);
  };

  useEffect(() => {
    if (selectedFlights) setAirplane(selectedFlights);
  }, [selectedFlights]);

  return (
    <>
      <div className={classes.wrapper} ref={ref}>
        <Input
          type="text"
          onChange={onChange}
          value={searchQuery}
          {...rest}
          disabled={disabled}
        />
        {searchedPlanes.length > 0 && !disabled && (
          <DropDown
            isActive={isActive}
            onClick={(value: any) => {
              const foundPlane = searchedPlanes.find(
                ({ code }) => code === value
              );

              if (foundPlane) {
                if (airplane) setAirplane([...airplane, foundPlane]);
                else setAirplane([foundPlane]);
              }

              setActive(false);
            }}
            options={locationOptions}
          />
        )}
        {isFetching && (
          <div className={classes.spinner}>
            <Image src={assets.spinner} width={20} height={20} alt="" />
          </div>
        )}
      </div>
      {airplane && airplane.length !== 0 && (
        <div className={classes.tags}>
          {airplane.map(({ name, code }, i) => (
            <Tag key={i}>
              <span className={classes.tag}>
                <Image
                  src={`${flightImagesURL}${code}.png`}
                  className={classes.img}
                />
                {name} ({code})
                <span className={classes.close} onClick={() => removeTag(code)}>
                  <Icon src={icons.cross.color} />
                </span>
              </span>
            </Tag>
          ))}
        </div>
      )}
    </>
  );
};

export const AirplaneInput = React.memo(UnmemoAirplaneInput);
