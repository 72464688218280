import React from "react";
import { Line, Profile, SideDrawer, Tag, Text } from "src/components";
import { BasicInfo } from "src/sections";
import {
  ApprovalProcessDetailed,
  approvalAction,
  approvalCondition,
} from "src/types/approval";

import classes from "./ProcessDetails.module.scss";

type Props = Omit<
  React.ComponentProps<typeof SideDrawer>,
  "title" | "children"
> & {
  processDetails?: ApprovalProcessDetailed;
};

const CONDITION_NAME_MAPPER = {
  [approvalCondition.allTrips]: "All Trips",
  [approvalCondition.inPolicy]: "In-Policy Trips",
  [approvalCondition.outOfPolicy]: "Out-of-Policy Trips",
};

const UnmemoProcessDetails: React.FC<Props> = ({ processDetails, ...rest }) => {
  if (!processDetails) {
    return <></>;
  }

  const {
    name,
    createdAt,
    approvalNeeded,
    approvalProcessRules,
    isDefault,
    travelerDetails,
  } = processDetails;

  return (
    <SideDrawer title={name} {...rest}>
      <BasicInfo
        info={[
          {
            label: "Name",
            value: `${name}`,
          },
          { label: "Create At", value: `${createdAt}` },
          {
            label: "Is approval Needed?",
            node: (
              <>
                {approvalNeeded ? (
                  <Tag variant="green">Yes</Tag>
                ) : (
                  <Tag variant="grey">No</Tag>
                )}
              </>
            ),
          },
          {
            label: "Is Default?",
            node: (
              <>
                {isDefault ? (
                  <Tag variant="green">Yes</Tag>
                ) : (
                  <Tag variant="grey">No</Tag>
                )}
              </>
            ),
          },
        ]}
      />

      <Line />

      <div className={classes.row}>
        <div className={classes.title}>
          <Text>Travelers Assigned</Text>
        </div>
        {travelerDetails && travelerDetails.length > 0 && (
          <div className={classes.travelerList}>
            {travelerDetails.map(({ name, profilePictureUrl }, i) => (
              <div className={classes.list} key={i}>
                <Profile name={name} profilePictureUrl={profilePictureUrl} />
              </div>
            ))}
          </div>
        )}
      </div>

      <Line />

      <div className={classes.row}>
        <div className={classes.title}>
          <Text>Approval Rules</Text>
        </div>
        {approvalProcessRules && approvalProcessRules.length > 0 && (
          <div className={classes.approvalRulesList}>
            {approvalProcessRules.map(
              ({ action, condition, reviewerName }, i) => (
                <div className={classes.rule} key={i}>
                  {action === approvalAction.notify ? (
                    <>
                      <Text>Send notification to</Text>{" "}
                      <Text tag="h4">{reviewerName}</Text> <Text>for</Text>{" "}
                      <Text tag="h4">{CONDITION_NAME_MAPPER[condition]}</Text>
                    </>
                  ) : (
                    <>
                      <Text>Request approval from</Text>{" "}
                      <Text tag="h4">{reviewerName}</Text> <Text>for</Text>{" "}
                      <Text tag="h4">{CONDITION_NAME_MAPPER[condition]}</Text>
                    </>
                  )}
                </div>
              )
            )}
          </div>
        )}
      </div>
    </SideDrawer>
  );
};

export const ProcessDetails = React.memo(UnmemoProcessDetails);
