import React from "react";
import classes from "./TopDestinations.module.scss";
import { Card, CenteredWrapper, SectionTitle, Text } from "src/components";

interface TopDestinationsProps {
  topDestinations: { city: string; country: string; numOfTrips: number }[];
}

const UnmemoTopDestinations: React.FC<TopDestinationsProps> = ({
  topDestinations,
}) => {
  return (
    <div className={classes.destinations}>
      <Text tag="h2">Top Destinations</Text>
      <ul>
        {topDestinations.map((destination, index) => (
          <li key={index}>
            <Text className={classes.number}>
              {" "}
              {destination.city}, {destination.country}{" "}
            </Text>
            <Text> {destination.numOfTrips} trips</Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const TopDestinations = React.memo(UnmemoTopDestinations);
