// activityService.ts

import axios from "axios";
import { API_BASE_URL, endpoints } from "src/configs";
import { ActivityOverviewResponse } from "src/types/reporting"; // Update import path
import { token } from "src/utils";

type GetActivity = () => Promise<ActivityOverviewResponse>; // Adjust type definition

export const getActivity: GetActivity = async () => {
  try {
    const res = await axios.get<ActivityOverviewResponse>(
      `${API_BASE_URL}${endpoints.reporting.activity}`, // Assuming "activity" is the endpoint for activity data
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.error("Failed to fetch activity overview:", error);
    throw new Error("Failed to fetch activity overview");
  }
};
