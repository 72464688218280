import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { FlightFilterParams, FlightSearchResponse } from "src/types/flight";
import { storage, token } from "src/utils";

type FilterFlight = (data: FlightFilterParams) => Promise<FlightSearchResponse>;

const INVALID_RESPONSE: FlightSearchResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const filterFlight: FilterFlight = async (data) => {
  const filterBounds = [];

  filterBounds.push({
    filterId: "TRAVEL_POLICY",
    travelPolicyConstraints: data.travelPolicies ? [data.travelPolicies] : [],
  });

  if (data.stops) {
    filterBounds.push({
      filterId: "STOPS",
      stopsConstraints: data.stops || [],
    });
  }

  if (data.cabins) {
    filterBounds.push({
      filterId: "CABIN_CLASS",
      cabinClassConstraints: data.cabins || [],
    });
  }

  filterBounds.push({
    filterId: "BAGGAGE",
    bggageConstraints: data.hasBaggage ? [data.hasBaggage] : [],
  });

  filterBounds.push({
    filterId: "AIRLINE",
    airlineConstraints: data.airlines || [],
  });

  filterBounds.push({
    filterId: "TIME",
    timeConstraints: [
      {
        groupId: "0",
        groupConstraints: [
          {
            filterId: "TIME_DEPARTURE",
            constraints: data.time0 || [],
          },
        ],
      },
      {
        groupId: "1",
        groupConstraints: [
          {
            filterId: "TIME_DEPARTURE",
            constraints: data.time1 || [],
          },
        ],
      },
    ],
  });

  try {
    const res = await axios.post<FlightSearchResponse["results"]>(
      `${API_BASE_URL}${endpoints.flight.filter}`,
      {
        transactionId: data.transactionId,
        cabinClass: data.cabinClass,
        policyId: data.policyId,
        page: data.page,
        sorting: data.sorting,
        sortOrder: data.sortOrder,
        filterBounds,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      storage.set("expiryTime", res.data.expirationTime);
      return { results: res.data, success: true };
    }

    console.error(`FILTER FLIGHT | ${SERVICE_FAILED_MESSAGE}`);

    storage.remove("expiryTime");
    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`FILTER FLIGHT | ${SERVICE_FAILED_MESSAGE} ${error}`);

    storage.remove("expiryTime");
    return INVALID_RESPONSE;
  }
};
