import React, { useEffect, useState } from "react";

import { FormGroup, Icon, SelectInput, Text } from "src/components";
import {
  APPROVAL_ACTIONS,
  APPROVAL_ACTIONS_1,
  APPROVAL_CONDITIONS,
  icons,
} from "src/configs";
import { useLoginContext } from "src/context";
import { ApprovalProcessRules, approvalAction } from "src/types/approval";
import { onInputChange } from "src/utils";
import classes from "./ApprovalRules.module.scss";

type Props = {
  rule: ApprovalProcessRules;
  canDelete: boolean;
  indexNo: number;
  onChange: (rule: ApprovalProcessRules, indexNo: number) => void;
  onDelete: (indexNo: number) => void;
  isApprovalNeeded: boolean;
};

const UnmemoApprovalRules: React.FC<Props> = ({
  canDelete,
  indexNo,
  rule,
  onChange,
  onDelete,
  isApprovalNeeded,
}) => {
  const { user } = useLoginContext();

  const [formData, setFormData] = useState<ApprovalProcessRules>(rule);

  useEffect(() => {
    setFormData(rule);
  }, [rule]);

  useEffect(() => {
    onChange(formData, indexNo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  // useEffect(() => {
  //   setFormData({ ...formData, action: approvalAction.notify });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isApprovalNeeded]);

  return (
    <FormGroup>
      <div className={classes.row}>
        <div className={classes.input1}>
          <SelectInput
            name="action"
            value={formData.action}
            onChange={(e) => onInputChange(e, formData, setFormData)}
            options={isApprovalNeeded ? APPROVAL_ACTIONS : APPROVAL_ACTIONS_1}
            disabled={!isApprovalNeeded}
          />
        </div>
        <Text>
          {formData.action === approvalAction.requestApproval ? "From" : "To"}
        </Text>
        <div className={classes.input2}>
          <SelectInput
            name="reviewerId"
            required
            value={formData.reviewerId}
            placeholder="Enter User Name/Email"
            onChange={(e) => onInputChange(e, formData, setFormData)}
            options={
              user && user.travelers
                ? [
                    {
                      name: `Select User`,
                      props: { value: "", disabled: true },
                    },
                    ...user.travelers.map((traveler) => ({
                      name: `${traveler.firstName} ${traveler.lastName}`,
                      props: { value: traveler.id },
                    })),
                  ]
                : []
            }
          />
        </div>
        <Text>For</Text>
        <div className={classes.input3}>
          <SelectInput
            name="condition"
            value={formData.condition}
            onChange={(e) => onInputChange(e, formData, setFormData)}
            options={APPROVAL_CONDITIONS}
          />
        </div>
        {canDelete && (
          <div className={classes.delete} onClick={() => onDelete(indexNo)}>
            <Icon src={icons.delete.color} />
          </div>
        )}
      </div>
    </FormGroup>
  );
};

export const ApprovalRules = React.memo(UnmemoApprovalRules);
