import emailSent from "src/assets/images/email_sent.svg";
import emptyTable from "src/assets/images/empty_table.svg";
import inviteUser from "src/assets/images/invite_user.svg";
import spinner from "src/assets/images/spinner.svg";
import spinnerWhite from "src/assets/images/spinner-2.svg";
import success from "src/assets/images/success.svg";
import logoColored from "src/assets/images/tripcater_logo.svg";
import logoWhite from "src/assets/images/tripcater_logo_white.svg";

export const logo = {
  default: logoColored,
  colored: logoColored,
  white: logoWhite,
};

export const assets = {
  spinner: spinner,
  spinnerWhite: spinnerWhite,
  emailSent: emailSent,
  inviteUser: inviteUser,
  emptyTable: emptyTable,
  success: success,
};

export const flightImagesURL = "https://images.kiwi.com/airlines/64x64/";
