import React from "react";
import { Line, SideDrawer, Tag } from "src/components";
import {
  BasicInfo,
  DetailedFlightInfo,
  TravelPolicySummary,
} from "src/sections";
import { componentSizes } from "src/types";
import {
  TravelPolicyOverview,
  TravelerInformation,
  TripsOverview,
  status,
} from "src/types/traveler";

import classes from "./UserDetails.module.scss";

type Props = Omit<
  React.ComponentProps<typeof SideDrawer>,
  "title" | "children"
> & {
  accountInformation?: TravelerInformation;
  travelPolicyOverview?: TravelPolicyOverview;
  tripsOverview?: TripsOverview[];
};

const UnmemoUserDetails: React.FC<Props> = ({
  accountInformation,
  travelPolicyOverview,
  tripsOverview,
  ...rest
}) => {
  if (!accountInformation) {
    return <></>;
  }

  return (
    <SideDrawer
      title={`${
        accountInformation.title ? `${accountInformation.title} ` : ""
      }${accountInformation.firstName} ${accountInformation.lastName}`}
      {...rest}
    >
      <BasicInfo
        profileImage={accountInformation.profilePictureUrl}
        name={`${accountInformation.firstName} ${accountInformation.lastName}`}
        info={[
          {
            label: "Name",
            value: `${
              accountInformation.title ? `${accountInformation.title} ` : ""
            }${accountInformation.firstName} ${accountInformation.lastName}`,
          },
          { label: "Email", value: accountInformation.email },
          { label: "Date of Birth", value: accountInformation.birthDate },
          { label: "Gender", value: accountInformation.gender },
          { label: "Phone Number", value: accountInformation.phoneNumber },
          { label: "Role Name", value: accountInformation.roleName },
          {
            label: "Status",
            node:
              accountInformation.status === status.active ? (
                <Tag variant="green" size={componentSizes.small}>
                  Active
                </Tag>
              ) : (
                <Tag variant="grey" size={componentSizes.small}>
                  Inactive
                </Tag>
              ),
          },
        ]}
      />

      {travelPolicyOverview && (
        <>
          <Line />
          <TravelPolicySummary {...travelPolicyOverview} />
        </>
      )}

      {tripsOverview && (
        <div className={classes.row}>
          {tripsOverview.length > 0 &&
            tripsOverview.map(
              ({ itineraryGroup, departureDate, returnDate, status }, i) => (
                <DetailedFlightInfo
                  identifier={""}
                  nonRefundable={false}
                  itinerary={itineraryGroup.itinerary}
                  policy={itineraryGroup.policy}
                  baggageInfo={itineraryGroup.baggageInfo}
                  pricingInformation={itineraryGroup.pricingInformation}
                  departureDate={departureDate}
                  returnDate={returnDate}
                  isShort
                  key={i}
                  showPrice
                  hideMainDate
                  status={status}
                />
              )
            )}
        </div>
      )}
    </SideDrawer>
  );
};

export const UserDetails = React.memo(UnmemoUserDetails);
