import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import {
  CreateTravelPolicyParams,
  CreateTravelPolicyResponse,
} from "src/types/travelPolicy";
import { token } from "src/utils";

type EditTravelPolicy = (
  params: CreateTravelPolicyParams
) => Promise<CreateTravelPolicyResponse>;

const INVALID_RESPONSE: CreateTravelPolicyResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const editTravelPolicy: EditTravelPolicy = async (params) => {
  try {
    const res = await axios.put<CreateTravelPolicyResponse>(
      `${API_BASE_URL}${endpoints.corporate.travelPolicies.edit}`,
      { ...params },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) return res.data;

    console.error(`UPDATE TRAVEL POLICIES | ${SERVICE_FAILED_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(
      `UPDATE TRAVEL POLICIES | ${SERVICE_FAILED_MESSAGE} ${error}`
    );

    return INVALID_RESPONSE;
  }
};
