import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { BookingStatus } from "src/types/checkout";
import { token } from "src/utils";

type Response = {
  success: boolean;
  message?: string;
  totals?: {
    status: BookingStatus;
    total: number;
  }[];
};

type GetTripsCount = () => Promise<Response>;

const INVALID_RESPONSE: Response = {
  success: false,
  message: ERROR_MESSAGE,
};

export const getTripsCount: GetTripsCount = async () => {
  try {
    const res = await axios.get<Response>(
      `${API_BASE_URL}${endpoints.trips.count}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`GET TRIPS COUNT | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`GET TRIPS COUNT | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
