import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { FlightSearchParams, FlightSearchResponse } from "src/types/flight";
import { storage, token } from "src/utils";

type SearchFlight = (data: FlightSearchParams) => Promise<FlightSearchResponse>;

const INVALID_RESPONSE: FlightSearchResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const searchFlight: SearchFlight = async (data) => {
  try {
    const res = await axios.post<FlightSearchResponse["results"]>(
      `${API_BASE_URL}${endpoints.flight.search}`,
      { ...data },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      storage.set("expiryTime", res.data.expirationTime);
      return { results: res.data, success: true };
    }

    console.error(`SEARCH FLIGHT | ${SERVICE_FAILED_MESSAGE}`);

    storage.remove("expiryTime");
    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`SEARCH FLIGHT | ${SERVICE_FAILED_MESSAGE} ${error}`);

    storage.remove("expiryTime");
    return INVALID_RESPONSE;
  }
};
