import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import {
  TravelersDeleteParams,
  TravelersDeleteResponse,
} from "src/types/traveler";
import { token } from "src/utils";

type DeleteTraveler = (
  ids: TravelersDeleteParams
) => Promise<TravelersDeleteResponse>;

const INVALID_RESPONSE: TravelersDeleteResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const deleteTraveler: DeleteTraveler = async ({ ids }) => {
  try {
    const res = await axios.delete<TravelersDeleteResponse>(
      `${API_BASE_URL}${endpoints.traveler.delete}`,
      {
        data: ids,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`DELETE TRAVEL | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`DELETE TRAVEL | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
