import React from "react";

import { ProfileImage, Text } from "src/components";
import { componentSizes } from "src/types";
import classes from "./Profile.module.scss";

type Props = {
  profilePictureUrl?: string;
  name: string;
};

const UnmemoProfile: React.FC<Props> = ({ profilePictureUrl, name }) => {
  return (
    <div className={classes.profile}>
      <ProfileImage
        profilePictureUrl={profilePictureUrl}
        name={name}
        size={componentSizes.regular}
      />
      <Text tag="h4">{name}</Text>
    </div>
  );
};

export const Profile = React.memo(UnmemoProfile);
