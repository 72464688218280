import React from "react";
import { Icon, Text } from "src/components";
import { icons } from "src/configs";
import { componentSizes } from "src/types";
import { FlightRoute as Flight, LayoverSummary } from "src/types/flight";
import { formatDate } from "src/utils";
import classes from "./FlightRoute.module.scss";

type Props = {
  from?: Flight;
  to?: Flight & { dateAdjustment?: number };
  duration?: string;
  layoverSummary?: LayoverSummary[];
};

const UnmemoFlightRoute: React.FC<Props> = ({
  from,
  to,
  duration,
  layoverSummary,
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.city}>
        <div className={classes.from}>
          <Text tag="h4">{from?.time}</Text>
        </div>
        <div className={classes.icon}>
          {layoverSummary?.map((layover, i) => (
            <div className={classes.iconList} key={i}>
              <Icon src={icons.flight.color} />
              <div className={classes.tooltip}>
                <Text size={componentSizes.small}>{layover.duration}</Text>
                <Text size={componentSizes.small} className={classes.cityName}>
                  {layover?.city}
                </Text>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.to}>
          <Text tag="h4">
            {to?.time}
            {to?.dateAdjustment ? <sup>+{to?.dateAdjustment}</sup> : <></>}
          </Text>
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.from}>
          <Text size={componentSizes.small}>
            {from && from.date ? formatDate(new Date(from.date)) : ""} -{" "}
            {from?.locationCode}
          </Text>
        </div>
        <div className={classes.duration}>
          <Text size={componentSizes.small} className={classes.text}>
            {duration}
          </Text>
          <Text size={componentSizes.small} className={classes.stop}>
            {layoverSummary && layoverSummary.length > 0
              ? `${layoverSummary?.length} ${
                  layoverSummary?.length === 1 ? `Stop` : `Stops`
                }`
              : ""}
          </Text>
        </div>
        <div className={classes.to}>
          <Text size={componentSizes.small}>
            {to && to.date ? formatDate(new Date(to.date)) : ""} - {to?.locationCode}
            <br />
          </Text>
        </div>
      </div>
    </div>
  );
};

export const FlightRoute = React.memo(UnmemoFlightRoute);
