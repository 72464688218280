import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { TravelerInformation } from "src/types/traveler";
import { token } from "src/utils";

type Response = {
  success: boolean;
  profilePictureUrl?: string;
  message?: string;
};

type UpdateTravelerProfilePicture = (
  id: TravelerInformation["id"],
  image: File
) => Promise<Response>;

const INVALID_RESPONSE: Response = {
  success: false,
  message: ERROR_MESSAGE,
};

export const updateTravelerProfilePicture: UpdateTravelerProfilePicture =
  async (id, image) => {
    try {
      const res = await axios.post<Response>(
        `${API_BASE_URL}${endpoints.traveler.get}/${id}/upload-profile-picture`,
        { image },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token.get()}`,
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        }
      );

      if (res.data) {
        return res.data;
      }

      console.error(
        `UPDATE TRAVELER PROFILE PICTURE | ${SERVICE_ERROR_MESSAGE}`
      );

      return INVALID_RESPONSE;
    } catch (error) {
      console.error(
        `UPDATE TRAVELER PROFILE PICTURE | ${SERVICE_FAILED_MESSAGE} ${error}`
      );

      return INVALID_RESPONSE;
    }
  };
