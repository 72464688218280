import React, { useState } from "react";
import {
  Card,
  Icon,
  Image,
  LabelValue,
  Profile,
  Tag,
  Text,
} from "src/components";
import { flightImagesURL, icons } from "src/configs";
import { componentSizes } from "src/types";
import { TravelPolicyOverview } from "src/types/traveler";

import { colorsMapper } from "src/types/travelPolicy";
import classes from "./TravelPolicySummary.module.scss";

type Props = TravelPolicyOverview;

const UnmemoTravelPolicySummary: React.FC<Props> = ({
  name,
  flightDynamicBudget,
  flightIsRefundable,
  flightMaxCabinClass,
  flightMaxCabinClassLongerLeg,
  flightExtrasRules,
  travelers,
  themeColor,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const onClick = () => setIsOpen(!isOpen);

  const { preferredAirlines, restrictedAirlines } = flightExtrasRules;

  return (
    <div className={classes.wrapper}>
      <div className={classes.heading}>
        <Text tag="h3">{"Travel Policy"}</Text>
        <Tag
          backgroundColor={colorsMapper[themeColor].bg}
          size={componentSizes.medium}
          color={colorsMapper[themeColor].color}
        >
          {name}
        </Tag>
      </div>
      <Card className={`${isOpen ? classes.open : ""}`}>
        <div className={classes.header}>
          <Icon src={icons.travelPolicy.primary} />
          <Text tag="h5">{"Travel Policy Details"}</Text>
          <div className={classes.dropDown} onClick={onClick}>
            <Icon src={icons.dropdown.color} />
          </div>
        </div>
        {isOpen && (
          <div className={classes.body}>
            {/* <div className={classes.list}>
              <LabelValue
                label={"Maximum budget"}
                value={flightDynamicBudget}
              />
            </div> */}
            <div className={classes.list}>
              <LabelValue
                label={"Max cabin class longer leg"}
                value={flightMaxCabinClassLongerLeg}
              />
            </div>
            <div className={classes.list}>
              <LabelValue
                label={"Max cabin class"}
                value={flightMaxCabinClass}
              />
            </div>
            <div className={classes.list}>
              <LabelValue
                label={"Refundable"}
                value={
                  flightIsRefundable
                    ? "Flight is refundable"
                    : "Flight is not refundable"
                }
              />
            </div>
            {restrictedAirlines.length > 0 && (
              <div className={`${classes.list} ${classes.full}`}>
                <LabelValue
                  label={"Restricted Airlines"}
                  node={
                    <div className={classes.flights}>
                      {restrictedAirlines.map(({ code, name }, i) => (
                        <Image
                          src={`${flightImagesURL}${code}.png`}
                          alt={name}
                          title={name}
                          width={32}
                          height={32}
                          key={i}
                        />
                      ))}
                    </div>
                  }
                />
              </div>
            )}
            {preferredAirlines.length > 0 && (
              <div className={`${classes.list} ${classes.full}`}>
                <LabelValue
                  label={"Preferred Airlines"}
                  node={
                    <div className={classes.flights}>
                      {preferredAirlines.map(({ code, name }, i) => (
                        <Image
                          src={`${flightImagesURL}${code}.png`}
                          alt={name}
                          title={name}
                          width={32}
                          height={32}
                          key={i}
                        />
                      ))}
                    </div>
                  }
                />
              </div>
            )}
            {travelers && travelers.length > 0 && (
              <div className={classes.travelerList}>
                {travelers.map(({ name, profilePictureUrl }, i) => (
                  <div className={classes.list} key={i}>
                    <Profile
                      profilePictureUrl={profilePictureUrl}
                      name={name}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

export const TravelPolicySummary = React.memo(UnmemoTravelPolicySummary);
