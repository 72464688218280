import React, { useEffect, useState } from "react";
import { Layout } from "src/Layout";
import { Button, CenteredGrid, ExpiredPopup, Line } from "src/components";
import { ERROR_MESSAGE, SUCCESS_MESSAGE, app, pageLinks } from "src/configs";
import { useAppContext, useLoadingContext } from "src/context";
import { CheckoutService } from "src/services";
import {
  GetTravelerCheckoutResponse,
  UpdateTravelerCheckoutParams,
} from "src/types/checkout";
import { Form } from "./Form";

import { useNavigate } from "react-router-dom";
import { componentVariants } from "src/types";
import classes from "./TravelerCheckout.module.scss";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const UnmemoTravelerCheckout: React.FC = () => {
  const navigate = useNavigate();
  const { loading } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();

  const [travelers, setTravelers] = useState<
    GetTravelerCheckoutResponse["travelers"]
  >(undefined);

  const [travelersUpdatedData, setTravelersUpdatedData] = useState<
    UpdateTravelerCheckoutParams["travelersInfo"]
  >([]);

  const onChangeTravelersUpdatedData = (index: number, travelerData: any) => {
    setTravelersUpdatedData((prevData) => {
      const updatedDataCopy = [...prevData];
      updatedDataCopy[index] = travelerData;
      return updatedDataCopy;
    });
  };

  const getTravelers = async () => {
    loading.start();

    const response = await CheckoutService.getTravelers();

    if (response.success && response.travelers)
      setTravelers(response.travelers);
    else showError(response.message ? response.message : ERROR_MESSAGE);

    loading.stop();
  };

  useEffect(() => {
    getTravelers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshTravelersData = async () => {
    await getTravelers();
  };

  const onSubmit = async () => {
    loading.start();

    if (
      travelersUpdatedData &&
      travelersUpdatedData.length > 0 &&
      travelersUpdatedData
    ) {
      const response = await CheckoutService.updateTravelers({
        travelersInfo: travelersUpdatedData,
      });

      if (response.success) {
        showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
        navigate(pageLinks.checkout.summary.link);
      } else showError(response.message ? response.message : ERROR_MESSAGE);
    } else showError("Please fill the fields");

    loading.stop();
  };

  return (
    <Layout title={`${pageLinks.checkout.confirmTravelers.name} - ${app.name}`}>
      <CenteredGrid>
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            {!travelers ? (
              <Skeleton height={40} count={10} />
            ) : (
              travelers.map((traveler, i) => (
                <Form
                  traveler={traveler}
                  key={i}
                  onUpdateData={(travelerData: any) =>
                    onChangeTravelersUpdatedData(i, travelerData)
                  }
                  refreshTravelersData={refreshTravelersData} // Pass the refresh function
                />
              ))
            )}

            <Line />

            {!travelers ? (
              <Skeleton height={50} count={2} /> // Adjust height and count as needed
            ) : (
              <>
                <div className={classes.footer}>
                  <Button
                    variant={componentVariants.secondary}
                    onClick={() => navigate(pageLinks.searchFlight.link)}
                  >
                    Back
                  </Button>
                  <Button onClick={onSubmit}>Continue</Button>
                </div>
              </>
            )}
          </div>
          <div className="col-lg-2"></div>
        </div>
      </CenteredGrid>
      <ExpiredPopup />
    </Layout>
  );
};

export const TravelerCheckout = React.memo(UnmemoTravelerCheckout);
