import React, { useEffect, useRef, useState } from "react";
import { ProfileImage, Text } from "src/components";
import { useLoginContext } from "src/context";
import { componentSizes } from "src/types";
import classes from "./Profile.module.scss";
import { ProfileDropdown } from "./components";

type Props = { isBGWhite?: boolean };

const UnmemoProfile: React.FC<Props> = ({ isBGWhite = false }) => {
  const { user } = useLoginContext();
  const [isActive, setActive] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const onClick = () => setActive(!isActive);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const { profilePictureUrl, firstName, lastName } = user;
  const name = `${firstName} ${lastName}`;

  return (
    <div className={`${classes.profile} ${isBGWhite ? classes.isWhite : ""}`}>
      <div className={classes.content} onClick={onClick} ref={ref}>
        <div className={classes.image}>
          <ProfileImage
            profilePictureUrl={profilePictureUrl}
            name={name}
            size={componentSizes.medium}
          />
        </div>
        <Text tag="h5" isWhite={isBGWhite}>
          {name}
        </Text>
      </div>

      <ProfileDropdown isVisible={isActive} />
    </div>
  );
};

export const Profile = React.memo(UnmemoProfile);
