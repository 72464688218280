import React from "react";

import { Icon } from "src/components";
import { icons } from "src/configs";
import classes from "./ColorSquare.module.scss";

type Props = {
  backgroundColor: string;
  borderColor: string;
  isSelected?: boolean;
  className?: string;
};

const UnmemoColorSquare: React.FC<Props> = ({
  backgroundColor,
  borderColor,
  className = "",
  isSelected = false,
}) => {
  return (
    <span
      className={`${classes.color} ${className}`}
      style={{
        backgroundColor,
        borderColor,
      }}
    >
      {isSelected && <Icon src={icons.tick.white} />}
    </span>
  );
};

export const ColorSquare = React.memo(UnmemoColorSquare);
