import React, { useState } from "react";

import { Text } from "src/components";
import { componentSizes } from "src/types";
import { PasswordChecker } from "../PasswordChecker";
import { PasswordTerms } from "../PasswordTerms";
import { isPasswordValid } from "../utils";
import classes from "./../../Input.module.scss";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type Props = InputProps & {
  showEye?: boolean;
  checkPassword?: boolean;
  error?: string;
};

const UnmemoPasswordInput: React.FC<Props> = ({
  showEye = false,
  checkPassword,
  value,
  error,
  ...rest
}) => {
  const [isPassword, setIsPassword] = useState(true);

  const activate = () => setIsPassword(false);
  const deactivate = () => setIsPassword(true);

  return (
    <div
      className={`${classes.passwordWrapper} ${
        isPassword ? classes.isPassword : ""
      }`}
    >
      <div className={classes.inputWrapper}>
        <input
          className={classes.input}
          {...rest}
          type={isPassword ? "password" : "text"}
          value={value}
          autoComplete="off"
        />
        {showEye && (
          <button
            type="button"
            onMouseDown={activate}
            onMouseUp={deactivate}
            onMouseLeave={deactivate}
          ></button>
        )}
      </div>
      {error && (
        <Text isError size={componentSizes.small}>
          {error}
        </Text>
      )}
      {checkPassword && (
        <>
          <PasswordChecker validate={isPasswordValid(String(value))} />
          <PasswordTerms password={String(value)} />
        </>
      )}
    </div>
  );
};

export const PasswordInput = React.memo(UnmemoPasswordInput);
