import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { TripByIDResponse, TripInfo } from "src/types/traveler";
import { token } from "src/utils";

type GetTripsByID = (id: TripInfo["id"]) => Promise<TripByIDResponse>;

const INVALID_RESPONSE: TripByIDResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const getTripsByID: GetTripsByID = async (id) => {
  try {
    const res = await axios.get<TripByIDResponse>(
      `${API_BASE_URL}${endpoints.trips.getByID}/${id}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return {
        ...res.data,
        orderSummary: res.data.orderSummary
          ? { ...res.data.orderSummary, id }
          : undefined,
      };
    }

    console.error(`GET TRIP BY ID | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`GET TRIP BY ID | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
