import React from "react";
import { Button, Icon, Line, ScreenLock, Text } from "src/components";
import { icons } from "src/configs";
import { componentVariants } from "src/types";
import classes from "./Popup.module.scss";

type Props = {
  title: string;
  children: React.ReactNode;
  cta?: {
    ctaName: string;
    ctaAction: () => void;
  };
  onClose?: {
    ctaName?: string;
    ctaAction: () => void;
  };
};

const UnmemoPopup: React.FC<Props> = ({ title, children, onClose, cta }) => {
  return (
    <>
      <ScreenLock />
      <div className={classes.popup}>
        <div className={classes.popupContent}>
          <div className={classes.header}>
            <Text tag="h4">{title}</Text>
            {onClose && (
              <div className={classes.closeIcon} onClick={onClose.ctaAction}>
                <Icon src={icons.cross.color} />
              </div>
            )}
          </div>

          {children}

          {(onClose || cta) && (
            <>
              <Line />
              <div className={classes.footer}>
                <div className={classes.btnBlock}>
                  {onClose && (
                    <Button
                      variant={componentVariants.secondary}
                      onClick={onClose.ctaAction}
                    >
                      {onClose.ctaName || "Close"}
                    </Button>
                  )}
                  {cta && (
                    <Button
                      variant={componentVariants.primary}
                      onClick={cta.ctaAction}
                    >
                      {cta.ctaName}
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const Popup = React.memo(UnmemoPopup);
