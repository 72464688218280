import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import {
  SearchLocation as SearchLocationData,
  SearchLocationResponse,
} from "src/types/location";
import { token } from "src/utils";

type SearchLocation = (
  data: SearchLocationData
) => Promise<SearchLocationResponse>;

export const searchLocation: SearchLocation = async (data) => {
  try {
    const res = await axios.get<SearchLocationResponse>(
      `${API_BASE_URL}${endpoints.location.search}?location=${data.location}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`SEARCH LOCATION | ${SERVICE_ERROR_MESSAGE}`);

    return {
      success: false,
      message: ERROR_MESSAGE,
    };
  } catch (error) {
    console.error(`SEARCH LOCATION | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return {
      success: false,
      message: ERROR_MESSAGE,
    };
  }
};
