import { Tag, TravelerList } from "src/components";
import { ApprovalProcessDetailed } from "src/types/approval";

export type ApprovalProcessTable = {
  key: ApprovalProcessDetailed;
  id: ApprovalProcessDetailed["id"];
  name: ApprovalProcessDetailed["name"];
  createdAt: string;
  assignees: React.ReactNode;
  approvalNeeded: React.ReactNode;
  approvers: React.ReactNode;
};

// const CONDITION_MAPPER: { [key: string]: React.ReactNode } = {
//   [approvalCondition.inPolicy]: <Tag variant={"green"}>In Policy</Tag>,
//   [approvalCondition.outOfPolicy]: <Tag variant={"red"}>Out of Policy</Tag>,
//   [approvalCondition.allTrips]: <Tag variant={"grey"}>All Trips</Tag>,
// };

const createTravelNode = (
  traveler: ApprovalProcessDetailed["travelerDetails"]
): React.ReactNode => {
  return <TravelerList travelers={traveler} />;
};

export const transformApprovalProcessList = (
  list: ApprovalProcessDetailed[]
) => {
  const transformedList: ApprovalProcessTable[] = list.map(
    (approvalProcess) => ({
      key: approvalProcess,
      id: approvalProcess.id,
      name: approvalProcess.name,
      // type: CONDITION_MAPPER[approvalProcess.approvalProcessRules[0].condition],
      createdAt: `${approvalProcess.createdAt}`,
      assignees: createTravelNode(approvalProcess.travelerDetails),
      approvalNeeded: (
        <>
          {approvalProcess.approvalNeeded ? (
            <Tag variant="green">Yes</Tag>
          ) : (
            <Tag variant="grey">No</Tag>
          )}
        </>
      ),
      approvers: createTravelNode(
        approvalProcess.approvalProcessRules.map(
          ({ reviewerName, reviewerId, profilePictureUrl }) => ({
            email: "",
            id: reviewerId,
            name: reviewerName,
            profilePictureUrl,
          })
        )
      ),
    })
  );

  return transformedList;
};
