import React from "react";

import classes from "./CenteredWrapper.module.scss";

type Props = {
  children: React.ReactNode;
};

const UnmemoCenteredWrapper: React.FC<Props> = ({ children }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.centerBox}>{children}</div>
    </div>
  );
};

export const CenteredWrapper = React.memo(UnmemoCenteredWrapper);
