import React, { useState } from "react";

import { Icon } from "src/components";
import { icons } from "src/configs";
import { ComponentVariant, componentVariants } from "src/types";
import classes from "./Accordion.module.scss";

type Props = {
  children: React.ReactNode;
  head: React.ReactNode;
  headDirection?: "right" | "left";
  isDefaultOpen?: boolean;
  variant?: ComponentVariant;
};

const UnmemoAccordion: React.FC<Props> = ({
  children,
  head,
  headDirection = "left",
  isDefaultOpen = false,
  variant = componentVariants.secondary,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const onClick = () => setIsOpen(!isOpen);

  return (
    <div
      className={`${classes.accordion} ${isOpen ? classes.open : ""} ${
        classes[headDirection]
      }`}
    >
      <div className={classes.head} onClick={onClick}>
        {head}
        <div className={classes.dropDown}>
          <Icon
            src={
              variant === componentVariants.primary
                ? icons.dropdown.primary
                : icons.dropdown.color
            }
          />
        </div>
      </div>
      {isOpen && <div>{children}</div>}
    </div>
  );
};

export const Accordion = React.memo(UnmemoAccordion);
