import React from "react";

import {
  ComponentSize,
  ComponentVariant,
  componentSizes,
  componentVariants,
} from "src/types";
import classes from "./Button.module.scss";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type Props = ButtonProps & {
  variant?: ComponentVariant;
  size?: ComponentSize;
  isFullWidth?: boolean;
  isFitWidth?: boolean;
  icon?: "dropdown";
  className?: string;
};

const UnmemoButton: React.FC<Props> = ({
  children,
  variant = componentVariants.primary,
  size = componentSizes.medium,
  isFullWidth = false,
  isFitWidth = false,
  icon,
  className,
  ...rest
}) => {
  return (
    <button
      className={`${classes.btn} ${classes[variant]} ${classes[size]} ${
        isFullWidth ? classes.fullWidth : ""
      } ${isFitWidth ? classes.fitWidth : ""} ${
        icon ? `${classes.hasIcon} ${classes[icon]}` : ""
      } ${className ? className : ""}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export const Button = React.memo(UnmemoButton);
