import React, {
  Dispatch,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  Actions,
  SearchFlightStateContextType,
  initialState,
  reducer,
} from "./reducer";

type SearchFlightContextType = {
  state: SearchFlightStateContextType;
  dispatch: Dispatch<Actions>;
  isLoading: boolean;
};

const SearchFlightContext = React.createContext<SearchFlightContextType>(
  undefined!
);

type Props = {
  children: React.ReactNode;
};

export const SearchFlightProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  return (
    <SearchFlightContext.Provider value={{ state, dispatch, isLoading }}>
      {children}
    </SearchFlightContext.Provider>
  );
};

const useSearchFlightContext = (): SearchFlightContextType =>
  useContext(SearchFlightContext);

export { useSearchFlightContext };
