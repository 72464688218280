import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import {
  ForgetPassword as ForgetPasswordData,
  ForgetPasswordResponse,
} from "src/types/password";

type ForgetPassword = (
  data: ForgetPasswordData
) => Promise<ForgetPasswordResponse>;

const INVALID_RESPONSE: ForgetPasswordResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const forgetPassword: ForgetPassword = async (data) => {
  try {
    const res = await axios.get<ForgetPasswordResponse>(
      `${API_BASE_URL}${endpoints.password.forget}?email=${data.email}`
    );

    if (res.data) {
      return res.data;
    }

    console.error(`FORGET PASSWORD | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`FORGET PASSWORD | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
