import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { GetTravelerCheckoutResponse } from "src/types/checkout";
import { token } from "src/utils";

type GetTravelers = () => Promise<GetTravelerCheckoutResponse>;

const INVALID_RESPONSE: GetTravelerCheckoutResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const getTravelers: GetTravelers = async () => {
  try {
    const res = await axios.get<GetTravelerCheckoutResponse>(
      `${API_BASE_URL}${endpoints.checkout.traveler.get}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) return res.data;

    console.error(`GET TRAVELERS FOR CHECKOUT | ${SERVICE_FAILED_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(
      `GET TRAVELERS FOR CHECKOUT | ${SERVICE_FAILED_MESSAGE} ${error}`
    );

    return INVALID_RESPONSE;
  }
};
