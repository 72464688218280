import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { TravelerDetails, TravelerInformation } from "src/types/traveler";
import { token } from "src/utils";

type GetTraveler = (id: TravelerInformation["id"]) => Promise<TravelerDetails>;

const INVALID_RESPONSE: TravelerDetails = {
  success: false,
  message: ERROR_MESSAGE,
};

export const getTraveler: GetTraveler = async (id) => {
  try {
    const res = await axios.get<TravelerDetails>(
      `${API_BASE_URL}${endpoints.traveler.get}/${id}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`GET TRAVEL | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`GET TRAVEL | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
