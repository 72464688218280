import activate from "src/assets/icons/activate.svg";
import addColor from "src/assets/icons/addColor.svg";
import approvalPolicy from "src/assets/icons/approval_process.svg";
import approvalPolicyWhite from "src/assets/icons/approval_process_white.svg";
import baggage from "src/assets/icons/baggage.svg";
import baggageColored from "src/assets/icons/baggageColored.svg";
import bookmark from "src/assets/icons/bookmark.svg";
import cross from "src/assets/icons/cross.svg";
import crossRed from "src/assets/icons/crossRed.svg";
import dashboard from "src/assets/icons/dashboard.svg";
import dashboardWhite from "src/assets/icons/dashboard_white.svg";
import deactivate from "src/assets/icons/deactivate.svg";
import deleteIcon from "src/assets/icons/delete.svg";
import dropdown from "src/assets/icons/dropdown.svg";
import dropdownColor from "src/assets/icons/dropdownColor.svg";
import edit from "src/assets/icons/edit.svg";
import flight from "src/assets/icons/flight.svg";
import handCarry from "src/assets/icons/handCarry.svg";
import infoRed from "src/assets/icons/info_red.svg";
import land from "src/assets/icons/land.svg";
import logout from "src/assets/icons/logout.svg";
import menu from "src/assets/icons/menu.svg";
import notification from "src/assets/icons/notification.svg";
import notificationWhite from "src/assets/icons/notificationWhite.svg";
import profile from "src/assets/icons/profile.svg";
import refundable from "src/assets/icons/refundable.svg";
import setting from "src/assets/icons/setting.svg";
import loyalty from "src/assets/icons/loyalty.svg";
import swap from "src/assets/icons/swap.svg";
import takeoff from "src/assets/icons/takeoff.svg";
import threeDot from "src/assets/icons/three_dots.svg";
import tickGreen from "src/assets/icons/tickGreen.svg";
import tickWhite from "src/assets/icons/tick_white.svg";
import travelPolicy from "src/assets/icons/travel_policy.svg";
import travelPolicyPrimary from "src/assets/icons/travel_policy_primary.svg";
import travelPolicyWhite from "src/assets/icons/travel_policy_white.svg";
import userManagement from "src/assets/icons/user_management.svg";
import userManagementWhite from "src/assets/icons/user_management_white.svg";
import reporting from "src/assets/icons/dashboard.svg";
import reportingWhite from "src/assets/icons/dashboard.svg";


export const icons = {
  dashboard: {
    color: dashboard,
    white: dashboardWhite,
  },
  reporting: {
    color: reporting,
    white: reportingWhite,
  },
  notification: {
    color: notification,
    white: notificationWhite,
  },
  userManagement: {
    color: userManagement,
    white: userManagementWhite,
  },
  threeDots: {
    color: threeDot,
  },
  edit: {
    color: edit,
  },
  delete: {
    color: deleteIcon,
  },
  profile: {
    color: profile,
  },
  setting: {
    color: setting,
  },
  loyalty: {
    color: loyalty,
  },
  logout: {
    color: logout,
  },
  cross: {
    color: cross,
    red: crossRed,
  },
  travelPolicy: {
    color: travelPolicy,
    primary: travelPolicyPrimary,
    white: travelPolicyWhite,
  },
  dropdown: {
    color: dropdown,
    primary: dropdownColor,
  },
  flight: {
    color: flight,
  },
  menu: {
    color: menu,
  },
  approvalPolicy: {
    color: approvalPolicy,
    white: approvalPolicyWhite,
  },
  deactivate: {
    color: deactivate,
  },
  activate: {
    color: activate,
  },
  tick: {
    white: tickWhite,
    green: tickGreen,
  },
  baggage: {
    green: baggage,
    color: baggageColored,
  },
  bookmark: {
    color: bookmark,
  },
  swap: {
    color: swap,
  },
  info: {
    red: infoRed,
  },
  takeoff: {
    color: takeoff,
  },
  land: {
    color: land,
  },
  refundable: {
    color: refundable,
  },
  add: {
    color: addColor,
  },
  handCarry: {
    color: handCarry,
  },
};
