import React from "react";

import { Tag, Text } from "src/components";
import { componentSizes } from "src/types";
import classes from "./SectionTitle.module.scss";

type Props = {
  title: string;
  description?: string;
  tableData?: {
    first: number;
    last: number;
    total: number;
  };
};

const UnmemoSectionTitle: React.FC<Props> = ({
  title,
  description,
  tableData,
}) => {
  return (
    <div className={`${classes.titleSection}`}>
      <div className={classes.titleAndTag}>
        <Text tag="h1" className={classes.title}>
          {title}
        </Text>
        {tableData && (
          <div className={classes.tag}>
            <Text tag="h5" className={classes.text}>
              Showing
            </Text>{" "}
            <Tag size={componentSizes.medium}>
              {tableData.first}-{tableData.last} of {tableData.total}
            </Tag>
          </div>
        )}
      </div>
      {description && <Text size={componentSizes.regular}>{description}</Text>}
    </div>
  );
};

export const SectionTitle = React.memo(UnmemoSectionTitle);
