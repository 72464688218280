import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { LoginResponse, SSOLoginParams } from "src/types/login";
import { token } from "src/utils";

type SSO = (data: SSOLoginParams) => Promise<LoginResponse>;

const INVALID_RESPONSE: LoginResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const sso: SSO = async (data) => {
  try {
    const res = await axios.post<LoginResponse>(
      `${API_BASE_URL}${endpoints.sso}`,
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (res.data) {
      if (res.data.success && res.data.token) {
        token.set(res.data.token);
      }

      return res.data;
    }

    console.error(`SSO LOGIN | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`SSO LOGIN | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
