import React from "react";

import { Image, Text } from "src/components";
import classes from "./IconAndTitle.module.scss";

type Props = {
  image: string;
  title?: string;
  description?: string;
};

const UnmemoIconAndTitle: React.FC<Props> = ({ image, title, description }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.icon}>
        <Image src={image} alt="" width={64} height={64} />
      </div>
      {title && (
        <Text tag="h5" className={classes.title}>
          {title}
        </Text>
      )}
      {description && <Text>{description}</Text>}
    </div>
  );
};

export const IconAndTitle = React.memo(UnmemoIconAndTitle);
