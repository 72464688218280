import { changeRequest } from "./changeRequest";
import { deleteTrip } from "./deleteTrip";
import { getTrips } from "./getTrips";
import { getTripsByID } from "./getTripsByID";
import { getTripsCount } from "./getTripsCount";
import { updateTrip } from "./updateTrip";

export const TripService = {
  get: getTrips,
  getCount: getTripsCount,
  delete: deleteTrip,
  getByID: getTripsByID,
  update: updateTrip,
  change: changeRequest,
};
