import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconAndTitle, Popup } from "src/components";
import { assets, pageLinks } from "src/configs";
import { useExpiryTime } from "src/hooks";
import classes from "./ExpiredPopup.module.scss";

type Props = {
  hasRefresh?: boolean;
};

const UnmemoExpiredPopup: React.FC<Props> = ({ hasRefresh = false }) => {
  const navigate = useNavigate();
  const { expiry } = useExpiryTime();

  const [isActive, setIsActive] = useState<boolean>(false);
  let timer: NodeJS.Timeout;

  useEffect(() => {
    clearTimeout(timer);
    setIsActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setTimeout(() => {
      setIsActive(true);
    }, expiry);

    return () => clearTimeout(timer);
  }, [expiry]);

  return (
    <>
      {isActive && (
        <div className={classes.wrapper}>
          <Popup
            title="Times Up!"
            onClose={{
              ctaName: "Search Again",
              ctaAction: () => navigate(pageLinks.home.link),
            }}
            cta={
              hasRefresh
                ? {
                    ctaName: "Refresh",
                    ctaAction: () => window.location.reload(),
                  }
                : undefined
            }
          >
            <IconAndTitle
              image={assets.emptyTable}
              title="Your search has been expired, please reload or start search again."
            />
          </Popup>
        </div>
      )}
    </>
  );
};

export const ExpiredPopup = React.memo(UnmemoExpiredPopup);
