import { format } from "date-fns";

export const formatDate = (date: Date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const formatDateForBE = (date: Date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

const getDaySuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDateInMonth = (date: string) => {
  // Parse the input date string into a Date object
  const inputDate = new Date(date);

  // Get the day of the week (e.g., "Sun")
  const dayOfWeek = format(inputDate, "EEE");

  // Get the day with suffix (e.g., "1st", "2nd", "3rd", "4th")
  const dayNumber = format(inputDate, "d");
  const dayWithSuffix = `${dayNumber}${getDaySuffix(parseInt(dayNumber, 10))}`;

  // Get the month and year (e.g., "Mar 26")
  const monthAndYear = format(inputDate, "MMM d");

  // Return the formatted date string (e.g., "Sun, Mar 26th, 2023")
  return `${dayOfWeek}, ${monthAndYear.replace(/\d+/, dayWithSuffix)}`;
};
