import { useEffect, useState } from "react";
import { useSearchFlightContext } from "src/context";

type UseExpiryTime = () => { expiry: number };

export const useExpiryTime: UseExpiryTime = () => {
  const { state, isLoading } = useSearchFlightContext();
  const [expiry, setExpiry] = useState<number>(30000);

  const { expirationTime } = state;

  useEffect(() => {
    if (!isLoading) {
      setExpiry(30000);
      return;
    }
    if (expirationTime && isLoading) {
      const remainingTime = Math.round(
        new Date(expirationTime).getTime() - new Date().getTime()
      );

      if (remainingTime < 0) setExpiry(0);
      else setExpiry(remainingTime);
    } else setExpiry(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, expirationTime]);

  return { expiry };
};
