import { assignTravelPolicy } from "./assignTravelPolicy";
import { createTravelPolicy } from "./createTravelPolicy";
import { deleteTravelPolicies } from "./deleteTravelPolicies";
import { editTravelPolicy } from "./editTravelPolicy";
import { getTravelPolicies } from "./getTravelPolicies";
import { getTravelPolicy } from "./getTravelPolicy";
import { getTravelPolicyToEdit } from "./getTravelPolicyToEdit";
import { searchTravelPolicies } from "./searchTravelPolicies";
import { unassignTravelPolicy } from "./unassignTravelPolicy";

export const TravelPolicyService = {
  get: getTravelPolicies,
  create: createTravelPolicy,
  search: searchTravelPolicies,
  delete: deleteTravelPolicies,
  getById: getTravelPolicy,
  getToEdit: getTravelPolicyToEdit,
  update: editTravelPolicy,
  assign: assignTravelPolicy,
  unassign: unassignTravelPolicy,
};
