import React from "react";

import { ProfileImage, Text } from "src/components";
import { Traveler } from "src/types/traveler";
import classes from "./TravelerList.module.scss";

type Props = {
  travelers: {
    id: Traveler["id"];
    name: string;
    profilePictureUrl?: Traveler["profilePictureUrl"];
  }[];
};

const UnmemoTravelerList: React.FC<Props> = ({ travelers }) => {
  return (
    <div className={classes.profiles}>
      {(travelers.length > 3 ? travelers.slice(0, 3) : travelers).map(
        ({ profilePictureUrl, name }, i) => (
          <ProfileImage
            profilePictureUrl={profilePictureUrl}
            name={name}
            key={i}
            className={classes.img}
          />
        )
      )}
      {travelers.length > 3 ? <Text> + {travelers.length - 3}</Text> : <></>}
    </div>
  );
};

export const TravelerList = React.memo(UnmemoTravelerList);
