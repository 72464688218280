// src/services/TravelerService.ts

import axios from 'axios';
import { API_BASE_URL } from 'src/configs';
import { token } from 'src/utils';
import { TravelerLoyaltyPrograms } from 'src/types/loyalty';

export const getTravelerLoyaltyPrograms = async (travelerId: number): Promise<TravelerLoyaltyPrograms> => {
  const response = await axios.get<TravelerLoyaltyPrograms>(
    `${API_BASE_URL}/traveler/${travelerId}/loyalty-programs`,
    {
      headers: {
        'accept': 'application/json, text/plain, */*',
        'authorization': `Bearer ${token.get()}`,
        'cache-control': 'no-cache',
        'pragma': 'no-cache',
      }
    }
  );
  return response.data;
};
