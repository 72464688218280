import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  DepartureDateInput,
  DirectOnlyInput,
  FlightFromInput,
  FlightMaxCabinInput,
  FlightToInput,
  FormGroup,
  Icon,
  Line,
  ReturnDateInput,
  UserListInput,
} from "src/components";
import { icons, pageLinks } from "src/configs";
import { ActionType, useAppContext, useSearchFlightContext } from "src/context";
import classes from "./SearchForm.module.scss";

const UnmemoSearchForm: React.FC = () => {
  const navigate = useNavigate();

  const { state, dispatch } = useSearchFlightContext();
  const { showError } = useAppContext();

  const { flightFrom, flightTo, departureDate, users, cabin } = state;

  const onSubmit = () => {
    if (flightFrom?.code && flightTo?.code && departureDate && cabin) {
      if (state.isAdmin) {
        if (users.length !== 0) {
          navigate(`${pageLinks.searchFlight.link}`);
        } else {
          showError("Please fill all fields!");
        }
      } else {
        navigate(`${pageLinks.searchFlight.link}`);
      }
    } else {
      showError("Please fill all fields!");
    }
  };

  const onSwapPlaces = () => {
    const tempFlightFrom = state.flightFrom;
    const tempFlightTo = state.flightTo;

    dispatch({
      type: ActionType.updateFlightFrom,
      payload: tempFlightTo,
    });

    dispatch({
      type: ActionType.updateFlightTo,
      payload: tempFlightFrom,
    });
  };

  return (
    <div className={classes.wrapper}>
      <FormGroup>
        <div className={classes.cities}>
          <FlightFromInput />
          <Icon src={icons.swap.color} onClick={() => onSwapPlaces()} />
          <FlightToInput />
        </div>
      </FormGroup>

      <FormGroup>
        <div className="row">
          <div className="col-lg-3">
            <DepartureDateInput />
          </div>
          <div className="col-lg-3">
            <ReturnDateInput />
          </div>
          <div className="col-lg-6">
            <FlightMaxCabinInput />
          </div>
        </div>
      </FormGroup>

      <FormGroup>
        <Line />
      </FormGroup>

      {state.isAdmin && (
        <>
          <FormGroup>
            {/* <div className="row"> */}
            {/* <div className="col-lg-4">
          <TravelPolicyInput />
        </div> */}
            {/* <div className="col-lg-4">
            
          </div>
        </div> */}
            <UserListInput />
          </FormGroup>
          <Line />
        </>
      )}

      <div className={classes.footer}>
        <DirectOnlyInput />
        <Button
          onClick={() => {
            onSubmit();
          }}
        >
          Search Flights
        </Button>
      </div>
    </div>
  );
};

export const SearchForm = React.memo(UnmemoSearchForm);
