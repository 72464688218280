import React, { useEffect, useState } from "react";
import { DateInput } from "src/components";
import { ActionType, useAppContext, useSearchFlightContext } from "src/context";

type Props = {
  disabled?: boolean;
};

const UnmemoReturnDateInput: React.FC<Props> = ({ disabled = false }) => {
  const { dispatch, state } = useSearchFlightContext();
  const { showError } = useAppContext();

  const [returnDate, setReturnDate] = useState<Date | null>(state.returnDate);

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  useEffect(() => {
    dispatch({
      type: ActionType.updateReturnDate,
      payload: returnDate,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnDate]);

  const onChange = (date: Date) => {
    if (state.departureDate) {
      setReturnDate(date);
    } else showError("Please select start date first!");
  };

  useEffect(() => {
    if (state.departureDate && returnDate && state.departureDate > returnDate) {
      setReturnDate(null);
    }
  }, [state.departureDate, returnDate]);

  return (
    <DateInput
      selected={returnDate}
      onChange={onChange}
      disabled={disabled}
      minDate={state.departureDate}
      placeholderText="Returning Date (Optional)"
      maxDate={maxDate}
    />
  );
};

export const ReturnDateInput = React.memo(UnmemoReturnDateInput);
