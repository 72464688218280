//component
import React from "react";
import { Card, CenteredWrapper, SectionTitle, Text } from "src/components";
import classes from "./Balance.module.scss";
interface BalanceProps {
  creditLimit: string;
  availableBalance: string;
}

const UnmemoBalance: React.FC<BalanceProps> = ({
  creditLimit,
  availableBalance,
}) => {
  return (
    <div className={classes.balance}>
      <div className={classes.item}>
        <div className="left">
          <Text tag="h2">Tripcater Credit</Text>
          <Text tag="p">
            Check Your Company Credit and let your teammates use them as soon as
            possible
          </Text>
        </div>
        <div className={classes.right}>
          <div className="credit">
            <Text className={classes.number}>{creditLimit}</Text>
            <Text className={classes.text}>Credit Limit </Text>
          </div>
          <div className="available">
            <Text className={classes.number}>{availableBalance}</Text>
            <Text className={classes.text}>Available</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Balance = React.memo(UnmemoBalance);
