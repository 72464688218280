import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { AirlineResponse } from "src/types/flight";
import { token } from "src/utils";

type SearchAirline = (searchQuery: string) => Promise<AirlineResponse>;

const INVALID_RESPONSE: AirlineResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const searchAirline: SearchAirline = async (searchQuery) => {
  try {
    const res = await axios.get<AirlineResponse>(
      `${API_BASE_URL}${endpoints.airline.search}?nameorcode=${searchQuery}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`SEARCH AIRLINE | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`SEARCH AIRLINE | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
