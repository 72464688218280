import axios from 'axios';
import { API_BASE_URL } from 'src/configs';
import { token } from 'src/utils';
import { TravelerLoyaltyPrograms } from 'src/types/loyalty';

export const deleteTravelerLoyaltyPrograms = async ( programId: number): Promise<TravelerLoyaltyPrograms> => {
  try {
    const response = await axios.delete<TravelerLoyaltyPrograms>(
      `${API_BASE_URL}traveler/loyalty-program/${programId}/delete`,
      {
        data: { programId },
        headers: {
          'accept': 'application/json, text/plain, */*',
          'authorization': `Bearer ${token.get()}`,
          'cache-control': 'no-cache',
          'pragma': 'no-cache',
        }
      }
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      programs: [],
      // message: 'Failed to fetch traveler loyalty programs'
    };
  }
};
