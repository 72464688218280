import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { Traveler } from "src/types/traveler";
import { token } from "src/utils";

type UserResponse = {
  userInfo?: Traveler;
  success: boolean;
  message?: string;
};

type GetUserDetails = () => Promise<UserResponse>;

const INVALID_RESPONSE: UserResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const getUserDetails: GetUserDetails = async () => {
  try {
    const res = await axios.get<UserResponse>(
      `${API_BASE_URL}${endpoints.user}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`GET USER | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`GET USER | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
