import React, { useEffect, useRef, useState } from "react";

import { Icon } from "src/components";
import { icons } from "src/configs";
import { MenuOpen } from "../MenuOpen";
import classes from "./Actions.module.scss";

type Props = {
  item: any;
  onDelete?: (data: any) => void;
  onStatusChange?: (data: any) => void;
  onUpdate?: (data: any) => void;
  moreActions?: {
    name: any;
    icon: string;
    action: any;
  }[];
};

const UnmemoActions: React.FC<Props> = ({ item, ...rest }) => {
  const [isActive, setActive] = useState(false);
  const actionsRef = useRef<HTMLDivElement | null>(null);

  const onClick = () => setActive(!isActive);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        actionsRef.current &&
        !actionsRef.current.contains(event.target as Node)
      ) {
        setActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={classes.actions} ref={actionsRef}>
      <div className={classes.menuIcon} onClick={onClick}>
        <Icon src={icons.threeDots.color} />
      </div>

      {isActive && (
        <MenuOpen item={item} {...rest} isActive={isActive} onHide={onClick} />
      )}
    </div>
  );
};

export const Actions = React.memo(UnmemoActions);
