import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { DropDown } from "src/components";
import classes from "./../../Input.module.scss";

type InputProps = React.SelectHTMLAttributes<HTMLSelectElement>;
type Option = React.OptionHTMLAttributes<HTMLOptionElement>;

type Props = InputProps & {
  options: { name: string; props: Option }[];
  placeholder?: string; 
  withSearch?: boolean;
  icon?: "cabin";
};

const UnmemoSelectInput: React.FC<Props> = ({
  options,
  placeholder,
  withSearch = false,
  icon,
  ...rest
}) => {
  const [selectedValue, setSelectedValue] = useState(rest.defaultValue);
  const [isActive, setActive] = useState(false);

  const ref = useRef<HTMLSelectElement>(null);

  const onClick = () => setActive(!isActive);

  const onChange = (value: any) => {
    setSelectedValue(value);
    if (rest.onChange)
      rest.onChange({
        target: { name: rest.name, value },
      } as ChangeEvent<HTMLSelectElement>);
  };

  const onButtonClick = (value: any) => {
    if (ref.current) {
      ref.current.value = String(value);
      onChange(value);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={`${classes.inputWrapper} ${classes.select}`}>
      <select
        ref={ref}
        className={`${classes.input} ${
          selectedValue === "" ? classes.placeholderSelected : ""
        } ${icon ? `${classes.hasIcon} ${classes[icon]}` : ""}`}
        {...rest}
        onChange={onChange}
        onClick={onClick}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map(({ name, props }, i) => (
          <option {...props} key={i}>
            {name}
          </option>
        ))}
      </select>
      <span className={classes.icon}></span>
      <DropDown options={options} isActive={isActive} onClick={onButtonClick} />
    </div>
  );
};

export const SelectInput = React.memo(UnmemoSelectInput);
