import React from "react";
import { CenteredGrid, Text } from "src/components";

import { useLoginContext } from "src/context";
import { componentSizes } from "src/types";
import classes from "./FlightSearch.module.scss";
import { SearchForm } from "./components";

const UnmemoFlightSearch: React.FC = () => {
  const { user } = useLoginContext();

  return (
    <div className={`${classes.wrapper}`}>
      <div className={classes.content}>
        <CenteredGrid>
          <div className={classes.text}>
            <Text size={componentSizes.small} isWhite>
              Welcome back, {user.firstName}
            </Text>
            <Text tag="h1" isWhite>
              Need to book a<br /> business trip? Let’s go!
            </Text>
          </div>
          <SearchForm />
        </CenteredGrid>
      </div>
    </div>
  );
};

export const FlightSearch = React.memo(UnmemoFlightSearch);
