import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { DropDown, Icon, Input, ProfileImage, Tag } from "src/components";
import { Traveler } from "src/types/traveler";

import { icons } from "src/configs";
import { useLoginContext, useSearchFlightContext } from "src/context";
import { componentSizes } from "src/types";
import classes from "./UsersInput.module.scss";
import { AddTraveler } from "./components";

type Props = {
  onUpdateUser: (users: Traveler[]) => void;
  hasIcon?: boolean;
  selectedUsersDefault: Traveler["id"][];
};

const UnmemoUsersInput: React.FC<Props> = ({
  onUpdateUser,
  hasIcon = true,
  selectedUsersDefault,
}) => {
  const { user } = useLoginContext();
  const { state } = useSearchFlightContext();

  const [render, setRender] = useState<number>(0);

  const [selectedValue, setSelectedValue] =
    useState<Traveler["id"][]>(selectedUsersDefault);

  const [selectedUsers, setSelectedUsers] = useState<Traveler[]>([]);

  useEffect(() => {
    if (selectedUsersDefault.length > 0 && render === 0) {
      setSelectedValue(selectedUsersDefault);
      setRender(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsersDefault]);

  const [isActive, setActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [addUserActive, setAddUserActive] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const handleDropdown = () => {
    if (searchQuery.length > 0) setActive(true);
    else setActive(!isActive);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node))
        setActive(false);
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchQuery.length > 0) setActive(true);
    else setActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const { travelers } = user;

  // const isPolicyHasTraveler = travelers.some(
  //   (traveler) => traveler.policyId === state.policyId
  // );

  const isPolicyHasTraveler = false;

  const travelersOption = (
    isPolicyHasTraveler
      ? travelers.filter((traveler) => traveler.policyId === state.policyId)
      : travelers
  ).map((traveler) => ({
    name: `${traveler.firstName} ${traveler.lastName}`,
    props: { value: traveler.id },
  }));

  const travelersFilteredOptions = travelersOption.filter(
    (option) =>
      option.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      !selectedValue.includes(option.props.value)
  );

  const selectUsers = () => {
    setSelectedUsers(
      travelers.filter((traveler) => selectedValue.includes(traveler.id))
    );
  };

  const removeUsers = () => {
    setSelectedUsers([]);
    setSelectedValue([]);
  };

  useEffect(() => {
    selectUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    removeUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.policyId]);

  useEffect(() => {
    if (selectedUsers.length > 0 || searchQuery.length > 2) {
      onUpdateUser(selectedUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  const removeTag = (id: Traveler["id"]) => {
    const updatedSelectedValue = selectedValue.filter((value) => value !== id);
    setSelectedValue(updatedSelectedValue);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div ref={ref}>
          <Input
            name="travelers"
            placeholder="Search Travelers"
            value={searchQuery}
            onChange={onInputChange}
            onClick={() => handleDropdown()}
            icon={hasIcon ? "userAdd" : undefined}
          />
        </div>
        <DropDown
          isActive={isActive}
          options={travelersFilteredOptions}
          onClick={(value: any) => {
            setSelectedValue([...selectedValue, value]);
          }}
          cta={{
            name: "Add Traveler",
            action: () => {
              setAddUserActive(true);
            },
          }}
        />
      </div>
      {selectedUsers.length !== 0 && (
        <div className={classes.tags}>
          {selectedUsers.map(
            ({ firstName, lastName, id, profilePictureUrl }, i) => (
              <Tag key={i}>
                <span className={classes.tag}>
                  <ProfileImage
                    size={componentSizes.small}
                    name={`${firstName} ${lastName}`}
                    profilePictureUrl={profilePictureUrl}
                  />
                  {firstName} {lastName}
                  <span className={classes.close} onClick={() => removeTag(id)}>
                    <Icon src={icons.cross.color} />
                  </span>
                </span>
              </Tag>
            )
          )}
        </div>
      )}
      <AddTraveler
        isActive={addUserActive}
        onClose={() => setAddUserActive(false)}
      />
    </>
  );
};

export const UsersInput = React.memo(UnmemoUsersInput);
