import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import {
  TravelerInviteParams,
  TravelerInviteResponse,
} from "src/types/traveler";
import { token } from "src/utils";

type InviteTraveler = (
  params: TravelerInviteParams
) => Promise<TravelerInviteResponse>;

const INVALID_RESPONSE: TravelerInviteResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const inviteTraveler: InviteTraveler = async (params) => {
  try {
    const res = await axios.post<TravelerInviteResponse>(
      `${API_BASE_URL}${endpoints.traveler.invite}`,
      { ...params },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`INVITE TRAVEL | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`INVITE TRAVEL | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
