import React, { useEffect, useState } from "react";
import { DateInput } from "src/components";
import { ActionType, useSearchFlightContext } from "src/context";

type Props = {
  disabled?: boolean;
};

const UnmemoDepartureDateInput: React.FC<Props> = ({ disabled = false }) => {
  const { dispatch, state } = useSearchFlightContext();
  const [departureDate, setDepartureDate] = useState<Date | null>(
    state.departureDate
  );

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  useEffect(() => {
    if (departureDate) {
      dispatch({
        type: ActionType.updateDepartureDate,
        payload: departureDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departureDate]);

  return (
    <DateInput
      selected={departureDate}
      onChange={(date) => setDepartureDate(date)}
      disabled={disabled}
      placeholderText="Departure Date"
      maxDate={maxDate}
    />
  );
};

export const DepartureDateInput = React.memo(UnmemoDepartureDateInput);
