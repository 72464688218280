import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import {
  TravelPolicyListResponse,
  TravelPolicySearchParams,
} from "src/types/travelPolicy";
import { token } from "src/utils";

type SearchTravelPolicies = (
  params: TravelPolicySearchParams
) => Promise<TravelPolicyListResponse>;

const INVALID_RESPONSE: TravelPolicyListResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const searchTravelPolicies: SearchTravelPolicies = async (params) => {
  try {
    const res = await axios.get<TravelPolicyListResponse>(
      `${API_BASE_URL}${endpoints.corporate.travelPolicies.search}?name=${
        params.name || ""
      }&sortBy=${params.sortBy || ""}&orderBy=${
        params.orderBy || ""
      }&pageIndex=${params.pageIndex || ""}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) return res.data;

    console.error(`SEARCH TRAVEL POLICIES | ${SERVICE_FAILED_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(
      `SEARCH TRAVEL POLICIES | ${SERVICE_FAILED_MESSAGE} ${error}`
    );

    return INVALID_RESPONSE;
  }
};
