import { cabin } from "src/types/travelPolicy";
import { roles } from "src/types/traveler";
import { storage } from "src/utils";
import {
  ActionType,
  Actions,
  Reducer,
  SearchFlightStateContextType,
} from "./types";

const storedDepartureDate = storage.get("departureDate");
const storedReturnDate = storage.get("returnDate");
const storedExpiryDate = storage.get("expiryTime");

const initialState: SearchFlightStateContextType = {
  flightFrom: {
    cityName: storage.get("flightFromCity") || "",
    code: storage.get("flightFromCode") || "",
  },
  flightTo: {
    cityName: storage.get("flightToCity") || "",
    code: storage.get("flightToCode") || "",
  },
  departureDate: storedDepartureDate
    ? new Date(storedDepartureDate)
    : new Date(),
  returnDate: storedReturnDate ? new Date(storedReturnDate) : null,
  cabin: storage.get("cabin") || cabin.economy,
  users: JSON.parse(localStorage.getItem("selectedTravelers") || "[]") || [],
  directOnly: false,
  transactionId: "",
  policyId: storage.get("travelPolicy") || 0,
  isAdmin: false,
  expirationTime: storedExpiryDate ? new Date(storedExpiryDate) : null,
};

const reducer: Reducer = (
  state: SearchFlightStateContextType,
  action: Actions
) => {
  if (action.type === ActionType.updateFlightFrom) {
    storage.set("flightFromCity", action.payload?.cityName);
    storage.set("flightFromCode", action.payload?.code);
    return {
      ...state,
      flightFrom: {
        cityName: action.payload?.cityName || "",
        code: action.payload?.code || "",
      },
    };
  }

  if (action.type === ActionType.updateFlightTo) {
    storage.set("flightToCity", action.payload?.cityName);
    storage.set("flightToCode", action.payload?.code);
    return {
      ...state,
      flightTo: {
        cityName: action.payload?.cityName || "",
        code: action.payload?.code || "",
      },
    };
  }

  if (action.type === ActionType.updateDepartureDate) {
    storage.set("departureDate", action.payload.toISOString());
    return {
      ...state,
      departureDate: action.payload,
    };
  }

  if (action.type === ActionType.updateReturnDate) {
    storage.set(
      "returnDate",
      action.payload ? action.payload.toISOString() : ""
    );
    return {
      ...state,
      returnDate: action.payload,
    };
  }

  if (action.type === ActionType.updateCabin) {
    storage.set("cabin", action.payload);
    return {
      ...state,
      cabin: action.payload,
    };
  }

  if (action.type === ActionType.updateUsers) {
    storage.set("selectedTravelers", JSON.stringify(action.payload));
    return {
      ...state,
      users: action.payload,
    };
  }

  if (action.type === ActionType.updateDirectFlight) {
    return {
      ...state,
      directOnly: action.payload,
    };
  }

  if (action.type === ActionType.updateTransactionId) {
    return {
      ...state,
      transactionId: action.payload,
    };
  }

  if (action.type === ActionType.updateTravelPolicy) {
    storage.set("travelPolicy", action.payload);
    return {
      ...state,
      policyId: action.payload,
    };
  }

  if (action.type === ActionType.updateLoginUser) {
    return {
      ...state,
      isAdmin:
        action.payload !== roles.traveler && action.payload !== roles.guest,
    };
  }

  if (action.type === ActionType.updateExpirationTime) {
    storage.set("expiryTime", action.payload);
    return {
      ...state,
      expirationTime: action.payload,
    };
  }

  return state;
};

export { initialState, reducer };
