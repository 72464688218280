import React, { useEffect } from "react";
import { Footer, Header, Main } from "src/components";

import { useLoginContext } from "src/context";

type Props = {
  children: React.ReactNode;
  title: string;
  isHome?: boolean;
};

const UnmemoLayout: React.FC<Props> = ({ children, title, isHome = false }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  const { isLogin } = useLoginContext();

  if (isLogin)
    return (
      <>
        <Main isHome={isHome}>{children}</Main>
        <Footer />
      </>
    );

  return (
    <>
      <Header isBGWhite={isHome} />
      {children}
      <Footer />
    </>
  );
};

export const Layout = React.memo(UnmemoLayout);
