import React from "react";

import { ComponentSize, componentSizes } from "src/types";

type Props = {
  children: React.ReactNode;
  padding?: ComponentSize;
  className?: string;
};

const UnmemoCard: React.FC<Props> = ({
  children,
  padding = componentSizes.regular,
  className,
}) => {
  return (
    <div className={`card ${padding}Padding ${className ? className : ""}`}>
      {children}
    </div>
  );
};

export const Card = React.memo(UnmemoCard);
