import React, { useEffect, useState } from "react";
import { Layout } from "src/Layout";
import {
  CenteredGrid,
  ExpiredPopup,
  IconAndTitle,
  Line,
  Popup,
  Text,
} from "src/components";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
  app,
  assets,
  pageLinks,
} from "src/configs";
import { DetailedFlightInfo } from "src/sections";

import { useNavigate } from "react-router-dom";
import {
  useAppContext,
  useLoadingContext,
  useSearchFlightContext,
} from "src/context";
import { CheckoutService } from "src/services";
import {
  BookingStatus,
  ConfirmBookingResponse,
  GetTravelerCheckoutResponse,
  OrderTotal,
  bookingStatus,
} from "src/types/checkout";
import { ItineraryGroup } from "src/types/flight";
import { TripStatus } from "src/types/traveler";
import { formatDateForBE } from "src/utils";
import classes from "./SummaryCheckout.module.scss";
import { OrderSummary, TravelerDetail } from "./components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const UnmemoSummaryCheckout: React.FC = () => {
  const navigate = useNavigate();
  const { showError, showSuccess } = useAppContext();
  const { loading } = useLoadingContext();
  const { state } = useSearchFlightContext();

  const [orderSummary, setOrderSummary] = useState<OrderTotal | undefined>(
    undefined
  );

  const [isApproved, setIsApproved] = useState<boolean>(false);

  const [selectedStatus, setSelectedStatus] = useState<
    BookingStatus | undefined
  >(undefined);

  const [bookingResponse, setBookingResponse] = useState<
    ConfirmBookingResponse | undefined
  >(undefined);

  const { departureDate, returnDate } = state;

  const [travelers, setTravelers] = useState<
    GetTravelerCheckoutResponse["travelers"]
  >(undefined);

  const [itineraryDetails, setItineraryDetails] = useState<
    ItineraryGroup | undefined
  >(undefined);

  const getSummary = async () => {
    loading.start();
    const responseVerify = await CheckoutService.verify();

    if (responseVerify.success) setIsApproved(!responseVerify.approvalRequired);
    else setIsApproved(false);

    const response = await CheckoutService.getSummary();

    if (response.success && response.orderSummary) {
      const {
        orderTotal,
        travelersInfo,
        itineraryDetails,
      } = response.orderSummary;
      setOrderSummary(orderTotal);
      setTravelers(travelersInfo);
      setItineraryDetails(itineraryDetails);
    } else {
      setTravelers(undefined);
      setOrderSummary(undefined);
      setItineraryDetails(undefined);
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  const confirmBooking = async (status: BookingStatus) => {
    loading.start();

    const response = await CheckoutService.confirm({ status });

    if (response.result) {
      if (response.success) {
        setBookingResponse(response);
        setSelectedStatus(status);
        showSuccess(
          response.result.message ? response.result.message : SUCCESS_MESSAGE
        );
      } else {
        setBookingResponse(undefined);
        showError(
          response.result.errors[0].message
            ? response.result.errors[0].message
            : ERROR_MESSAGE
        );
      }
    } else {
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  useEffect(() => {
    getSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title={`${pageLinks.checkout.summary.name} - ${app.name}`}>
      <CenteredGrid>
        <div className="row">
          <div className="col-lg-8">
            <div className={classes.row}>
              {!itineraryDetails ? (
                <Skeleton height={40} count={10} />
              ) : (
                itineraryDetails && (
                  <DetailedFlightInfo
                    {...itineraryDetails}
                    departureDate={formatDateForBE(departureDate)}
                    returnDate={
                      returnDate ? formatDateForBE(returnDate) : undefined
                    }
                    onSelect={(id: string) => {}}
                    isShort
                  />
                )
              )}
            </div>

            <div className={classes.row}>
              <Line />
            </div>
            {!travelers ? (
              <Skeleton height={40} count={2} />
            ) : (
              <>
                <div className={classes.row}>
                  <Text tag="h3">Travelers</Text>
                </div>

                {travelers &&
                  travelers.map(({ ...rest }, i) => (
                    <div className={classes.row} key={i}>
                      <TravelerDetail {...rest} />
                    </div>
                  ))}
              </>
            )}
          </div>

          <div className="col-lg-4">
            <div className={classes.summary}>
              {!orderSummary ? (
                <Skeleton height={40} count={10} />
              ) : (
                orderSummary && (
                  <OrderSummary
                    {...orderSummary}
                    onConfirm={confirmBooking}
                    onApprove={(status: TripStatus) => {}}
                    isApproved={isApproved}
                    canBook
                    canDraft
                  />
                )
              )}
            </div>
          </div>
        </div>
      </CenteredGrid>

      {bookingResponse && (
        <Popup
          title="Success"
          onClose={{
            ctaAction: () => {
              navigate(pageLinks.trips.link);
            },
          }}
        >
          <IconAndTitle
            image={assets.success}
            description={`Your order ${bookingResponse.result?.orderId} ${
              selectedStatus === bookingStatus.draft
                ? "is on hold for 2 hours"
                : "has been saved"
            }.`}
          />
        </Popup>
      )}

      <ExpiredPopup />
    </Layout>
  );
};

export const SummaryCheckout = React.memo(UnmemoSummaryCheckout);
