import { ItineraryGroup } from "./flight";
import { Cabin } from "./travelPolicy";

export const serviceType = {
  flight: "Flight",
} as const;

export type ServiceType = typeof serviceType[keyof typeof serviceType];

export type SelectServiceParams = {
  serviceType: ServiceType;
  transactionId: string;
  serviceItemId: string;
  cabinClass: Cabin;
};

export type SelectServiceResponse = {
  success: boolean;
  message?: string;
};

export type TravelerCheckout = {
  programName: string | number | readonly string[] | undefined;
  id: number;
  email: string;
  hasMissingInfo: boolean;
  title?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  birthDate?: string;
  profilePictureUrl?: string;
  passport: {
    id: number;
    number: string;
    expiryDate: string;
    issueCountry: string;
  }[];
  loyaltyProgram: {
    programId: number;
    programName: string;
    programNumber: string;
  };
};

export type GetTravelerCheckoutResponse = {
  success: boolean;
  message?: string;
  travelers?: TravelerCheckout[];
};

export type UpdateTravelerCheckoutParams = {
  travelersInfo: {
    id: null;
    title: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    birthDate: string;
    passport: {
      id: number;
      number: string;
      expiryDate: string;
      issueCountry: string;
    }[];
    loyaltyProgram: {
      programId: number;
      programName: string;
      programNumber: number;
    }[];
  }[];
};

export type UpdateTravelerCheckoutResponse = {
  success: boolean;
  message?: string;
};

export type OrderTotal = {
  orderTotal: number;
  fareAmount: number;
  totalTax: number;
  numberOfTravelers: number;
  currency: string;
  // taxes: {
  //   amount: number;
  //   code: string;
  //   description: string;
  //   station: string;
  // }[];
};

export type SummaryCheckoutResponse = {
  success: boolean;
  message?: string;
  orderSummary?: {
    orderTotal: OrderTotal;
    travelersInfo: GetTravelerCheckoutResponse["travelers"];
    itineraryDetails: ItineraryGroup;
  };
};

export const bookingStatus = {
  draft: "draft",
  pendingApproval: "pendingApproval",
  waiting: "Pending Approval",
  rejected: "Rejected",
  approved: "approved",
  upcoming: "Pending Issuance",
  upcomingTab: "upcoming",
  past: "past",
  issued: "Issued",
  onHold: "onHold",
  OnHold: "OnHold",
  other: "other",
  cancelled: "cancelled",
  pending: "pending-approval",
  hold: "on-hold",
  book: "book-now",
} as const;

export type BookingStatus = typeof bookingStatus[keyof typeof bookingStatus];

export type ConfirmBookingParams = {
  status: BookingStatus;
};

export type ConfirmBookingResponse = {
  result?: {
    orderId: string;
    message: string;
    warnings: string[];
    errors: [
      {
        code: string;
        message: string;
      }
    ];
  };
  success: boolean;
  message?: string;
};
