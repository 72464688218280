import React, { ChangeEvent, useState } from "react";
import { FormGroup, Popup, SelectInput } from "src/components";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "src/configs";
import { useAppContext, useLoadingContext, useLoginContext } from "src/context";
import { TravelPolicyService } from "src/services";
import { UnassignTravelPolicyParams } from "src/types/travelPolicy";

type Props = {
  onHide: () => void;
};

const UnmemoUnassignPolicy: React.FC<Props> = ({ onHide }) => {
  const { loading } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();
  const { user } = useLoginContext();

  const [assignFormData, setAssignFormData] =
    useState<UnassignTravelPolicyParams>({
      userIds: user.travelers[0].id,
    });

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setAssignFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const unassignPolicyToUser = async () => {
    loading.start();
    if (assignFormData.userIds) {
      const response = await TravelPolicyService.unassign({
        ...assignFormData,
      });
      if (response.success)
        showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
      else showError(response.message ? response.message : ERROR_MESSAGE);
    } else {
      showError("Please fill all fields!");
    }
    loading.stop();
  };
  return (
    <Popup
      title="Unassign Policy"
      onClose={{ ctaAction: onHide }}
      cta={{ ctaName: "Unassign", ctaAction: unassignPolicyToUser }}
    >
      <FormGroup>
        <SelectInput
          name="userIds"
          required
          defaultValue={assignFormData.userIds}
          placeholder="Enter User Name/Email"
          onChange={onSelectChange}
          options={
            user && user.travelers
              ? user.travelers.map((traveler) => ({
                  name: `${traveler.firstName} ${traveler.lastName}`,
                  props: { value: traveler.id },
                }))
              : []
          }
        />
      </FormGroup>
    </Popup>
  );
};

export const UnassignPolicy = React.memo(UnmemoUnassignPolicy);
