import React, { useState } from "react";
import { Accordion, Button, Card, Link, Text, Image } from "src/components";
import {
  BookingStatus,
  OrderTotal as OrderSummaryType,
  bookingStatus,
} from "src/types/checkout";
import { assets } from "src/configs";

import { Form } from "react-bootstrap";
import { componentSizes, componentVariants } from "src/types";
import { TripStatus, tripStatus } from "src/types/traveler";
import { formatPrice } from "src/utils";
import { RejectionPopup } from "../RejectionPopup";
import classes from "./OrderSummary.module.scss";

type Props = OrderSummaryType & {
  onConfirm: (status: BookingStatus) => void;
  onApprove: (status: TripStatus, reason?: string) => void;
  isApproved: boolean;
  canBook?: boolean;
  canDraft?: boolean;
  canApprove?: boolean;
  canReject?: boolean;
};

const UnmemoOrderSummary: React.FC<Props> = ({
  fareAmount,
  orderTotal,
  numberOfTravelers,
  totalTax,
  currency,
  onConfirm,
  onApprove,
  isApproved,
  canBook,
  canDraft,
  canApprove,
  canReject,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedHold, setIsCheckedHold] = useState(false);
  const [isFetching, setIsFetching] = useState<string | null>(null);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  
  const handleCheckboxChangeHold = () => {
    setIsCheckedHold(!isCheckedHold);
  };

  const handleAction = async (action: () => void, actionType: string) => {
    setIsFetching(actionType);
    try {
      await action();
    } finally {
      setIsFetching(null);
    }
  };

  return (
    <>
      <Card>
        <Text tag="h4" className={classes.title}>
          Order Summary
        </Text>
        <div className={classes.table}>
          <div className={classes.row}>
            <Text tag="h6">Flight Fare</Text>
            <Text tag="h4">{formatPrice(currency, fareAmount)}</Text>
          </div>
          <div className={classes.row}>
            <Text tag="h6">Total Tax</Text>
            <Text tag="h4">{formatPrice(currency, totalTax)}</Text>
          </div>
          <div className={classes.row}>
            <Text tag="h6">No. of Passengers</Text>
            <Text tag="h4">{numberOfTravelers}</Text>
          </div>
          <div className={`${classes.row} ${classes.total}`}>
            <Text tag="h4">Total price of trip</Text>
            <Text tag="h2">{formatPrice(currency, orderTotal)}</Text>
          </div>

          <Accordion
            head={
              <Link to="" size={componentSizes.small}>
                Price Breakdown
              </Link>
            }
            headDirection="right"
            variant={componentVariants.primary}
          >
            <div className={classes.row}>
              <Text tag="h6">{numberOfTravelers} x Passenger</Text>
              <div className={classes.value}>
                <Text tag="h4">{formatPrice(currency, fareAmount)}</Text>
                <Text size={componentSizes.small}>
                  ({numberOfTravelers} x {formatPrice(currency, fareAmount / numberOfTravelers)}
                  )
                </Text>
              </div>
            </div>
            <div className={classes.row}>
              <Text tag="h6">{numberOfTravelers} x Tax Applied</Text>
              <div className={classes.value}>
                <Text tag="h4">{formatPrice(currency, totalTax)}</Text>
                <Text size={componentSizes.small}>
                  ({numberOfTravelers} x {formatPrice(currency, totalTax / numberOfTravelers)})
                </Text>
              </div>
            </div>
          </Accordion>
        </div>

        <div className="row mt-2">
          {isApproved && canBook && (
            <div className="col-lg-6">
              <Button
                variant={componentVariants.primary}
                isFullWidth
                className={classes.btn}
                onClick={() => handleAction(() => onConfirm(bookingStatus.book), "book")}
              >
                {isFetching === "book" ? (
                  <Image src={assets.spinnerWhite} width={20} height={20} alt="Loading..." />
                ) : (
                  "Book Now"
                )}
              </Button>
            </div>
          )}

          {!isApproved && canBook && (
            <>
              <Button
                variant={componentVariants.primary}
                isFullWidth
                className={`${classes.btn} ${!isChecked ? "disabled-btn" : ""}`}
                onClick={() => handleAction(() => onConfirm(bookingStatus.pending), "sendForApproval")}
                disabled={!isChecked}
              >
                {isFetching === "sendForApproval" ? (
                  <Image src={assets.spinnerWhite} width={20} height={20} alt="Loading..." />
                ) : (
                  "Send for Approval"
                )}
              </Button>
              <Form.Group className="mt-3 checkbox-label">
                <Form.Check
                  type="checkbox"
                  id="checkbox-enable"
                  label="Booking will be held for 2 hours awaiting approval. Please ensure your approver provides a prompt response to secure the current fare, airlines may change the price at any time."
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
            </>
          )}

          {isApproved && canDraft && (
            <>
              <div className="col-lg-6">
                <Button
                  variant={componentVariants.secondary}
                  isFullWidth
                  className={`${classes.btn} ${
                    !isCheckedHold ? "disabled-btn" : ""
                  }`}
                  onClick={() => handleAction(() => onConfirm(bookingStatus.hold), "hold")}
                  disabled={!isCheckedHold}
                >
                  {isFetching === "hold" ? (
                    <Image src={assets.spinner} width={20} height={20} alt="Loading..." />
                  ) : (
                    "Hold for 2 hours"
                  )}
                </Button>
              </div>
              <Form.Group className="mt-3 checkbox-labelHold">
                <Form.Check
                  type="checkbox"
                  id="checkbox-enableHold"
                  label="Flight holds do not always guarantee the same pricing, airlines may change the price at any time."
                  checked={isCheckedHold}
                  onChange={handleCheckboxChangeHold}
                />
              </Form.Group>
            </>
          )}

          {canApprove && (
            <div className="col-lg-6">
              <Button
                isFullWidth
                className={classes.btn}
                onClick={() => handleAction(() => onApprove(tripStatus.approve), "approve")}
              >
                {isFetching === "approve" ? (
                  <Image src={assets.spinner} width={20} height={20} alt="Loading..." />
                ) : (
                  "Approve"
                )}
              </Button>
            </div>
          )}

          {canReject && (
            <div className="col-lg-6">
              <Button
                variant={componentVariants.secondary}
                isFullWidth
                className={classes.btn}
                onClick={() => setIsActive(true)}
              >
                Reject
              </Button>
            </div>
          )}
        </div>
      </Card>

      {isActive && (
        <RejectionPopup
          onHide={() => {
            setIsActive(false);
          }}
          onReject={(reason: string) => handleAction(() => onApprove(tripStatus.reject, reason), "reject")}
        />
      )}
    </>
  );
};

export const OrderSummary = React.memo(UnmemoOrderSummary);
