import React, { useContext, useEffect, useState } from "react";
import {
  AuthService,
  TravelPolicyService,
  TravelerService,
  UserService,
} from "src/services";
import { TravelPolicy } from "src/types/travelPolicy";
import { Traveler, roles, status } from "src/types/traveler";
import { storage } from "src/utils";
import { useLoadingContext } from "../Loading";
import { ActionType, useSearchFlightContext } from "../SearchFlight";
import { Login1stForm } from "./components";

type LoginUser = Traveler & {
  travelPolicies: TravelPolicy[];
  travelers: Traveler[];
};

type LoginContextType = {
  user: LoginUser;
  isLogin: boolean;
  saveUser: () => void;
  token: string | undefined;
  login: (token: string) => void;
  logout: () => void;
  updatePolicies: () => void;
  updateUsers: () => void;
  isLoading: boolean;
};

const defaultValues: LoginContextType = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
    roleName: roles.traveler,
    profilePictureUrl: "",
    status: status.inActive,
    policyId: 0,
    id: 0,
    travelPolicies: [],
    travelers: [],
    phoneNumber: "",
    title: "",
    birthDate: "",
  },
  isLogin: false,
  saveUser: () => {},
  token: undefined,
  login: (token: string) => {},
  logout: () => {},
  updatePolicies: () => {},
  updateUsers: () => {},
  isLoading: true,
};

const LoginContext = React.createContext<LoginContextType>(defaultValues);

type Props = {
  children: React.ReactNode;
};

export const LoginProvider: React.FC<Props> = ({ children }) => {
  const { dispatch } = useSearchFlightContext();
  const [travelPolicies, setTravelPolicies] = useState<TravelPolicy[]>([]);
  const [travelers, setTravelers] = useState<Traveler[]>([]);
  const [userDetail, setUserDetail] = useState<Traveler>(defaultValues.user);
  const [user, setUser] = useState<LoginUser>(defaultValues.user);
  const [token, setTokenState] = useState<string | undefined>(undefined);
  const [isLogin, setIsLogin] = useState<boolean>(!!token);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const { loading } = useLoadingContext();

  const login = (token: string) => {
    storage.set("user_token", token);
    setTokenState(token);
    saveUser();
  };

  const logout = async () => {
    loading.start();
    await AuthService.logout();
    storage.remove("user_token");
    setTokenState(undefined);
    setUser(defaultValues.user);
    setIsLogin(false);
    loading.stop();
  };

  const getPolicies = async () => {
    const response = await TravelPolicyService.get();

    if (response.success && response.travelPolicies) {
      setTravelPolicies(response.travelPolicies);
    }
  };

  const getUserInfo = async () => {
    const response = await UserService.get();

    if (response.success && response.userInfo) {
      setUserDetail(response.userInfo);
      setIsAdmin(
        response.userInfo.roleName !== roles.guest &&
          response.userInfo.roleName !== roles.traveler
      );
    } else {
      logout();
    }
  };

  const getUsers = async () => {
    const response = await TravelerService.search({ pageSize: 100 });

    if (response.success && response.results) {
      setTravelers(response.results.travelers);
    }
  };

  useEffect(() => {
    if (isLogin) {
      if (isAdmin) {
        getPolicies();
        getUsers();
      }
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  const updatePolicies = () => {
    getPolicies();
  };

  const updateUsers = () => {
    getUsers();
  };

  const saveUser = async () => {
    loading.start();
    await getUserInfo();

    if (isAdmin) {
      await getPolicies();
      await getUsers();
    }
    setIsLogin(true);
    loading.stop();
    setIsLoading(false);
  };

  useEffect(() => {
    const storedToken = storage.get("user_token");
    if (storedToken) {
      setTokenState(storedToken);
      saveUser();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUser({ ...userDetail, travelers, travelPolicies });
  }, [travelers, travelPolicies, userDetail]);

  useEffect(() => {
    dispatch({
      type: ActionType.updateLoginUser,
      payload: userDetail.roleName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail]);

  return (
    <LoginContext.Provider
      value={{
        user,
        saveUser,
        isLogin,
        token,
        login,
        logout,
        updatePolicies,
        updateUsers,
        isLoading,
      }}
    >
      {isLogin && user.id && (!user.firstName || !user.lastName) && (
        <Login1stForm id={user.id} onUpdate={saveUser} />
      )}
      {/* {isLogin && user.id && <Login1stForm id={user.id} onUpdate={saveUser} />} */}
      {children}
    </LoginContext.Provider>
  );
};

const useLoginContext = (): LoginContextType => useContext(LoginContext);

export { useLoginContext };
