import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { FlightByIDParams, FlightByIDResponse } from "src/types/flight";
import { token } from "src/utils";

type GetFlightByID = (data: FlightByIDParams) => Promise<FlightByIDResponse>;

const INVALID_RESPONSE: FlightByIDResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const getFlightByID: GetFlightByID = async (data) => {
  try {
    const res = await axios.get<FlightByIDResponse>(
      `${API_BASE_URL}${endpoints.flight.getByID}?${
        data.transactionId ? `transactionId=${data.transactionId}&` : ""
      }selectedFlightId=${data.id}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) return { ...res.data };

    console.error(`GET FLIGHT BY ID | ${SERVICE_FAILED_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`GET FLIGHT BY ID | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
