import React from "react";

import { componentSizes } from "src/types";

import { Text } from "src/components";
import {
  hasMixedCase,
  hasNumber,
  hasSpecialCharacter,
  hasValidLength,
} from "../utils";
import classes from "./PasswordTerms.module.scss";

type Props = {
  password: string;
};

const UnmemoPasswordTerms: React.FC<Props> = ({ password }) => {
  return (
    <div className={classes.passwordTerms}>
      <span
        className={`${classes.list} ${
          hasValidLength(password) ? classes.checked : ""
        }`}
      >
        <Text size={componentSizes.small}>
          Must contain at least 8 characters
        </Text>
      </span>
      <span
        className={`${classes.list} ${
          hasNumber(password) ? classes.checked : ""
        }`}
      >
        <Text size={componentSizes.small}>
          Must contain at least one number
        </Text>
      </span>
      <span
        className={`${classes.list} ${
          hasSpecialCharacter(password) ? classes.checked : ""
        }`}
      >
        <Text size={componentSizes.small}>
          Must contain at least a special character
        </Text>
      </span>
      <span
        className={`${classes.list} ${
          hasMixedCase(password) ? classes.checked : ""
        }`}
      >
        <Text size={componentSizes.small}>
          Mixed upper and lower-case characters
        </Text>
      </span>
    </div>
  );
};

export const PasswordTerms = React.memo(UnmemoPasswordTerms);
