import React from "react";

import { Header } from "src/components";
import classes from "./Main.module.scss";

type Props = {
  children: React.ReactNode;
  isHome?: boolean;
};

const UnmemoMain: React.FC<Props> = ({ children, isHome = false }) => {
  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <Header isBGWhite={isHome} />
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export const Main = React.memo(UnmemoMain);
