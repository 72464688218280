import React, { useEffect, useRef, useState } from "react";

import { Button } from "src/components";
import { componentVariants } from "src/types";
import { MenuOpen } from "../MenuOpen";
import classes from "./ActionBulk.module.scss";

type Props = {
  onDeleteBulk?: () => void;
  onDeActiveBulk?: () => void;
  onActivateBulk?: () => void;
};

const UnmemoActionBulk: React.FC<Props> = ({ ...rest }) => {
  const [isActive, setActive] = useState(false);
  const actionsRef = useRef<HTMLDivElement | null>(null);

  const onClick = () => setActive(!isActive);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        actionsRef.current &&
        !actionsRef.current.contains(event.target as Node)
      ) {
        setActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={classes.actions} ref={actionsRef}>
      <Button
        variant={componentVariants.secondary}
        icon="dropdown"
        onClick={onClick}
      >
        Actions
      </Button>

      {isActive && (
        <div className={classes.dropdown}>
          <MenuOpen {...rest} isActive={isActive} onHide={onClick} />
        </div>
      )}
    </div>
  );
};

export const ActionBulk = React.memo(UnmemoActionBulk);
