import React from "react";

import { ComponentSize } from "src/types";
import classes from "./FormGroup.module.scss";

type Props = {
  children: React.ReactNode;
  isFlex?:
    | boolean
    | {
        direction?: "row" | "column";
        justify?: "center" | "space-between";
      };
  marginBottom?: ComponentSize;
  hasMargin?: boolean;
};

const UnmemoFormGroup: React.FC<Props> = ({
  children,
  isFlex = false,
  marginBottom = "medium",
  hasMargin = true,
}) => {
  let flexStyles = {};

  if (isFlex) {
    flexStyles = { display: "flex" };

    if (typeof isFlex === "object") {
      const { direction, justify } = isFlex;

      if (direction) {
        flexStyles = { ...flexStyles, flexDirection: direction };
      }

      if (justify) {
        flexStyles = { ...flexStyles, justifyContent: justify };
      }
    }
  }

  return (
    <div
      className={`${classes.formGroup} ${classes[marginBottom]} ${
        !hasMargin ? classes.noMargin : ""
      }`}
      style={flexStyles}
    >
      {children}
    </div>
  );
};

export const FormGroup = React.memo(UnmemoFormGroup);
