import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { TripsParams, TripsResponse } from "src/types/traveler";
import { token } from "src/utils";

type GetTrips = (params: TripsParams) => Promise<TripsResponse>;

const INVALID_RESPONSE: TripsResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const getTrips: GetTrips = async ({ status, pageIndex }) => {
  try {
    const res = await axios.get<TripsResponse>(
      `${API_BASE_URL}${endpoints.trips.get}?status=${status}&pageIndex=${pageIndex}&pageSize=10`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`GET TRIPS | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`GET TRIPS | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
