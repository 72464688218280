import React from "react";
import { Text } from "src/components";
import { componentSizes } from "src/types";
import classes from "./Tabs.module.scss";

type Props = {
  tabList: {
    onClick: () => void;
    isActive: boolean;
    title: string;
    description?: string;
  }[];
  hasFullWidth?: boolean;
  onTabClick?: (index: number) => void;
};

const UnmemoTabs: React.FC<Props> = ({ tabList, hasFullWidth = true, onTabClick }) => {
  const width = hasFullWidth ? `${(100 / tabList.length) * 100}%` : "unset";

  return (
    <div className={classes.tabs}>
      {tabList.map(({ title, description, onClick, isActive }, i) => (
        <div
          className={`${classes.tab} ${isActive ? classes.active : ""}`}
          key={i}
          style={{ width: `${width}` }}
        >
          <div
            onClick={() => {
              onTabClick && onTabClick(i);
              onClick();
            }}
            className={classes.click}
          >
            <Text tag="h4" className={classes.title}>
              {title}
            </Text>
            <Text
              tag="h6"
              size={componentSizes.small}
              className={classes.description}
            >
              {description}
            </Text>
          </div>
        </div>
      ))}
    </div>
  );
};

export const Tabs = React.memo(UnmemoTabs);
