import React, { useState } from "react";
import {
  DateInput,
  FormGroup,
  Input,
  Label,
  Popup,
  SelectInput,
} from "src/components";
import { ERROR_MESSAGE, PERSON_TITLE, SUCCESS_MESSAGE } from "src/configs";
import { useAppContext, useLoadingContext, useLoginContext } from "src/context";
import { TravelerService } from "src/services";
import { AddTravelerParams, roles } from "src/types/traveler";
import { onInputChange } from "src/utils";

type Props = {
  isActive: boolean;
  onClose: () => void;
};

const UnmemoAddTraveler: React.FC<Props> = ({ isActive, onClose }) => {
  const { loading } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();
  const { saveUser } = useLoginContext();

  const [formData, setFormData] = useState<AddTravelerParams>({
    title: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    email: "",
    roleName: roles.guest,
  });

  const onDateChange = (date: Date | null) => {
    setFormData((prevData) => ({
      ...prevData,
      birthDate: `${date}`,
    }));
  };

  const onSubmit = async () => {
    loading.start();

    const response = await TravelerService.add(formData);
    if (response.success) {
      saveUser();
      showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
      onClose();
    } else {
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  if (!isActive) return <></>;

  return (
    <Popup
      title="Please complete new traveler information"
      cta={{ ctaName: "Add Traveler", ctaAction: onSubmit }}
      onClose={{ ctaName: "Close", ctaAction: onClose }}
    >
      <div className="row">
        <div className="col-lg-12">
          <FormGroup>
            <Label>Title</Label>
            <SelectInput
              name="title"
              value={formData.title}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              options={PERSON_TITLE}
            />
          </FormGroup>
        </div>
        <div className="col-lg-6">
          <FormGroup>
            <Label>First Name</Label>
            <Input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              placeholder="First Name"
              required
            />
          </FormGroup>
        </div>
        <div className="col-lg-6">
          <FormGroup>
            <Label>Last Name</Label>
            <Input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              placeholder="Last Name"
              required
            />
          </FormGroup>
        </div>
        <div className="col-lg-12">
          <FormGroup>
            <Label>Email</Label>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              placeholder="Enter Email"
              required
            />
          </FormGroup>
        </div>
        <div className="col-lg-12">
          <FormGroup>
            <SelectInput
              name="roleName"
              required
              defaultValue={formData.roleName}
              placeholder="Role to Assign"
              onChange={(e) => onInputChange(e, formData, setFormData)}
              options={[
                {
                  name: roles.corporateAdmin,
                  props: { value: roles.corporateAdmin },
                },
                {
                  name: roles.travelerManager,
                  props: { value: roles.travelerManager },
                },
                { name: roles.traveler, props: { value: roles.traveler } },
                { name: roles.guest, props: { value: roles.guest } },
              ]}
            />
          </FormGroup>
        </div>

        <div className="col-lg-12">
          <FormGroup>
            <Label>Date of Birth</Label>
            <DateInput
              name="birthDate"
              selected={
                formData.birthDate ? new Date(formData.birthDate) : null
              }
              onChange={(value) => onDateChange(value)}
              placeholderText="Enter Date"
              required
              limitStartFromToday={false}
            />
          </FormGroup>
        </div>
      </div>
    </Popup>
  );
};

export const AddTraveler = React.memo(UnmemoAddTraveler);
