import { approvalAction, approvalCondition } from "src/types/approval";
import { cabin } from "src/types/travelPolicy";
import { changeRequestType } from "src/types/traveler";

export const CABINS = [
  //   {
  //     name: "Select",
  //     props: { value: "", disabled: true },
  //   },
  {
    name: "Economy",
    props: { value: cabin.economy },
  },
  {
    name: "Business",
    props: { value: cabin.business },
  },
];

export const PERSON_TITLE = [
  //   {
  //     name: "Select",
  //     props: { value: "", disabled: true },
  //   },
  {
    name: "Mr.",
    props: { value: "Mr." },
  },
  {
    name: "Mrs.",
    props: { value: "Mrs." },
  },
  {
    name: "Miss.",
    props: { value: "Miss." },
  },
];

export const APPROVAL_ACTIONS = [
  {
    name: "Request Approval",
    props: { value: approvalAction.requestApproval },
  },
  {
    name: "Send Notification",
    props: { value: approvalAction.notify },
  },
];

export const APPROVAL_ACTIONS_1 = [
  {
    name: "Send Notification",
    props: { value: approvalAction.notify, selected: true },
  },
];

export const APPROVAL_CONDITIONS = [
  {
    name: "All Trips",
    props: { value: approvalCondition.allTrips },
  },
  {
    name: "In-Policy Trips",
    props: { value: approvalCondition.inPolicy },
  },
  {
    name: "Out-of-Policy Trips",
    props: { value: approvalCondition.outOfPolicy },
  },
];

export const CHANGE_REQUEST_OPTIONS = [
  {
    name: "Amendment",
    props: { value: changeRequestType.amend },
  },
  {
    name: "Cancelation",
    props: { value: changeRequestType.cancel },
  },
];
