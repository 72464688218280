import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout } from "src/Layout";
import { app, pageLinks } from "src/configs";
import { useLoadingContext, useLoginContext } from "src/context";
import { AuthService } from "src/services";
import { SSOLoginParams } from "src/types/login";

const UnmemoSSOLogin: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { loading } = useLoadingContext();
  const navigate = useNavigate();
  const { login } = useLoginContext();

  const [formData, setFormData] = useState<SSOLoginParams>({
    userId: searchParams.get("uid") || "",
    tripId: searchParams.get("id") || "",
    token: searchParams.get("token") || "",
    tid: searchParams.get("tid") || "",
  });

  const onSubmit = async () => {
    loading.start();
  
    const response = await AuthService.autoLogin(formData);
    loading.stop();
      
    if (response.success && response.token) {
      login(response.token);  
      window.location.href = `${pageLinks.tripSummary.link}${formData.tripId}`;
    } else {
      console.log("Login failed");
    }};

  useEffect(() => {
    onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title={`${pageLinks.sso.name} - ${app.name}`}>Redirecting...</Layout>
  );
};

export const SSOLogin = React.memo(UnmemoSSOLogin);
