import React from "react";

import { Icon, Text } from "src/components";
import { icons } from "src/configs";
import { componentSizes } from "src/types";
import classes from "./ToastMessage.module.scss";

type Props = {
  children: React.ReactNode;
  type: "success" | "error";
  onClose?: () => void;
  isActive?: boolean;
};

const UnmemoToastMessage: React.FC<Props> = ({
  children,
  type,
  onClose,
  isActive = false,
}) => {
  return (
    <div
      className={`${classes.message} ${classes[type]}  ${
        isActive ? classes.active : ""
      }`}
    >
      <div className={classes.content}>
        <Text tag="h5">{type === "success" ? "Success!" : "Error!"}</Text>
        <Text size={componentSizes.small}>{children}</Text>
      </div>
      {onClose && (
        <div className={classes.close} onClick={onClose}>
          <Icon src={icons.cross.color} />
        </div>
      )}
    </div>
  );
};

export const ToastMessage = React.memo(UnmemoToastMessage);
