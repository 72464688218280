import React from "react";

import classes from "./Input.module.scss";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type Props = InputProps & {
  icon?: "search" | "dropdown" | "flightFrom" | "flightTo" | "date" | "userAdd";
};

const UnmemoInput: React.FC<Props> = ({
  type,
  icon = undefined,
  value,
  ...rest
}) => {
  return (
    <input
      className={`${classes.input} ${
        icon ? `${classes.hasIcon} ${classes[icon]}` : ""
      }`}
      value={value || ""}
      {...rest}
      autoComplete="off"
    />
  );
};

export const Input = React.memo(UnmemoInput);
