import React from "react";

import classes from "./PrimaryLink.module.scss";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type Props = ButtonProps;

const UnmemoPrimaryLink: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <button className={`${classes.link}`} {...rest}>
      {children}
    </button>
  );
};

export const PrimaryLink = React.memo(UnmemoPrimaryLink);
