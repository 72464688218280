import { ProfileImage, Tag, Text } from "src/components";
import { componentSizes } from "src/types";
import { Traveler, status } from "src/types/traveler";

import React from "react";
import classes from "./UserManagement.module.scss";

export type TravelerTable = {
  key: Traveler;
  id: Traveler["id"];
  name: React.ReactNode;
  email: Traveler["email"];
  role: Traveler["roleName"];
  status: React.ReactNode;
};

const createNameNode = (traveler: Traveler): React.ReactNode => {
  const { profilePictureUrl, firstName, lastName } = traveler;

  const name = `${firstName} ${lastName ? lastName : ""}`;
  return (
    <div className={classes.name}>
      <ProfileImage profilePictureUrl={profilePictureUrl} name={name} />
      <Text size={componentSizes.regular}>{name} </Text>
    </div>
  );
};

export const transformTravelerList = (list: Traveler[]) => {
  const transformedList: TravelerTable[] = list.map((traveler) => ({
    key: traveler,
    id: traveler.id,
    name: createNameNode(traveler),
    email: traveler.email,
    role: traveler.roleName,
    status:
      traveler.status === status.active ? (
        <Tag variant="green">Active</Tag>
      ) : (
        <Tag variant="grey">Inactive</Tag>
      ),
  }));

  return transformedList;
};
