import React from "react";
import { Icon, Tag, Text } from "src/components";
import { icons } from "src/configs";
import { componentSizes } from "src/types";
import { BaggageInfo, PenaltyInfo } from "src/types/flight";
import { formatPrice } from "src/utils";
import classes from "./FareCondition.module.scss";

type Props = PenaltyInfo & { bags?: BaggageInfo[] };

const UnmemoFareCondition: React.FC<Props> = ({
  refundInfo,
  exchangeInfo,
  bags,
}) => {
  const isRefundable: boolean =
    refundInfo.amount > 0 ? true : refundInfo.refundable ? true : false;

  const isChangeable: boolean =
    exchangeInfo.amount > 0 ? true : exchangeInfo.changeable ? true : false;
  return (
    <div className={classes.wrapper}>
      <Text tag="h3" className={classes.title}>
        Fare conditions
      </Text>

      {/* <div className={classes.header}>
        <Image src={`${flightImagesURL}MS.jpg`} />
        <div className={classes.content}>
          <div className={classes.row}>
            <Text tag="h4">King Abdulaziz Intl - Jinnah Int</Text>
            <Tag size={componentSizes.small} variant="blue">
              F638
            </Tag>
          </div>
          <div className={classes.row}>
            <Text tag="h6">Class Here</Text>
          </div>
        </div>
      </div> */}

      <div className={classes.conditions}>
        <div className={classes.row}>
          <Icon src={icons.refundable.color} />
          <Icon src={isRefundable ? icons.tick.green : icons.cross.red} />

          <Text tag="h5">
            Cancellations:{" "}
            {isRefundable ? "Refundable for a fee" : "Non-Refundable"}
            {refundInfo.amount > 0 && isRefundable ? (
              <>
                <Tag variant="red" size={componentSizes.small}>
                  {formatPrice(refundInfo.currency, refundInfo.amount)}
                </Tag>
              </>
            ) : (
              ""
            )}
          </Text>
        </div>

        {bags &&
          bags.map(
            (
              {
                provisionType,
                pieceCount,
                included,
                weight,
                unit,
                description1,
              },
              i
            ) => (
              <div className={classes.row} key={i}>
                <Icon
                  src={
                    (provisionType === "A" ? icons.baggage : icons.handCarry)
                      .color
                  }
                />
                <Icon src={included ? icons.tick.green : icons.cross.red} />
                <Text tag="h5">
                  {`${pieceCount && pieceCount > 0 ? `${pieceCount} ` : ""}${
                    provisionType
                      ? provisionType === "A"
                        ? `Checked ${
                            pieceCount && pieceCount > 1 ? "bags" : "bag"
                          }`
                        : "Carry-On"
                      : ""
                  } ${included ? "included" : "not included"} ${
                    weight && unit
                      ? `/ ${weight || ""}${unit || ""}`
                      : description1
                      ? `| ${description1} ${
                          pieceCount && pieceCount > 1 ? "each" : ""
                        }`
                      : ""
                  } `}
                </Text>
              </div>
            )
          )}

        <div className={classes.row}>
          <Icon src={icons.swap.color} />
          <Icon src={isChangeable ? icons.tick.green : icons.cross.red} />
          <Text tag="h5">
            {isChangeable ? "Changeable" : "Changeable for a fee"}{" "}
            {exchangeInfo.amount > 0 && isChangeable && (
              <Tag variant="red" size={componentSizes.small}>
                {formatPrice(exchangeInfo?.currency, exchangeInfo?.amount)}
              </Tag>
            )}
          </Text>
        </div>
      </div>
    </div>
  );
};

export const FareCondition = React.memo(UnmemoFareCondition);
