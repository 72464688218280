import React from "react";

import { Icon, Link, Text } from "src/components";
import { icons, pageLinks } from "src/configs";
import classes from "./PageHeader.module.scss";

type Props = {
  title?: string;
};

const UnmemoPageHeader: React.FC<Props> = ({ title }) => {
  return (
    <div className={classes.header}>
      <Text tag="h1">{title || "Create new Approval Process"}</Text>
      <Link to={pageLinks.approvalProcess.link}>
        <Icon src={icons.cross.color} />
      </Link>
    </div>
  );
};

export const PageHeader = React.memo(UnmemoPageHeader);
