import React, { ChangeEvent, useState } from "react";
import {
  DateInput,
  FormGroup,
  Input,
  Label,
  PhoneInput,
  Popup,
  SelectInput,
} from "src/components";
import { ERROR_MESSAGE, PERSON_TITLE, SUCCESS_MESSAGE } from "src/configs";
import { useAppContext, useLoadingContext } from "src/context";
import { UserService } from "src/services";
import { UserEditParams } from "src/types/login";

type Props = { id: UserEditParams["id"]; onUpdate: () => void };

const UnmemoLogin1stForm: React.FC<Props> = ({ id, onUpdate }) => {
  const { loading } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();

  const [formData, setFormData] = useState<UserEditParams>({
    id,
    title: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    birthDate: "",
  });

  const onInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onDateChange = (date: Date | null) => {
    setFormData((prevData) => ({
      ...prevData,
      birthDate: `${date}`,
    }));
  };

  const onSubmit = async () => {
    loading.start();

    const response = await UserService.edit(formData);
    if (response.success) {
      onUpdate();
      showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
    } else {
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  return (
    <Popup
      title="Please complete your information"
      cta={{ ctaName: "Update", ctaAction: onSubmit }}
    >
      <div className="row">
        <div className="col-lg-12">
          <FormGroup>
            <Label>Title</Label>
            <SelectInput
              name="title"
              value={formData.title}
              onChange={onInputChange}
              options={PERSON_TITLE}
            />
          </FormGroup>
        </div>
        <div className="col-lg-6">
          <FormGroup>
            <Label>First Name</Label>
            <Input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={onInputChange}
              placeholder="First Name"
              required
            />
          </FormGroup>
        </div>
        <div className="col-lg-6">
          <FormGroup>
            <Label>Last Name</Label>
            <Input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={onInputChange}
              placeholder="Last Name"
              required
            />
          </FormGroup>
        </div>
        <div className="col-lg-12">
          <FormGroup>
            <Label>Phone Number</Label>
            <PhoneInput
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={onInputChange}
              placeholder="Enter Phone"
              required
            />
          </FormGroup>
        </div>
        <div className="col-lg-12">
          <FormGroup>
            <Label>Date of Birth</Label>
            <DateInput
              name="birthDate"
              selected={
                formData.birthDate ? new Date(formData.birthDate) : null
              }
              onChange={(value) => onDateChange(value)}
              placeholderText="Enter Date"
              required
              limitStartFromToday={false}
            />
          </FormGroup>
        </div>
      </div>
    </Popup>
  );
};

export const Login1stForm = React.memo(UnmemoLogin1stForm);
