import React from "react";

import classes from "./Toggle.module.scss";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type Props = InputProps;

const UnmemoToggle: React.FC<Props> = ({ ...rest }) => {
  return (
    <div className={classes.inputBox}>
      <input {...rest} type="checkbox" autoComplete="off" />
      <span className={classes.toggle}>
        <span className={classes.circle}></span>
      </span>
    </div>
  );
};

export const Toggle = React.memo(UnmemoToggle);
