import React from "react";

import { LinkProps, Link as LinkRouter } from "react-router-dom";
import {
  ComponentSize,
  ComponentVariant,
  componentSizes,
  componentVariants,
} from "src/types";
import classes from "./Link.module.scss";

type Props = LinkProps & {
  variant?: ComponentVariant;
  size?: ComponentSize;
  className?: string;
};

const UnmemoLink: React.FC<Props> = ({
  children,
  variant = componentVariants.primary,
  size = componentSizes.regular,
  className,
  ...rest
}) => {
  return (
    <LinkRouter
      className={`${classes.link} ${classes[variant]} ${classes[size]} ${className}`}
      {...rest}
    >
      {children}
    </LinkRouter>
  );
};

export const Link = React.memo(UnmemoLink);
