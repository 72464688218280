import React, { useEffect, useState } from "react";
import { Layout } from "src/Layout";
import {
  CenteredGrid,
  DataTable,
  DataTableColumn,
  SectionTitle,
} from "src/components";
import { ERROR_MESSAGE, app, icons, pageLinks } from "src/configs";
import { useNavigate } from "react-router-dom";
import { useAppContext, useLoadingContext, useLoginContext } from "src/context";
import { TravelPolicyService } from "src/services";
import { TableConfigs } from "src/types";
import {
  TravelPolicyDetails,
  TravelPolicyFull,
  TravelPolicyListResponse,
  TravelPolicySearchParams,
} from "src/types/travelPolicy";
import { AssignPolicy, PolicyDetails, UnassignPolicy } from "./components";
import { DEFAULT_TABLE_CONFIGS } from "./configs";
import { TravelPolicyTable, transformTravelPolicyList } from "./utils";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const columns: DataTableColumn<TravelPolicyTable>[] = [
  { dataKey: "id", header: "ID" },
  { dataKey: "name", header: "Name" },
  { dataKey: "createdAt", header: "Created At" },
  { dataKey: "travelers", header: "People Assigned" },
];

const UnmemoTravelPolicy: React.FC = () => {
  const { user } = useLoginContext();
  const { loading, unlockScreen } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();
  const navigate = useNavigate();

  const [openSidebar, setOpenSidebar] = useState(false); // For sidebar
  const [openPopupAssign, setOpenPopupAssign] = useState(false); // For popup
  const [openPopupUnassign, setOpenPopupUnassign] = useState(false); // For popup
  const [assignPolicyID, setAssignPolicyID] = useState<number | undefined>(
    undefined
  ); // For Assign
  const [list, setList] = useState<TravelPolicyTable[] | null>(null); // For table list
  const [selectedRows, setSelectedRows] = useState<TravelPolicyTable[]>([]); // For selected rows
  const [search, setSearch] = useState(false); // To search
  const [searchParams, setSearchParams] = useState<TravelPolicySearchParams>({
    pageIndex: 0,
  }); // Search payload
  const [detailedPolicyInfo, setDetailedPolicyInfo] =
    useState<TravelPolicyDetails["travelPolicy"]>(undefined); // Save information of one user
  const [tableConfigs, setTableConfigs] = useState<TableConfigs>(
    DEFAULT_TABLE_CONFIGS
  ); // Table configs
  const [isLoading, setIsLoading] = useState(true); // Manage loading state
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Manage error message

  // -------- Table configurations start --------
  const updateTable = (response: TravelPolicyListResponse) => {
    if (response.success && response.results) {
      setList(transformTravelPolicyList(response.results.travelPolicies));

      setTableConfigs({
        pageNumber: response.results.pageNumber,
        pageSize: response.results.pageSize,
        totalItems: response.results.totalItems,
        totalPages: response.results.totalPages,
        firstItem: response.results.firstItem,
        lastItem: response.results.lastItem,
      });
      setErrorMessage(null); // Clear any previous error messages
    } else {
      setList([]);
      setErrorMessage(response.message || ERROR_MESSAGE); // Set error message
      setTableConfigs(DEFAULT_TABLE_CONFIGS);
    }
    setIsLoading(false); // Stop loading when the response is received
  };
  // -------- Table configurations end --------
  // -------- Table sorting start --------
  const onSort = (
    sortBy: TravelPolicySearchParams["sortBy"],
    orderBy: TravelPolicySearchParams["orderBy"]
  ) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      sortBy,
      orderBy,
    }));

    setSearch(true);
   };

  // -------- Table Listing start --------
  const getList = async () => {
    setIsLoading(true);  // Start loading
    setErrorMessage(null);  // Clear previous errors

    const response = await TravelPolicyService.search(searchParams);

    if (response.success && response.results) {
      setList(transformTravelPolicyList(response.results.travelPolicies)); // Set the list of travel policies
    } else {
      setErrorMessage(response.message || ERROR_MESSAGE);
      setList([]); // Empty the list on error
    }
    setIsLoading(false);  // Stop loading
  };

  useEffect(() => {
    getList();
  }, []);

  const onSearch = async () => {
    getList();
    setSearch(false);
  };

  useEffect(() => {
    if (search) {
      onSearch();
    }
  }, [search]);

  const onChangePagination = (number: number) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      pageIndex: number - 1,
    }));
    setSearch(true);
  };

  const onSearchChange = (value: string) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      name: value,
    }));
  };

  const onSearchPolicies = () => {
    setSearch(true);
  };
  // -------- Table Listing ends --------

  if (!user) return <></>;
  const { travelPolicies } = user;

  // -------- Create Policy Handling start --------
  const showCreatePolicy = () => navigate(pageLinks.createTravelPolicy.link);
  // -------- Create Policy Handling ends --------
  

  // -------- Popup Handling start --------
  const showAssignPolicy = () => setOpenPopupAssign(true);
  const hideAssignPolicy = () => {
    setOpenPopupAssign(false);
    setAssignPolicyID(undefined);
  };

  const handleAssignPolicy = (policy: TravelPolicyFull) => {
    setAssignPolicyID(policy.id);
    showAssignPolicy();
  };

  const showUnassignPolicy = () => setOpenPopupUnassign(true);
  const hideUnassignPolicy = () => {
    setOpenPopupUnassign(false);
  };
  // -------- Popup Handling ends --------

  // -------- Delete Handling starts --------
  const handleDelete = async (ids: TravelPolicyFull["id"][]) => {
    loading.start();

    const response = await TravelPolicyService.delete({ ids });

    if (response.success) showSuccess(response.message);
    else showError(response.message);

    getList();
    loading.stop();
  };

  const onDelete = async (policy: TravelPolicyFull) => {
    handleDelete([policy.id]);
  };

  const onDeleteBulk = async () => {
    const ids: number[] = selectedRows.map((list) => list.key.id);
    handleDelete(ids);
  };
  // -------- Delete Handling ends --------

  // -------- Update Handling starts --------
  const onUpdate = (policy: TravelPolicyFull) => {
    navigate(`${pageLinks.editTravelPolicy.link}?id=${policy.id}`);
  };
  // -------- Update Handling end --------

  // -------- Status Handling starts --------
  // const handleStatusChange = async (ids: Traveler["id"][], status: Status) => {
  //   loading.start();

  //   const response =
  //     status === statusValues.active
  //       ? await TravelerService.deactivate({ ids })
  //       : await TravelerService.activate({ ids });
  //   if (response.success) showSuccess(response.message);
  //   else showError(response.message);

  //   getList();
  //   loading.stop();
  // };

  // const onStatusChange = async (policy: TravelPolicyFull) => {
  //   handleStatusChange([user.id], user.status);
  // };

  // const onDeactivateBulk = async () => {
  //   const ids: number[] = selectedRows.map((list) => list.key.id);
  //   handleStatusChange(ids, statusValues.active);
  // };

  // const onActivateBulk = async () => {
  //   const ids: number[] = selectedRows.map((list) => list.key.id);
  //   handleStatusChange(ids, statusValues.inActive);
  // };
  // -------- Status Handling ends --------

  // -------- Sidebar Handling starts --------
  const onCloseSideBar = () => {
    unlockScreen();
    setOpenSidebar(false);
    setDetailedPolicyInfo(undefined);
  };
  // -------- Sidebar Handling ends --------

  // -------- Row click action starts --------
  const onClickRow = async (policy: TravelPolicyFull) => {
    loading.start();

    const response = await TravelPolicyService.getById(policy.id);
    if (response.success && response.travelPolicy) {
      setDetailedPolicyInfo(response.travelPolicy);
      setOpenSidebar(true);
    } else {
      setDetailedPolicyInfo(undefined);
      setOpenSidebar(false);
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };
  // -------- Row click action ends --------
  return (
    <Layout title={`${pageLinks.travelPolicy.name} - ${app.name}`}>
      <CenteredGrid>
        <SectionTitle
          title={pageLinks.travelPolicy.name}
          tableData={{
            first: tableConfigs.firstItem,
            last: tableConfigs.lastItem,
            total: tableConfigs.totalItems,
          }}
        />
  
 
  {list === null ? (
          <Skeleton height={40} count={10} />
        ) : (
          // Show the table when data is successfully loaded
          <DataTable<TravelPolicyTable>
            data={list}
            columns={columns}
            onSelectedRowsChange={setSelectedRows}
            onDelete={onDelete}
            onDeleteBulk={onDeleteBulk}
            onUpdate={onUpdate}
            onClick={onClickRow}
            mainCTA={{
              name: "Create New Travel Policy",
              action: showCreatePolicy,
            }}
            otherCTA={
              travelPolicies.length > 0
                ? [
                    { name: "Assign Policy", action: showAssignPolicy },
                    { name: "Unassign Policy", action: showUnassignPolicy },
                  ]
                : []
            }
            rowActions={[
              {
                name: "Assign Policy",
                action: handleAssignPolicy,
                icon: icons.activate.color,
              },
            ]}
            totalPages={tableConfigs.totalPages}
            pageNumber={tableConfigs.pageNumber}
            onChangePagination={onChangePagination}
            onSearchChange={onSearchChange}
            onSearch={onSearchPolicies}
            hasSort={{
              onSort,
              sortBy: searchParams.sortBy,
              orderBy: searchParams.orderBy,
            }}
          />
        )}
  
        {/* Popup starts */}
        {openPopupAssign && (
          <AssignPolicy onHide={hideAssignPolicy} policyId={assignPolicyID} />
        )}
  
        {openPopupUnassign && <UnassignPolicy onHide={hideUnassignPolicy} />}
        {/* Popup ends */}
  
        {/* Side drawer starts */}
        <PolicyDetails
          isActive={openSidebar}
          onClose={onCloseSideBar}
          policyDetails={detailedPolicyInfo}
        />
        {/* Side drawer ends */}
      </CenteredGrid>
    </Layout>
  );
  
};

export const TravelPolicy = React.memo(UnmemoTravelPolicy);
