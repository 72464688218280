import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import {
  ApprovalProcessList,
  ApprovalProcessSearchParams,
} from "src/types/approval";
import { token } from "src/utils";

type SearchApprovalProcess = (
  params: ApprovalProcessSearchParams
) => Promise<ApprovalProcessList>;

const INVALID_RESPONSE: ApprovalProcessList = {
  success: false,
  message: ERROR_MESSAGE,
};

export const searchApprovalProcess: SearchApprovalProcess = async (params) => {
  try {
    const res = await axios.get<ApprovalProcessList>(
      `${API_BASE_URL}${endpoints.corporate.approvalProcess.get}?${
        params.pageIndex ? `pageIndex=${params.pageIndex}` : ""
      }${params.name ? `&name=${params.name}` : ""}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(
      `GET APPROVAL PROCESS SEARCH LIST | ${SERVICE_ERROR_MESSAGE}`
    );

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(
      `GET APPROVAL PROCESS SEARCH LIST | ${SERVICE_FAILED_MESSAGE} ${error}`
    );

    return INVALID_RESPONSE;
  }
};
