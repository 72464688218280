import { Pagination } from ".";
import { Traveler } from "./traveler";

export const approvalAction = {
  requestApproval: "REQUEST_APPROVAL",
  notify: "NOTIFY",
} as const;

export type ApprovalAction =
  (typeof approvalAction)[keyof typeof approvalAction];

export const approvalCondition = {
  inPolicy: "IN_POLICY",
  outOfPolicy: "OUT_OF_POLICY",
  allTrips: "ALWAYS",
} as const;

export type ApprovalCondition =
  (typeof approvalCondition)[keyof typeof approvalCondition];

export type ApprovalProcessRules = {
  action: ApprovalAction;
  condition: ApprovalCondition;
  reviewerId: number;
  reviewerName: string;
  id: number;
  profilePictureUrl?: string;
};

export type ApprovalProcess = {
  name: string;
  isDefault?: boolean;
  approvalNeeded: boolean;
  approvalProcessRules: ApprovalProcessRules[];
};

export type ApprovalProcessDetailed = ApprovalProcess & {
  createdAt?: string;
  travelerDetails: {
    email: string;
    id: Traveler["id"];
    name: string;
    profilePictureUrl?: Traveler["profilePictureUrl"];
  }[];
  id: number;
};

export type CreateApprovalProcessParams = ApprovalProcess & {
  travelerDetails?: {
    email: string;
    id: Traveler["id"];
    name: string;
    profilePictureUrl?: Traveler["profilePictureUrl"];
  }[];
};

export type EditApprovalProcessParams = CreateApprovalProcessParams & {
  id: number;
};

export type ApprovalProcessList = {
  results?: Pagination & { approvalProcesses: ApprovalProcessDetailed[] };
  success: boolean;
  message?: string;
};

export type ApprovalProcessSearchParams = {
  pageIndex?: number;
  name?: string;
};
