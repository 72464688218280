import React from "react";
import {
  LabelValue,
  ProfileImage as ProfileImageComponent,
} from "src/components";
import { componentSizes } from "src/types";
import classes from "./BasicInfo.module.scss";

type Props = {
  profileImage?: string;
  name?: string;
  info: {
    label: string;
    value?: string;
    node?: React.ReactNode;
  }[];
};

const UnmemoBasicInfo: React.FC<Props> = ({ profileImage, info, name }) => {
  return (
    <div className={classes.basicInfo}>
      {(profileImage || name) && (
        <div className={classes.image}>
          <ProfileImageComponent
            profilePictureUrl={profileImage}
            name={name || ""}
            size={componentSizes.large}
          />
        </div>
      )}
      <div className={classes.data}>
        {info.map(
          ({ label, value, node }, i) =>
            (value || node) && (
              <div className={classes.list} key={i}>
                <LabelValue label={label} value={value} node={node} />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export const BasicInfo = React.memo(UnmemoBasicInfo);
