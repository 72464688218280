import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout } from "src/Layout";
import { CenteredGrid, Line, ProfileImage, Text } from "src/components";
import { ERROR_MESSAGE, SUCCESS_MESSAGE, app, pageLinks } from "src/configs";
import { useAppContext, useLoadingContext, useLoginContext } from "src/context";
import { ChangePasswordForm } from "src/sections";
import { TravelerService, UserService } from "src/services";
import { componentSizes } from "src/types";
import { Traveler } from "src/types/traveler";
import classes from "./Settings.module.scss";
import { EditDetails } from "./components";

const UnmemoSettings: React.FC = () => {
  const { loading } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();
  const { saveUser } = useLoginContext();

  const [user, setUser] = useState<Traveler | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true); // Loading state for skeleton

  const getDetails = async () => {
    loading.start();
    setIsLoading(true);

    const response = await UserService.get();
    if (response.success && response.userInfo) {
      setUser(response.userInfo);
    } else {
      setUser(undefined);
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
    setIsLoading(false);
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateProfileImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];

      loading.start();

      const response = await TravelerService.updateImage(user?.id || 0, file);
      if (response.success) {
        showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
        getDetails();
        saveUser();
      } else {
        showError(response.message ? response.message : ERROR_MESSAGE);
      }

      loading.stop();
    }
  };

  if (isLoading) {
    return (
      <Layout title={`${pageLinks.setting.name} - ${app.name}`}>
        <CenteredGrid>
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className={classes.group}>
                <div className={classes.title}>
                  <Skeleton height={40} width={200} />
                </div>
                <div className={classes.content}>
                  <Skeleton height={100} width={100} circle />
                  <Skeleton height={30} width={`60%`} />
                  <Skeleton height={20} width={`80%`} />
                  <Skeleton height={20} width={`40%`} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                </div>
              </div>
              <br />
              <Line />
              <br />
              <div className={classes.group}>
                <div className={classes.title}>
                  <Skeleton height={40} width={200} />
                </div>
                <div className={classes.content}>
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </CenteredGrid>
      </Layout>
    );
  }

  if (!user) return <></>;

  const {
    profilePictureUrl,
    firstName,
    lastName,
    email,
    id,
    phoneNumber,
    birthDate,
    title,
  } = user;

  const name = `${firstName} ${lastName}`;

  return (
    <Layout title={`${pageLinks.setting.name} - ${app.name}`}>
      <CenteredGrid>
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <div className={classes.group}>
              <div className={classes.title}>
                <Text tag="h1">Edit Profile</Text>
              </div>
              <div className={classes.content}>
                <div className={classes.profile}>
                  <div className={classes.image}>
                    <ProfileImage
                      profilePictureUrl={profilePictureUrl}
                      name={name}
                      size={componentSizes.large}
                    />
                    <div className={classes.btn}>
                      <input
                        type="file"
                        onChange={updateProfileImage}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className={classes.detail}>
                    <Text tag="h3">{name}</Text>
                    <Text>{email}</Text>
                  </div>
                </div>
                <EditDetails
                  id={id}
                  title={title}
                  firstName={firstName}
                  lastName={lastName}
                  phoneNumber={phoneNumber}
                  birthDate={birthDate}
                  onUpdate={getDetails}
                />
              </div>
            </div>
            <br />
            <Line />
            <br />
            <div className={classes.group}>
              <div className={classes.title}>
                <Text tag="h1">Change Password</Text>
              </div>
              <div className={classes.content}>
                <ChangePasswordForm
                  ctaText="Update Password"
                  isCTAFullWidth={false}
                  isInApp={true}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </CenteredGrid>
    </Layout>
  );
};

export const Settings = React.memo(UnmemoSettings);
