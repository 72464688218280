import React from "react";

import { Icon, Text } from "src/components";
import { icons } from "src/configs";
import { componentSizes } from "src/types";
import classes from "./Alert.module.scss";

type Props = {
  children: React.ReactNode;
  type: "success" | "error";
  isFloatTop?: boolean;
  onClose?: () => void;
  isActive?: boolean;
};

const UnmemoAlert: React.FC<Props> = ({
  children,
  type,
  isFloatTop = true,
  onClose,
  isActive = false,
}) => {
  return (
    <div
      className={`${classes.alert} ${classes[type]} ${
        isFloatTop ? classes.fixed : ""
      } ${isActive ? classes.active : ""}`}
    >
      <Text size={componentSizes.regular}>{children}</Text>
      {onClose && (
        <div className={classes.close} onClick={onClose}>
          <Icon src={icons.cross.color} />
        </div>
      )}
    </div>
  );
};

export const Alert = React.memo(UnmemoAlert);
