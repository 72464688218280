const set = (name: string, value: any) => localStorage.setItem(name, value);

const get = (name: string) => {
  const storedItem = localStorage.getItem(name);

  if (!storedItem) return null;

  if (typeof storedItem === "object") return JSON.parse(storedItem);

  return storedItem;
};

const remove = (name: string) => localStorage.removeItem(name);

export const storage = {
  set,
  get,
  remove,
};
