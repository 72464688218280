import React from "react";
import { Layout } from "src/Layout";
import { CenteredWrapper } from "src/components";
import { app, pageLinks } from "src/configs";
import { PageHeader, PolicyForm } from "./components";

const UnmemoEditTravelPolicy: React.FC = () => {
  return (
    <Layout title={`${pageLinks.editTravelPolicy.name} - ${app.name}`}>
      <CenteredWrapper>
        <PageHeader title="Edit Policy" />
        <PolicyForm />
      </CenteredWrapper>
    </Layout>
  );
};

export const EditTravelPolicy = React.memo(UnmemoEditTravelPolicy);
