import { TableConfigs } from "src/types";

export const DEFAULT_TABLE_CONFIGS: TableConfigs = {
  pageNumber: 1,
  pageSize: 0,
  totalItems: 0,
  totalPages: 1,
  firstItem: 1,
  lastItem: 1,
};
