import React from "react";

import { ComponentSize, componentSizes } from "src/types";
import classes from "./Text.module.scss";

type Props = {
  children: React.ReactNode;
  tag?: "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div";
  size?: ComponentSize;
  weight?: "light" | "regular" | "medium" | "semibold" | "bold";
  isWhite?: boolean;
  className?: string;
  isError?: boolean;
};

const UnmemoText: React.FC<Props> = ({
  isWhite = false,
  children,
  tag = "p",
  size = componentSizes.regular,
  weight = "regular",
  className = "",
  isError = false,
}) => {
  const allProps = {
    className: `
        ${classes.text} ${isWhite ? classes.isWhite : ""} ${
      classes[`${size}Size`]
    } ${classes[`${weight}Weight`]} ${className} ${
      isError ? classes.errorMessage : ""
    }`,
  };

  if (tag === "p") return <p {...allProps}>{children}</p>;

  if (tag === "h1") return <h1 {...allProps}>{children}</h1>;

  if (tag === "h2") return <h2 {...allProps}>{children}</h2>;

  if (tag === "h3") return <h3 {...allProps}>{children}</h3>;

  if (tag === "h4") return <h4 {...allProps}>{children}</h4>;

  if (tag === "h5") return <h5 {...allProps}>{children}</h5>;

  if (tag === "h6") return <h6 {...allProps}>{children}</h6>;

  if (tag === "div") return <div {...allProps}>{children}</div>;

  return <p {...allProps}>{children}</p>;
};

export const Text = React.memo(UnmemoText);
