import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { BasicResponse } from "src/types";
import { ApprovalProcessDetailed } from "src/types/approval";
import { token } from "src/utils";

type Response = BasicResponse & {
  results?: ApprovalProcessDetailed;
};

type GetApprovalProcessByID = (
  id: ApprovalProcessDetailed["id"]
) => Promise<Response>;

const INVALID_RESPONSE: Response = {
  success: false,
  message: ERROR_MESSAGE,
};

export const getApprovalProcessByID: GetApprovalProcessByID = async (id) => {
  try {
    const res = await axios.get<Response>(
      `${API_BASE_URL}${endpoints.corporate.approvalProcess.getByID}/${id}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) return res.data;

    console.error(`GET APPROVAL PROCESSES BY ID | ${SERVICE_FAILED_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(
      `GET APPROVAL PROCESSES BY ID | ${SERVICE_FAILED_MESSAGE} ${error}`
    );

    return INVALID_RESPONSE;
  }
};
