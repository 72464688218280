import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import {
  ChangePassword as ChangePasswordData,
  ChangePasswordResponse,
} from "src/types/password";
import { token } from "src/utils";

type ChangePasswordInApp = (
  data: ChangePasswordData
) => Promise<ChangePasswordResponse>;

const INVALID_RESPONSE: ChangePasswordResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const changePasswordInApp: ChangePasswordInApp = async ({
  id,
  newPassword,
  oldPassword,
}) => {
  try {
    const res = await axios.post<ChangePasswordResponse>(
      `${API_BASE_URL}traveler/${id}/${endpoints.password.change}`,
      { newPassword, oldPassword },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`CHANGE PASSWORD | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`CHANGE PASSWORD | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
