import React, { useEffect } from "react";
import { Layout } from "src/Layout";
import { app } from "src/configs";
import { ActionType, useSearchFlightContext } from "src/context";
import { FlightSearch } from "src/sections";
import { cabin } from "src/types/travelPolicy";

const UnmemoHome: React.FC = () => {
  const { dispatch } = useSearchFlightContext();

  useEffect(() => {
    dispatch({
      type: ActionType.updateExpirationTime,
      payload: new Date(new Date().getTime() + 30 * 60 * 1000),
    });

    dispatch({
      type: ActionType.updateFlightFrom,
      payload: {
        code: "",
        cityName: "",
      },
    });

    dispatch({
      type: ActionType.updateFlightTo,
      payload: {
        code: "",
        cityName: "",
      },
    });

    dispatch({
      type: ActionType.updateCabin,
      payload: cabin.economy,
    });
  }, []);

  return (
    <>
      <Layout title={`${app.name} - ${app.tagline}`} isHome={true}>
        <FlightSearch />
      </Layout>
    </>
  );
};

export const Home = React.memo(UnmemoHome);
