import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import {
  TravelersDeactivateParams,
  TravelersDeactivateResponse,
} from "src/types/traveler";
import { token } from "src/utils";

type DeactivateTraveler = (
  ids: TravelersDeactivateParams
) => Promise<TravelersDeactivateResponse>;

const INVALID_RESPONSE: TravelersDeactivateResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const deactivateTraveler: DeactivateTraveler = async ({ ids }) => {
  try {
    const res = await axios.patch<TravelersDeactivateResponse>(
      `${API_BASE_URL}${endpoints.traveler.deactivate}`,
      ids,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`DEACTIVATE TRAVEL | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`DEACTIVATE TRAVEL | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
