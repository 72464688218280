import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { ApprovalProcessList } from "src/types/approval";
import { token } from "src/utils";

type GetApprovalProcesses = () => Promise<ApprovalProcessList>;

const INVALID_RESPONSE: ApprovalProcessList = {
  success: false,
  message: ERROR_MESSAGE,
};

export const getApprovalProcesses: GetApprovalProcesses = async () => {
  try {
    const res = await axios.get<ApprovalProcessList>(
      `${API_BASE_URL}${endpoints.corporate.approvalProcess.get}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) return res.data;

    console.error(`GET APPROVAL PROCESSES | ${SERVICE_FAILED_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(
      `GET APPROVAL PROCESSES | ${SERVICE_FAILED_MESSAGE} ${error}`
    );

    return INVALID_RESPONSE;
  }
};
