import React from "react";

import classes from "./Input.module.scss";

type InputProps = React.InputHTMLAttributes<HTMLTextAreaElement>;

type Props = InputProps;

const UnmemoTextArea: React.FC<Props> = ({ type, value, ...rest }) => {
  return (
    <textarea className={classes.input} value={value} {...rest}></textarea>
  );
};

export const TextArea = React.memo(UnmemoTextArea);
