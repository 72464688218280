import React from "react";

import { IconAndTitle } from "src/components";
import { assets } from "src/configs";

const UnmemoEmailSent: React.FC = () => {
  return (
    <IconAndTitle
      image={assets.success}
      title="Email Sent"
      description="We’ve sent an email to your email address. Follow the steps provided in the email to update your password or select Log In if you don’t want to change your password at this time."
    />
  );
};

export const EmailSent = React.memo(UnmemoEmailSent);
