import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import {
  TravelerSearchParams,
  TravelersListResponse,
} from "src/types/traveler";
import { token } from "src/utils";

type SearchTraveler = (
  params: TravelerSearchParams
) => Promise<TravelersListResponse>;

const INVALID_RESPONSE: TravelersListResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const searchTraveler: SearchTraveler = async (params) => {
  try {
    const res = await axios.post<TravelersListResponse>(
      `${API_BASE_URL}${endpoints.traveler.search}`,
      { ...params },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`GET TRAVEL SEARCH LIST | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(
      `GET TRAVEL SEARCH LIST | ${SERVICE_FAILED_MESSAGE} ${error}`
    );

    return INVALID_RESPONSE;
  }
};
