import { BasicLocation } from "src/types/location";
import { Cabin } from "src/types/travelPolicy";
import { Role, Traveler } from "src/types/traveler";

export type SearchFlightStateContextType = {
  flightFrom: BasicLocation | null;
  flightTo: BasicLocation | null;
  departureDate: Date;
  returnDate: Date | null;
  cabin: Cabin;
  users: Traveler[];
  policyId: number;
  directOnly: boolean;
  transactionId: string;
  isAdmin: boolean;
  expirationTime: Date | null | undefined;
};

export enum ActionType {
  updateFlightFrom = "updateFlightFrom",
  updateFlightTo = "updateFlightTo",
  updateDepartureDate = "updateDepartureDate",
  updateReturnDate = "updateReturnDate",
  updateCabin = "updateCabin",
  updateUsers = "updateUsers",
  updateDirectFlight = "updateDirectFlight",
  updateTransactionId = "updateTransactionId",
  updateTravelPolicy = "updateTravelPolicy",
  updateLoginUser = "updateLoginUser",
  updateExpirationTime = "updateExpirationTime",
}

export type ActionsType =
  | ActionType.updateFlightFrom
  | ActionType.updateFlightTo
  | ActionType.updateDepartureDate
  | ActionType.updateReturnDate
  | ActionType.updateCabin
  | ActionType.updateUsers
  | ActionType.updateDirectFlight
  | ActionType.updateTransactionId
  | ActionType.updateTravelPolicy
  | ActionType.updateLoginUser
  | ActionType.updateExpirationTime;

export interface updateFlightFrom {
  type: ActionType.updateFlightFrom;
  payload: BasicLocation | null;
}

export interface updateFlightTo {
  type: ActionType.updateFlightTo;
  payload: BasicLocation | null;
}

export interface updateDepartureDate {
  type: ActionType.updateDepartureDate;
  payload: Date;
}

export interface updateReturnDate {
  type: ActionType.updateReturnDate;
  payload: Date | null;
}

export interface updateCabin {
  type: ActionType.updateCabin;
  payload: Cabin;
}

export interface updateUsers {
  type: ActionType.updateUsers;
  payload: Traveler[];
}

export interface updateDirectFlight {
  type: ActionType.updateDirectFlight;
  payload: boolean;
}

export interface updateTransactionId {
  type: ActionType.updateTransactionId;
  payload: string;
}
export interface updateTravelPolicy {
  type: ActionType.updateTravelPolicy;
  payload: number;
}

export interface updateLoginUser {
  type: ActionType.updateLoginUser;
  payload: Role;
}

export interface updateExpirationTime {
  type: ActionType.updateExpirationTime;
  payload: Date;
}

export type Actions =
  | updateFlightFrom
  | updateFlightTo
  | updateDepartureDate
  | updateReturnDate
  | updateCabin
  | updateUsers
  | updateDirectFlight
  | updateTransactionId
  | updateTravelPolicy
  | updateLoginUser
  | updateExpirationTime;

export type Reducer = (
  state: SearchFlightStateContextType,
  action: Actions
) => SearchFlightStateContextType;
