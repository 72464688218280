import React from "react";
import { FormGroup, Text } from "src/components";
import classes from "./SideFilter.module.scss";

type Props = {
  totalRecords: number | undefined;
  options: {
    name?: string;
    data: React.ReactNode;
  }[];
};

const UnmemoSideFilter: React.FC<Props> = ({ options, totalRecords }) => {
  return (
    <div className={classes.sideFilter}>
      {totalRecords && <Text tag="h5">{totalRecords} flights found </Text>}
      {options.map(({ name, data }, i) => (
        <div className={classes.group} key={i}>
          {name && (
            <FormGroup>
              <Text tag="h4">{name}</Text>
            </FormGroup>
          )}
          {data}
        </div>
      ))}
    </div>
  );
};

export const SideFilter = React.memo(UnmemoSideFilter);
