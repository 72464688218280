import React from "react";

import { Icon } from "src/components";
import { icons } from "src/configs";
import { status } from "src/types/traveler";
import classes from "./MenuOpen.module.scss";

type Props = {
  isActive: boolean;
  item?: any;
  onDelete?: (data: any) => void;
  onStatusChange?: (data: any) => void;
  onUpdate?: (data: any) => void;
  onDeleteBulk?: () => void;
  onDeActiveBulk?: () => void;
  onActivateBulk?: () => void;
  onHide: () => void;
  moreActions?: {
    name: any;
    icon: string;
    action: any;
  }[];
};

const UnmemoMenuOpen: React.FC<Props> = ({
  onDelete,
  onStatusChange,
  onUpdate,
  item,
  isActive = false,
  onDeActiveBulk,
  onActivateBulk,
  onDeleteBulk,
  onHide,
  moreActions,
}) => {
  if (isActive) {
    return (
      <div className={classes.dropDown} onClick={onHide}>
        {moreActions &&
          moreActions.map(({ name, action, icon }, i) => (
            <button onClick={action} key={i}>
              <Icon src={icon} />
              {name}
            </button>
          ))}
        {onUpdate && (
          <button onClick={() => onUpdate(item)}>
            <Icon src={icons.edit.color} />
            Edit
          </button>
        )}
        {onStatusChange && (
          <button onClick={() => onStatusChange(item)}>
            <Icon src={icons.deactivate.color} />
            {item.key.status === status.active ? "Deactivate" : "Activate"}
          </button>
        )}
        {onDeActiveBulk && (
          <button onClick={() => onDeActiveBulk()}>
            <Icon src={icons.deactivate.color} />
            Deactivate
          </button>
        )}
        {onActivateBulk && (
          <button onClick={() => onActivateBulk()}>
            <Icon src={icons.activate.color} />
            Activate
          </button>
        )}
        {onDelete && (
          <button onClick={() => onDelete(item)} className={classes.delete}>
            <Icon src={icons.delete.color} />
            Delete
          </button>
        )}
        {onDeleteBulk && (
          <button onClick={() => onDeleteBulk()} className={classes.delete}>
            <Icon src={icons.delete.color} />
            Delete
          </button>
        )}
      </div>
    );
  }

  return <></>;
};

export const MenuOpen = React.memo(UnmemoMenuOpen);
