import React, { useEffect, useRef, useState } from "react";

import { ColorSquare, Input } from "src/components";
import { Colors, colors, colorsMapper } from "src/types/travelPolicy";
import classes from "./ColorInput.module.scss";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type Props = InputProps & {
  align?: "left" | "right";
  onColorChange: (color: Colors) => void;
};

const COLORS_LIST = [
  colors.green,
  colors.pink,
  colors.blackishBlue,
  colors.darkBlue,
  colors.secondaryGreen,
  colors.black,
  colors.darkPink,
  colors.blue,
  colors.orange,
  colors.yellow,
  colors.maroon,
  colors.red,
];

const UnmemoColorInput: React.FC<Props> = ({
  align = "left",
  onColorChange,
  value,
  ...rest
}) => {
  const [colorSelected, setColorSelected] = useState<Colors>(
    (value as Colors) || colors.green
  );
  const [isActive, setActive] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const onClick = () => setActive(!isActive);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const onSelectColor = (color: Colors) => {
    setColorSelected(color);
    onColorChange(color);
  };

  useEffect(() => {
    setColorSelected(value as Colors);
  }, [value]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputWrapper} onClick={onClick} ref={ref}>
        <Input
          {...rest}
          value={colorsMapper[colorSelected].name}
          readOnly
          icon="dropdown"
        />
        <ColorSquare
          className={classes.color}
          backgroundColor={colorsMapper[colorSelected].color}
          borderColor={colorsMapper[colorSelected].bg}
        />
      </div>
      {isActive && (
        <div className={classes.options}>
          {COLORS_LIST.map((color, i) => (
            <div
              className={classes.option}
              onClick={() => onSelectColor(colorsMapper[color].key)}
              key={i}
            >
              <ColorSquare
                backgroundColor={colorsMapper[color].color}
                borderColor={colorsMapper[color].bg}
                isSelected={colorSelected === color}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const ColorInput = React.memo(UnmemoColorInput);
