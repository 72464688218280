import { checkApproval } from "./checkApproval";
import { confirmBooking } from "./confirmBooking";
import { getSummary } from "./getSummary";
import { getTravelers } from "./getTravelers";
import { selectService } from "./selectService";
import { updateTravelers } from "./updateTravelers";

export const CheckoutService = {
  select: selectService,
  getTravelers,
  updateTravelers,
  getSummary,
  confirm: confirmBooking,
  verify: checkApproval,
};
