import { ChangeEvent } from "react";

export const onInputChange = (
  e:
    | ChangeEvent<HTMLInputElement>
    | ChangeEvent<HTMLSelectElement>
    | ChangeEvent<HTMLTextAreaElement>,
  prevData: any,
  setFormData: (value: React.SetStateAction<any>) => void
) => {
  const { name, value } = e.target;
  setFormData({ ...prevData, [name]: value });
};
