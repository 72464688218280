import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  ApprovalProcess,
  ChangePassword,
  CreateApprovalProcess,
  CreateTravelPolicy,
  EditApprovalProcess,
  EditTravelPolicy,
  ForgetPassword,
  Home,
  Login,
  Loyalty,
  Reporting,
  SSOLogin,
  SearchFlight,
  Settings,
  SummaryCheckout,
  SummaryCheckoutApproval,
  TravelPolicy,
  TravelerCheckout,
  Trips,
  UserManagement,
} from "src/pages";

import { pageLinks } from "src/configs";
import {
  AppProvider,
  LoadingProvider,
  LoginProvider,
  SearchFlightProvider,
  useLoginContext,
} from "src/context";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "src/sass/style.scss";

import reportWebVitals from "./reportWebVitals";

export default function Web() {
  const { isLogin, isLoading } = useLoginContext();

  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   setIsLoading(false);
  // }, []);

  if (isLoading) return <></>;

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={pageLinks.login.link}
          element={isLogin ? <Navigate to={pageLinks.home.link} /> : <Login />}
        />

        <Route path={pageLinks.sso.link} element={<SSOLogin />} />

        <Route
          path={pageLinks.forgetPassword.link}
          element={
            isLogin ? <Navigate to={pageLinks.home.link} /> : <ForgetPassword />
          }
        />

        <Route
          path={pageLinks.changePassword.link}
          element={
            isLogin ? <Navigate to={pageLinks.home.link} /> : <ChangePassword />
          }
        />

        <Route
          path={pageLinks.userManagement.link}
          element={
            isLogin ? (
              <UserManagement />
            ) : (
              <Navigate to={pageLinks.login.link} />
            )
          }
        />

        <Route
          path={pageLinks.approvalProcess.link}
          element={
            isLogin ? (
              <ApprovalProcess />
            ) : (
              <Navigate to={pageLinks.login.link} />
            )
          }
        />

        <Route
          path={pageLinks.checkout.confirmTravelers.link}
          element={
            isLogin ? (
              <TravelerCheckout />
            ) : (
              <Navigate to={pageLinks.login.link} />
            )
          }
        />

        <Route
          path={pageLinks.checkout.summary.link}
          element={
            isLogin ? (
              <SummaryCheckout />
            ) : (
              <Navigate to={pageLinks.login.link} />
            )
          }
        />

        <Route
          path={pageLinks.approval.checkout.link}
          element={
            isLogin ? (
              <SummaryCheckoutApproval />
            ) : (
              <Navigate to={pageLinks.login.link} />
            )
          }
        />

        <Route
          path={pageLinks.travelPolicy.link}
          element={
            isLogin ? <TravelPolicy /> : <Navigate to={pageLinks.login.link} />
          }
        />

        <Route
          path={pageLinks.createTravelPolicy.link}
          element={
            isLogin ? (
              <CreateTravelPolicy />
            ) : (
              <Navigate to={pageLinks.login.link} />
            )
          }
        />

        <Route
          path={pageLinks.reporting.link}
          element={
            isLogin ? <Reporting /> : <Navigate to={pageLinks.login.link} />
          }
        />

        <Route
          path={pageLinks.editTravelPolicy.link}
          element={
            isLogin ? (
              <EditTravelPolicy />
            ) : (
              <Navigate to={pageLinks.login.link} />
            )
          }
        />

        <Route
          path={pageLinks.createApprovalProcess.link}
          element={
            isLogin ? (
              <CreateApprovalProcess />
            ) : (
              <Navigate to={pageLinks.login.link} />
            )
          }
        />

        <Route
          path={pageLinks.editApprovalProcess.link}
          element={
            isLogin ? (
              <EditApprovalProcess />
            ) : (
              <Navigate to={pageLinks.login.link} />
            )
          }
        />

        <Route
          path={pageLinks.searchFlight.link}
          element={
            isLogin ? <SearchFlight /> : <Navigate to={pageLinks.login.link} />
          }
        />

        <Route
          path={pageLinks.trips.link}
          element={isLogin ? <Trips /> : <Navigate to={pageLinks.login.link} />}
        />

        <Route
          path={pageLinks.setting.link}
          element={
            isLogin ? <Settings /> : <Navigate to={pageLinks.login.link} />
          }
        />

<Route
          path={pageLinks.loyalty.link}
          element={
            isLogin ? <Loyalty /> : <Navigate to={pageLinks.login.link} />
          }
        />

        <Route
          path={pageLinks.home.link}
          element={isLogin ? <Home /> : <Navigate to={pageLinks.login.link} />}
        />

        <Route
          path="*"
          element={
            <Navigate
              to={isLogin ? pageLinks.home.link : pageLinks.login.link}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <SearchFlightProvider>
    <LoadingProvider>
      <LoginProvider>
        <AppProvider>
          <Web />
        </AppProvider>
      </LoginProvider>
    </LoadingProvider>
  </SearchFlightProvider>
);

reportWebVitals();
