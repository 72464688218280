import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  CountryDropDown,
  DateInput,
  FormGroup,
  Input,
  Label,
  Line,
  PhoneInput,
  Profile,
  SelectInput,
  Tag,
  Text,
} from "src/components";
import { PERSON_TITLE } from "src/configs";
import { LoyaltyProgramsService, TravelerService } from "src/services";
import { componentSizes } from "src/types";
import { TravelerCheckout } from "src/types/checkout";
import { formatDate, onInputChange } from "src/utils";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import classes from "./Form.module.scss";

type Props = {
  traveler: TravelerCheckout;
  onUpdateData: (travelerData: any) => void;
  refreshTravelersData: () => void;
};

const UnmemoForm: React.FC<Props> = ({
  traveler,
  onUpdateData,
  refreshTravelersData,
}) => {
  const {
    firstName,
    lastName,
    profilePictureUrl,
    hasMissingInfo,
    loyaltyProgram,
  } = traveler;

  const [travelerForm, setTravelerForm] = useState<TravelerCheckout>(
    traveler.passport?.length > 0
      ? {
          ...traveler,
          title: traveler.title ? traveler.title : PERSON_TITLE[0].props.value,
          passport: [
            {
              ...traveler.passport[0],
              issueCountry: traveler.passport[0].issueCountry
                ? traveler.passport[0].issueCountry
                : "JO",
            },
          ],
          loyaltyProgram: traveler.loyaltyProgram
            ? traveler.loyaltyProgram
            : { programId: 0, programName: "", programNumber: "" },
        }
      : {
          ...traveler,
          title: traveler.title ? traveler.title : PERSON_TITLE[0].props.value,
          passport: [{ id: 0, number: "", expiryDate: "", issueCountry: "JO" }],
          loyaltyProgram: { programId: 0, programName: "", programNumber: "" },
        }
  );

  const [loyaltyPrograms, setLoyaltyPrograms] = useState<
    { name: string; id: number }[]
  >([]);

  const [showLoyaltyForm, setShowLoyaltyForm] = useState(false);

  const options = loyaltyPrograms.map((program) => ({
    name: program.name,
    props: { value: program.id },
  }));

  const name = `${firstName} ${lastName}`;

  const onDateChange = (date: Date | null) => {
    setTravelerForm((prevData) => ({
      ...prevData,
      birthDate: `${date}`,
    }));
  };

  const onPassportInputChange = (
    e: ChangeEvent<HTMLInputElement> | { target: { name: string; value: any } }
  ) => {
    const { value, name } = e.target;

    if (name === "id")
      setTravelerForm((prevData) => ({
        ...prevData,
        passport: [{ ...prevData.passport[0], [name]: Number(value) }],
      }));
    else
      setTravelerForm((prevData) => ({
        ...prevData,
        passport: [
          {
            ...prevData.passport[0],
            [name]: typeof value === "string" ? value : `${formatDate(value)}`,
          },
        ],
      }));
  };

  const onPassportDateChange = (date: Date | null) => {
    setTravelerForm((prevData) => ({
      ...prevData,
      passport: [{ ...prevData.passport[0], expiryDate: `${date}` }],
    }));
  };

  const onPassportSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    setTravelerForm((prevData) => ({
      ...prevData,
      passport: [{ ...prevData.passport[0], issueCountry: value }],
    }));
  };

  const fetchLoyaltyPrograms = async () => {
    try {
      const response = await LoyaltyProgramsService.get();
      if (response.success) {
        setLoyaltyPrograms(response.programs);
      } else {
        console.error("Failed to fetch loyalty programs.");
      }
    } catch (error) {
      console.error("Failed to fetch loyalty programs.");
    }
  };

  const onLoyaltyProgramChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const selectedProgramId = Number(value);
    const selectedProgramName =
      loyaltyPrograms.find((program) => program.id === selectedProgramId)
        ?.name || "";

    setTravelerForm((prevData) => ({
      ...prevData,
      loyaltyProgram: {
        ...prevData.loyaltyProgram,
        programId: selectedProgramId,
        programName: selectedProgramName,
      },
    }));
  };

  const onLoyaltyProgramNumberChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;

    setTravelerForm((prevData) => ({
      ...prevData,
      loyaltyProgram: {
        ...prevData.loyaltyProgram,
        programNumber: value,
      },
    }));
  };

  useEffect(() => {
    fetchLoyaltyPrograms();
  }, []);

  useEffect(() => {
    onUpdateData(travelerForm);
  }, [travelerForm]);

  const { number, expiryDate, issueCountry } = travelerForm.passport[0];
  const { programId, programName, programNumber } = travelerForm.loyaltyProgram;

  return (
    <div className={classes.wrapper}>
      <Card>
        <Accordion
          head={
            <div className={classes.header}>
              <Profile name={name} profilePictureUrl={profilePictureUrl} />
              {hasMissingInfo && (
                <Tag variant="red" size={componentSizes.small}>
                  Information Missing
                </Tag>
              )}
            </div>
          }
          isDefaultOpen={true}
        >
          <div className={classes.body}>
            <Text tag="h3" className={classes.title}>
              Passenger details
            </Text>
            <div className="row">
              <div className="col-lg-3">
                <FormGroup>
                  <Label>Title</Label>
                  <SelectInput
                    name="title"
                    value={travelerForm.title}
                    onChange={(e) =>
                      onInputChange(e, travelerForm, setTravelerForm)
                    }
                    placeholder="Enter Title"
                    options={PERSON_TITLE}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    value={travelerForm.firstName}
                    onChange={(e) =>
                      onInputChange(e, travelerForm, setTravelerForm)
                    }
                    placeholder="Enter First Name"
                    required
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label>Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    value={travelerForm.lastName}
                    onChange={(e) =>
                      onInputChange(e, travelerForm, setTravelerForm)
                    }
                    placeholder="Enter Last Name"
                    required
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label>Date of Birth</Label>
                  <DateInput
                    name="birthDate"
                    selected={
                      travelerForm.birthDate
                        ? new Date(travelerForm.birthDate)
                        : null
                    }
                    onChange={(date) => onDateChange(date)}
                    required
                    limitStartFromToday={false}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label>Phone Number</Label>
                  <PhoneInput
                    type="text"
                    name="phoneNumber"
                    value={travelerForm.phoneNumber}
                    onChange={(e) =>
                      onInputChange(e, travelerForm, setTravelerForm)
                    }
                    required
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    name="email"
                    value={travelerForm.email}
                    onChange={(e) =>
                      onInputChange(e, travelerForm, setTravelerForm)
                    }
                    required
                    disabled
                  />
                </FormGroup>
              </div>
            </div>

            <Line />

            <Text tag="h3" className={classes.title}>
              Passport details
            </Text>

            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label>Passport Number</Label>
                  <Input
                    type="text"
                    name="number"
                    value={number}
                    onChange={(e) => onPassportInputChange(e)}
                    placeholder="Enter Passport Number"
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label>Expiry Date</Label>
                  <DateInput
                    name="expiryDate"
                    selected={expiryDate ? new Date(expiryDate) : null}
                    onChange={(date) => onPassportDateChange(date)}
                    required
                    limitStartFromToday={true}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label>Country of Issue</Label>
                  <CountryDropDown
                    name="issueCountry"
                    value={issueCountry}
                    onChange={(e) => onPassportSelectChange(e)}
                    required
                  />
                </FormGroup>
              </div>
            </div>

            <Line />

            <Text tag="h3" className={classes.title}>
              Loyalty Program
            </Text>

            {programId === 0 && !showLoyaltyForm ? (
              <Button
                onClick={() => setShowLoyaltyForm(true)}
                className={classes.addProgramButton}
              >
                Add Program
              </Button>
            ) : (
              <div className="row">
                <div className="col-lg-6">
                  <FormGroup>
                    <Label>Program</Label>
                    <SelectInput
                      name="program"
                      value={programId}
                      onChange={onLoyaltyProgramChange}
                      placeholder="Select Program"
                      options={options}
                      disabled={programNumber !== ""}
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Label>Program Number</Label>
                    <Input
                      type="number"
                      name="programNumber"
                      value={programNumber}
                      onChange={onLoyaltyProgramNumberChange}
                      placeholder="Enter Program Number"
                    />
                  </FormGroup>
                </div>
              </div>
            )}
          </div>
        </Accordion>
      </Card>
    </div>
  );
};

export const Form = React.memo(UnmemoForm);
