import axios from "axios";
import { API_BASE_URL, endpoints } from "src/configs";
import { token } from "src/utils";
import { LoyaltyProgramsResponse } from "src/types/loyalty";

export const getLoyaltyPrograms = async (): Promise<LoyaltyProgramsResponse> => {
  try {
    const res = await axios.get<LoyaltyProgramsResponse>(
      `${API_BASE_URL}${endpoints.loyaltyPrograms}`,
      {
        headers: {
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Failed to fetch loyalty programs:", error);
    throw new Error("Failed to fetch loyalty programs");
  }
};
