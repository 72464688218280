import axios from 'axios';
import { API_BASE_URL } from 'src/configs';
import { token } from 'src/utils';
import { TravelerLoyaltyPrograms } from 'src/types/loyalty';

export const addTravelerLoyaltyPrograms = async (programNumber: number, programId: number): Promise<TravelerLoyaltyPrograms> => {
  try {
    const response = await axios.post<TravelerLoyaltyPrograms>(
      `${API_BASE_URL}traveler/loyalty-program/new`,
      { programId, programNumber },
      {
        headers: {
          'accept': 'application/json, text/plain, */*',
          'authorization': `Bearer ${token.get()}`,
          'cache-control': 'no-cache',
          'pragma': 'no-cache',
        }
      }
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      programs: [],
    };
  }
};
