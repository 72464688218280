import React from "react";

import { Image, Text } from "src/components";
import { assets } from "src/configs";
import classes from "./Nodata.module.scss";

const UnmemoNodata: React.FC = () => {
  return (
    <div className={classes.nodata}>
      <Image src={assets.emptyTable} alt="" />
      <Text>No Record Found</Text>
    </div>
  );
};

export const Nodata = React.memo(UnmemoNodata);
