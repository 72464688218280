import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { AssignTravelPolicyParams } from "src/types/travelPolicy";
import { token } from "src/utils";

type Response = {
  success: boolean;
  message: string;
};

type AssignTravelPolicy = (
  params: AssignTravelPolicyParams
) => Promise<Response>;

const INVALID_RESPONSE: Response = {
  success: false,
  message: ERROR_MESSAGE,
};

export const assignTravelPolicy: AssignTravelPolicy = async ({
  userIds,
  ...rest
}) => {
  try {
    const res = await axios.put<Response>(
      `${API_BASE_URL}${endpoints.corporate.travelPolicies.assign}`,
      { ...rest, userIds: [userIds] },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) return res.data;

    console.error(`ASSIGN TRAVEL POLICIES | ${SERVICE_FAILED_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(
      `ASSIGN TRAVEL POLICIES | ${SERVICE_FAILED_MESSAGE} ${error}`
    );

    return INVALID_RESPONSE;
  }
};
