import { OrderBy, Pagination } from ".";
import { Traveler } from "./traveler";

export type TravelPolicy = {
  name: string;
  id: number;
  themeColor: string;
  createdAt: string;
};

export type TravelPoliciesResponse = {
  travelPolicies?: TravelPolicy[];
  success: boolean;
  message?: string;
};

export const cabin = {
  economy: "Y",
  business: "C",
} as const;

export type Cabin = (typeof cabin)[keyof typeof cabin];

export const budgetDirection = {
  moreThen: "above",
  lessThen: "below",
} as const;

export type BudgetDirection =
  (typeof budgetDirection)[keyof typeof budgetDirection];

export const budgetType = {
  median: "median",
  cheapest: "cheapest",
} as const;

export type BudgetType = (typeof budgetType)[keyof typeof budgetType];

export type CreateTravelPolicyParams = {
  name: string;
  themeColor: Colors;
  flightDynamicBudgetValue: string;
  flightDynamicBudgetDirection: BudgetDirection;
  flightDynamicBudgetType: BudgetType;
  flightMaxCabinClass: Cabin;
  flightLongerLegHours: number;
  flightMaxCabinClassLongerLeg: Cabin;
  flightIsRefundable: boolean;
  flightExtrasRules?: {
    preferredAirlines?: {
      code: string;
    }[];
    restrictedAirlines?: {
      code: string;
    }[];
  };
};

export type CreateTravelPolicyResponse = {
  success: boolean;
  message: string;
};

export const colors = {
  green: "green",
  pink: "pink",
  blackishBlue: "blackishBlue",
  darkBlue: "darkBlue",
  secondaryGreen: "secondaryGreen",
  black: "black",
  darkPink: "darkPink",
  blue: "blue",
  orange: "orange",
  yellow: "yellow",
  maroon: "maroon",
  red: "red",
} as const;

export type Colors = (typeof colors)[keyof typeof colors];

type ColorMapper = {
  name: string;
  key: Colors;
  color: string;
  bg: string;
};

export const colorsMapper: { [color: string]: ColorMapper } = {
  [colors.green]: {
    name: "Green",
    key: colors.green,
    color: "#058812",
    bg: "#DAEDDB",
  },
  [colors.pink]: {
    name: "Pink",
    key: colors.pink,
    color: "#FF5151",
    bg: "#FFE5E5",
  },
  [colors.blackishBlue]: {
    name: "Blue",
    key: colors.blackishBlue,
    color: "#1F2A44",
    bg: "#DDDFE3",
  },
  [colors.darkBlue]: {
    name: "Blue",
    key: colors.darkBlue,
    color: "#0E4E96",
    bg: "#DBE4EF",
  },
  [colors.secondaryGreen]: {
    name: "Green",
    key: colors.secondaryGreen,
    color: "#32BCAD",
    bg: "#E0F5F3",
  },
  [colors.black]: {
    name: "Black",
    key: colors.black,
    color: "#000",
    bg: "#D9D9D9",
  },
  [colors.darkPink]: {
    name: "Pink",
    key: colors.darkPink,
    color: "#E1058C",
    bg: "#FBDAEE",
  },
  [colors.blue]: {
    name: "Blue",
    key: colors.blue,
    color: "#0000FF",
    bg: "#D9D9FF",
  },
  [colors.orange]: {
    name: "Orange",
    key: colors.orange,
    color: "#FFA500",
    bg: "#FFF2D9",
  },
  [colors.yellow]: {
    name: "Yellow",
    key: colors.yellow,
    color: "#E8E82E",
    bg: "#FBFBE0",
  },
  [colors.maroon]: {
    name: "Maroon",
    key: colors.maroon,
    color: "#800080",
    bg: "#ECD9EC",
  },
  [colors.red]: {
    name: "Red",
    key: colors.red,
    color: "#FF0000",
    bg: "#FFD9D9",
  },
};

export type TravelPolicySearchParams = {
  name?: string;
  sortBy?: string;
  orderBy?: OrderBy;
  pageIndex?: number;
};

export type TravelPolicyFull = TravelPolicy & {
  travelers: {
    id: Traveler["id"];
    name: string;
    profilePictureUrl: Traveler["profilePictureUrl"];
  }[];
};

export type TravelPolicyListResponse = {
  results?: Pagination & {
    travelPolicies: TravelPolicyFull[];
  };
  success: boolean;
  message?: string;
};

export type TravelPolicyDeleteParams = {
  ids: number[];
};

export type TravelPolicyDeleteResponse = {
  success: boolean;
  message: string;
};

export type TravelPolicyDetails = {
  travelPolicy?: {
    id: number;
    name: string;
    isDefault: boolean;
    flightDynamicBudget: string;
    flightIsRefundable: boolean;
    flightMaxCabinClass: Cabin;
    themeColor: Colors;
    createdAt: string;
    flightMaxCabinClassLongerLeg: Cabin;
    flightExtrasRules: {
      preferredAirlines: {
        code: string;
        name: string;
      }[];
      restrictedAirlines: {
        code: string;
        name: string;
      }[];
    };
    travelers: {
      id: number;
      name: string;
      profilePictureUrl: string;
    }[];
  };
  success: boolean;
  message?: string;
};

export type AssignTravelPolicyParams = {
  userIds: number;
  policyId: number;
};

export type UnassignTravelPolicyParams = {
  userIds: number;
};
