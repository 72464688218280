import React, { useEffect } from "react";
import { Button, Icon, Line, Text } from "src/components";
import { icons } from "src/configs";
import { useLoadingContext } from "src/context";
import { componentVariants } from "src/types";
import classes from "./SideDrawer.module.scss";

type Props = {
  title: string;
  children: React.ReactNode;
  isActive: boolean;
  onClose: () => void;
  cta?: {
    action: any;
    name: string;
  };
  secondaryCta?: {
    action: any;
    name: string;
  };
};

const UnmemoSideDrawer: React.FC<Props> = ({
  title,
  children,
  isActive = false,
  onClose,
  cta,
  secondaryCta,
}) => {
  const { lockScreen, unlockScreen } = useLoadingContext();

  useEffect(() => {
    if (isActive) {
      lockScreen();
    } else {
      const timeoutId = setTimeout(() => {
        unlockScreen();
      }, 400);

      return () => clearTimeout(timeoutId);
    }
  }, [isActive, lockScreen, unlockScreen]);

  return (
    <div
      className={`${classes.sideDrawer} ${isActive ? classes.active : ""}`}
      style={{ right: isActive ? 0 : "-100%" }}
    >
      <div className={classes.header}>
        <Text tag="h4">{title}</Text>
        <div className={classes.closeIcon} onClick={onClose}>
          <Icon src={icons.cross.color} />
        </div>
      </div>

      {children}

      <Line />

      <div className={classes.footer}>
        <div className={classes.btnBlock}>
          {!secondaryCta && (
            <Button variant={componentVariants.secondary} onClick={onClose}>
              Close
            </Button>
          )}
          {secondaryCta && (
            <Button
              variant={componentVariants.secondary}
              onClick={secondaryCta.action}
            >
              {secondaryCta.name}
            </Button>
          )}
          {cta && <Button onClick={cta.action}>{cta.name}</Button>}
        </div>
      </div>
    </div>
  );
};

export const SideDrawer = React.memo(UnmemoSideDrawer);
