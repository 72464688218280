import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { BasicResponse } from "src/types";
import { AddTravelerParams } from "src/types/traveler";
import { formatDateForBE, token } from "src/utils";

type AddTraveler = (params: AddTravelerParams) => Promise<BasicResponse>;

const INVALID_RESPONSE: BasicResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const addTraveler: AddTraveler = async (data) => {
  try {
    const res = await axios.post<BasicResponse>(
      `${API_BASE_URL}${endpoints.traveler.new}`,
      {
        ...data,
        birthDate: data.birthDate
          ? formatDateForBE(new Date(data.birthDate))
          : "",
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }

    console.error(`ADD NEW TRAVEL | ${SERVICE_ERROR_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(`ADD NEW TRAVEL | ${SERVICE_FAILED_MESSAGE} ${error}`);

    return INVALID_RESPONSE;
  }
};
