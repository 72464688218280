import { icons } from "./icons";

export const app = {
  name: "TripCater",
  tagline: "Found all flights at single place",
};

export const pageLinks = {
  home: {
    name: "Dashboard",
    link: "/",
  },
  login: {
    name: "Login",
    link: "/login",
  },
  reporting: {
    name: "Reporting",
    link: "/reporting",
  },
  sso: {
    name: "Login",
    link: "/sso/login",
  },
  forgetPassword: {
    name: "Forget Password",
    link: "/forget-password",
  },
  changePassword: {
    name: "Change Password",
    link: "/change-password",
  },
  userManagement: {
    name: "User Management",
    link: "/user-management",
  },
  travelPolicy: {
    name: "Travel Policies",
    link: "/travel-policies",
  },
  createTravelPolicy: {
    name: "Create Travel Policy",
    link: "/create-travel-policy",
  },
  editTravelPolicy: {
    name: "Edit Travel Policy",
    link: "/edit-travel-policy",
  },
  approvalProcess: {
    name: "Approval Process",
    link: "/approval-process",
  },
  trips: {
    name: "Trips",
    link: "/trips",
  },
  tripSummary: {
    name: "Trip Summary",
    link: "/trips/summary?id=",
  },
  searchFlight: {
    name: "Search Flight",
    link: "/search/flights",
  },
  checkout: {
    confirmTravelers: {
      name: "Confirm Travelers",
      link: "/checkout/traveler",
    },
    summary: {
      name: "Summary",
      link: "/checkout/summary",
    },
  },
  setting: {
    name: "Setting",
    link: "/user/settings",
  },
  loyalty: {
    name: "Loyalty",
    link: "/user/loyalty-program",
  },
  createApprovalProcess: {
    name: "Create Approval Process",
    link: "/create-approval-process",
  },
  editApprovalProcess: {
    name: "Edit Approval Process",
    link: "/edit-approval-process",
  },
  approval: {
    checkout: {
      name: "Trip Summary",
      link: "/trips/summary",
    },
  },
};

export type NavLinks = {
  title: string;
  link: string;
  icon: string;
  iconWhite: string;
};

export const NAV_LINKS: NavLinks[] = [
  {
    title: pageLinks.home.name,
    link: pageLinks.home.link,
    icon: icons.dashboard.color,
    iconWhite: icons.dashboard.white,
  },

  {
    title: pageLinks.userManagement.name,
    link: pageLinks.userManagement.link,
    icon: icons.userManagement.color,
    iconWhite: icons.userManagement.white,
  },
  {
    title: pageLinks.travelPolicy.name,
    link: pageLinks.travelPolicy.link,
    icon: icons.travelPolicy.color,
    iconWhite: icons.travelPolicy.white,
  },
  {
    title: pageLinks.approvalProcess.name,
    link: pageLinks.approvalProcess.link,
    icon: icons.approvalPolicy.color,
    iconWhite: icons.approvalPolicy.white,
  },
  {
    title: pageLinks.trips.name,
    link: pageLinks.trips.link,
    icon: icons.approvalPolicy.color,
    iconWhite: icons.approvalPolicy.white,
  },
  {
    title: pageLinks.reporting.name,
    link: pageLinks.reporting.link,
    icon: icons.reporting.color,
    iconWhite: icons.reporting.white,
  },
];

export const NAV_LINKS_LIMITED: NavLinks[] = [
  {
    title: pageLinks.home.name,
    link: pageLinks.home.link,
    icon: icons.dashboard.color,
    iconWhite: icons.dashboard.white,
  },
  {
    title: pageLinks.trips.name,
    link: pageLinks.trips.link,
    icon: icons.approvalPolicy.color,
    iconWhite: icons.approvalPolicy.white,
  },
];
