import React from "react";
import { UsersInput } from "src/components";
import { Traveler } from "src/types/traveler";

import { ActionType, useSearchFlightContext } from "src/context";

const UnmemoUserListInput: React.FC = () => {
  const { dispatch, state } = useSearchFlightContext();

  const onUpdateUser = (users: Traveler[]) => {
    dispatch({
      type: ActionType.updateUsers,
      payload: users,
    });
  };

  return (
    <div className="row">
      <div className="col-lg-6">
        <UsersInput
          onUpdateUser={onUpdateUser}
          selectedUsersDefault={state.users.map(({ id }) => id)}
        />
      </div>
    </div>
  );
};

export const UserListInput = React.memo(UnmemoUserListInput);
