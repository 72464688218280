import React, { useState } from "react";
import {
  Button,
  DateInput,
  FormGroup,
  Input,
  Label,
  PhoneInput,
  SelectInput,
} from "src/components";
import { ERROR_MESSAGE, PERSON_TITLE, SUCCESS_MESSAGE } from "src/configs";
import { useAppContext, useLoadingContext, useLoginContext } from "src/context";
import { UserService } from "src/services";
import { UserEditParams } from "src/types/login";
import { onInputChange } from "src/utils";
import classes from "./EditDetails.module.scss";

type Props = UserEditParams & { onUpdate: () => void };

const UnmemoEditDetails: React.FC<Props> = ({ onUpdate, ...rest }) => {
  const { loading } = useLoadingContext();
  const { saveUser } = useLoginContext();

  const { showError, showSuccess } = useAppContext();

  const [formData, setFormData] = useState<UserEditParams>({
    ...rest,
  });

  const onDateChange = (date: Date | null) => {
    setFormData((prevData) => ({
      ...prevData,
      birthDate: `${date}`,
    }));
  };

  const onUpdateInfo = async () => {
    loading.start();

    const response = await UserService.edit(formData);
    if (response.success) {
      showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
      onUpdate();
      saveUser();
    } else {
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  return (
    <div className={classes.form}>
      <div className="row">
        <div className="col-lg-2">
          <FormGroup>
            <Label>Title</Label>
            <SelectInput
              name="title"
              value={formData.title}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              options={PERSON_TITLE}
            />
          </FormGroup>
        </div>
        <div className="col-lg-5">
          <FormGroup>
            <Label>First Name</Label>
            <Input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              placeholder="First Name"
              required
            />
          </FormGroup>
        </div>
        <div className="col-lg-5">
          <FormGroup>
            <Label>Last Name</Label>
            <Input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              placeholder="Last Name"
              required
            />
          </FormGroup>
        </div>
        <div className="col-lg-6">
          <FormGroup>
            <Label>Phone Number</Label>
            <PhoneInput
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              placeholder="Enter Phone"
              required
            />
          </FormGroup>
        </div>
        <div className="col-lg-6">
          <FormGroup>
            <Label>Date of Birth</Label>
            <DateInput
              name="birthDate"
              selected={
                formData.birthDate ? new Date(formData.birthDate) : null
              }
              onChange={(date) => onDateChange(date)}
              placeholderText="Enter Date"
              required
              limitStartFromToday={false}
            />
          </FormGroup>
        </div>
      </div>
      <div className={classes.btnBlock}>
        <Button onClick={onUpdateInfo}>Update Info</Button>
      </div>
    </div>
  );
};

export const EditDetails = React.memo(UnmemoEditDetails);
