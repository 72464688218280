import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import { SummaryCheckoutResponse } from "src/types/checkout";
import { token } from "src/utils";

type GetSummary = () => Promise<SummaryCheckoutResponse>;

const INVALID_RESPONSE: SummaryCheckoutResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const getSummary: GetSummary = async () => {
  try {
    const res = await axios.get<SummaryCheckoutResponse>(
      `${API_BASE_URL}${endpoints.checkout.order.summary}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) return res.data;

    console.error(`GET SUMMARY FOR CHECKOUT | ${SERVICE_FAILED_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(
      `GET SUMMARY FOR CHECKOUT | ${SERVICE_FAILED_MESSAGE} ${error}`
    );

    return INVALID_RESPONSE;
  }
};
