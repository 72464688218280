import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";
import {
  UpdateTravelerCheckoutParams,
  UpdateTravelerCheckoutResponse,
} from "src/types/checkout";
import { formatDateForBE, token } from "src/utils";

type UpdateTravelers = (
  params: UpdateTravelerCheckoutParams
) => Promise<UpdateTravelerCheckoutResponse>;

const INVALID_RESPONSE: UpdateTravelerCheckoutResponse = {
  success: false,
  message: ERROR_MESSAGE,
};

export const updateTravelers: UpdateTravelers = async (params) => {
  try {
    const res = await axios.post<UpdateTravelerCheckoutResponse>(
      `${API_BASE_URL}${endpoints.checkout.traveler.update}`,
      {
        travelersInfo: params.travelersInfo.map(
          ({
            id,
            title,
            firstName,
            birthDate,
            phoneNumber,
            lastName,
            passport,
            loyaltyProgram,
          }) => ({
            id,
            title,
            firstName,
            lastName,
            phoneNumber,
            birthDate: formatDateForBE(new Date(birthDate)),
            passport: {
              ...passport[0],
              expiryDate: formatDateForBE(new Date(passport[0].expiryDate)),
            },
            loyaltyProgram,
          })
        ),
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) return res.data;

    console.error(`UPDATE TRAVELERS FOR CHECKOUT | ${SERVICE_FAILED_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(
      `UPDATE TRAVELERS FOR CHECKOUT | ${SERVICE_FAILED_MESSAGE} ${error}`
    );

    return INVALID_RESPONSE;
  }
};
