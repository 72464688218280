import React from "react";
import { ComponentSize, componentSizes } from "src/types";
import classes from "./ProfilePlaceholder.module.scss";
import { get2Letters } from "./util";

type Props = {
  name: string;
  className: string;
  size?: ComponentSize;
};

const UnmemoProfilePlaceholder: React.FC<Props> = ({
  name,
  className,
  size = componentSizes.regular,
}) => {
  return (
    <div
      className={`${classes.image} ${className} ${classes[size]}`}
      title={name}
    >
      {get2Letters(name)}
    </div>
  );
};

export const ProfilePlaceholder = React.memo(UnmemoProfilePlaceholder);
