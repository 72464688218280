import axios from "axios";
import { API_BASE_URL, endpoints } from "src/configs";
import { token } from "src/utils";

type ReportDownload = (dateFrom: string, dateTo: string) => Promise<void>;

export const reportDownload: ReportDownload = async (dateFrom, dateTo) => {
  try {
    const res = await axios.get<ArrayBuffer>(
      `${API_BASE_URL}${endpoints.reporting.download}?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([res.data], { type: "application/octet-stream" });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report.xlsx");
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    link.remove();
  } catch (error) {
    console.error("Failed to fetch activity overview:", error);
    throw new Error("Failed to fetch activity overview");
  }
};
