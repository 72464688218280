import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout } from "src/Layout";
import {
  CenteredGrid,
  Text,
  Button,
  FormGroup,
  Label,
  Input,
  SelectInput,
} from "src/components";
import { ERROR_MESSAGE, app, pageLinks } from "src/configs";
import { useAppContext, useLoadingContext } from "src/context";
import { LoyaltyProgramsService, TravelerService } from "src/services";
import classes from "./Loyalty.module.scss";
import { componentSizes, componentVariants } from "src/types";
import { ButtonGroup } from "react-bootstrap";

const UnmemoLoyalty: React.FC = () => {
  const { loading } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();

  const [loyaltyPrograms, setLoyaltyPrograms] = useState<
    { name: string; id: number }[]
  >([]);
  const [travelerLoyaltyPrograms, setTravelerLoyaltyPrograms] = useState<
    {
      programName: string;
      id: number;
      programId: number;
      programNumber: number;
    }[]
  >([]);
  const [selectedProgramId, setSelectedProgramId] = useState<number>(0);
  const [programNumber, setProgramNumber] = useState<number>(0);
  const [editingProgram, setEditingProgram] = useState<{
    id: number;
    programNumber: number;
    programId: number;
  } | null>(null);
  const [editingProgramNumber, setEditingProgramNumber] = useState<number>(0);
  const [showAddProgramPopup, setShowAddProgramPopup] = useState<boolean>(
    false
  ); // State for popup visibility
  const [isLoading, setIsLoading] = useState(true); // Loading state for skeleton
  const options = loyaltyPrograms.map((program) => ({
    name: program.name,
    props: { value: program.id },
  }));

  const getLoyaltyPrograms = async () => {
    loading.start();
    setIsLoading(true);
    try {
      const response = await LoyaltyProgramsService.get();
      if (response.success) {
        setLoyaltyPrograms(response.programs);
      } else {
        showError(ERROR_MESSAGE);
      }
    } catch (error) {
      showError(ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
      loading.stop();
    }
  };

  const fetchTravelerLoyaltyPrograms = async (travelerId: number) => {
    loading.start();
    setIsLoading(true);
    try {
      const response = await TravelerService.getTravelerPrograms(travelerId);
      if (response.success) {
        setTravelerLoyaltyPrograms(response.programs);
      } else {
        showError(ERROR_MESSAGE);
      }
    } catch (error) {
      showError(ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
      loading.stop();
    }
  };

  const handleAddProgram = async () => {
    if (selectedProgramId && programNumber) {
      loading.start();
      try {
        const response = await TravelerService.addTravelerProgram(
          programNumber,
          selectedProgramId
        );
        if (response.success) {
          showSuccess("Program added successfully!");
          fetchTravelerLoyaltyPrograms(1); // Replace with actual traveler ID
          setSelectedProgramId(0);
          setProgramNumber(0);
          setShowAddProgramPopup(false); // Close the popup after adding
        } else {
          showError(ERROR_MESSAGE);
        }
      } catch (error) {
        showError(ERROR_MESSAGE);
      } finally {
        loading.stop();
      }
    } else {
      showError("Please select a program and enter a program number.");
    }
  };

  const handleDeleteProgram = async (programId: number) => {
    loading.start();
    try {
      const response = await TravelerService.deleteTravelerProgram(programId);
      if (response.success) {
        setTravelerLoyaltyPrograms(
          travelerLoyaltyPrograms.filter((program) => program.id !== programId)
        );
        showSuccess("Program deleted successfully!");
      } else {
        showError(ERROR_MESSAGE);
      }
    } catch (error) {
      showError(ERROR_MESSAGE);
    } finally {
      loading.stop();
    }
  };

  const handleEditProgram = async () => {
    if (editingProgram && editingProgramNumber) {
      loading.start();
      try {
        const response = await TravelerService.updateTravelerProgram(
          editingProgram.id,
          editingProgramNumber,
          editingProgram.programId
        );
        if (response.success) {
          showSuccess("Program updated successfully!");
          fetchTravelerLoyaltyPrograms(1); // Replace with actual traveler ID
          setEditingProgram(null);
          setEditingProgramNumber(0);
        } else {
          showError(ERROR_MESSAGE);
        }
      } catch (error) {
        showError(ERROR_MESSAGE);
      } finally {
        loading.stop();
      }
    } else {
      showError("Please enter a program number.");
    }
  };

  useEffect(() => {
    getLoyaltyPrograms();
    const travelerId = 1; // Replace with actual traveler ID
    fetchTravelerLoyaltyPrograms(travelerId);
  }, []);

  return (
    <Layout title={`${pageLinks.loyalty.name} - ${app.name}`}>
      <CenteredGrid>
        <div className={classes.group}>
          <div className={classes.title}>
            <Text tag="h1">Loyalty Programs</Text>
            <Button onClick={() => setShowAddProgramPopup(true)}>
              Add Program
            </Button>
          </div>
          <div className={classes.content}>
            {isLoading ? (
              <Skeleton count={5} height={30} width={`100%`} />
            ) : travelerLoyaltyPrograms.length > 0 ? (
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>
                      <h5>Program Name</h5>
                    </th>
                    <th>
                      <h5>Program Number</h5>
                    </th>
                    <th>
                      <h5>Actions</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {travelerLoyaltyPrograms.map((program) => (
                    <tr key={program.id}>
                      <td>{program.programName}</td>
                      <td>
                        {editingProgram && editingProgram.id === program.id ? (
                          <input
                            type="number"
                            value={editingProgramNumber || ""}
                            onChange={(e) =>
                              setEditingProgramNumber(
                                parseInt(e.target.value) || 0
                              )
                            }
                          />
                        ) : (
                          program.programNumber
                        )}
                      </td>
                      <td>
                        {editingProgram && editingProgram.id === program.id ? (
                          <div className={classes.buttongroup}>
                            <Button onClick={handleEditProgram}>Save</Button>
                            <Button
                              onClick={() => setEditingProgram(null)}
                              variant={componentVariants.secondary}
                            >
                              Cancel
                            </Button>
                          </div>
                        ) : (
                          <>
                            <div className={classes.buttongroup}>
                              <Button
                                variant={componentVariants.secondary}
                                onClick={() => {
                                  setEditingProgram({
                                    id: program.id,
                                    programNumber: program.programNumber,
                                    programId: program.programId,
                                  });
                                  setEditingProgramNumber(
                                    program.programNumber
                                  );
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant={componentVariants.secondary}
                                onClick={() => handleDeleteProgram(program.id)}
                              >
                                Delete
                              </Button>
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <Text>You have not any programs.</Text>
            )}

            {showAddProgramPopup && (
              <>
                <div
                  className={classes.overlay}
                  onClick={() => setShowAddProgramPopup(false)}
                ></div>
                <div className={classes.popup}>
                  <h5>Add a New Program</h5>
                  <br></br>
                  <FormGroup>
                    <Label>Program Name</Label>
                    <SelectInput
                      options={[
                        {
                          name: "Select a Loyalty Program",
                          props: { value: "" },
                        },
                        ...options,
                      ]}
                      value={selectedProgramId || ""}
                      onChange={(e) =>
                        setSelectedProgramId(parseInt(e.target.value) || 0)
                      }
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Program Number</Label>
                    <Input
                      type="number"
                      value={programNumber || ""}
                      onChange={(e) =>
                        setProgramNumber(parseInt(e.target.value) || 0)
                      }
                    />
                  </FormGroup>
                  <div className={classes.buttongroup}>
                    <Button onClick={handleAddProgram}>Add Program</Button>
                    <Button
                      variant={componentVariants.secondary}
                      onClick={() => setShowAddProgramPopup(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </CenteredGrid>
    </Layout>
  );
};

export const Loyalty = React.memo(UnmemoLoyalty);
