import axios from "axios";
import {
  API_BASE_URL,
  ERROR_MESSAGE,
  SERVICE_FAILED_MESSAGE,
  endpoints,
} from "src/configs";

import { token } from "src/utils";

type Response = {
  success: boolean;
  approvalRequired: boolean;
  message?: string;
};

type CheckApproval = () => Promise<Response>;

const INVALID_RESPONSE: Response = {
  success: false,
  approvalRequired: true,
  message: ERROR_MESSAGE,
};

export const checkApproval: CheckApproval = async () => {
  try {
    const res = await axios.get<Response>(
      `${API_BASE_URL}${endpoints.checkout.approval}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token.get()}`,
        },
      }
    );

    if (res.data) return res.data;

    console.error(`CHECK APPROVAL FOR CHECKOUT | ${SERVICE_FAILED_MESSAGE}`);

    return INVALID_RESPONSE;
  } catch (error) {
    console.error(
      `CHECK APPROVAL FOR CHECKOUT | ${SERVICE_FAILED_MESSAGE} ${error}`
    );

    return INVALID_RESPONSE;
  }
};
