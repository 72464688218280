import React, { useState } from "react";
import { Layout } from "src/Layout";
import {
  Alert,
  Button,
  Card,
  CenteredWrapper,
  FormGroup,
  Input,
  Label,
  Link,
  SectionTitle,
} from "src/components";
import { app, pageLinks } from "src/configs";
import { useLoadingContext } from "src/context";
import { passwordService } from "src/services";
import { componentSizes } from "src/types";
import {
  ForgetPassword as ForgetPasswordData,
  ForgetPasswordResponse,
} from "src/types/password";
import { onInputChange } from "src/utils";
import { EmailSent } from "./components";

const UnmemoForgetPassword: React.FC = () => {
  const { loading } = useLoadingContext();
  const [formData, setFormData] = useState<ForgetPasswordData>({
    email: "",
  });

  const [response, setResponse] = useState<ForgetPasswordResponse | undefined>(
    undefined
  );

  const [isEmailSent, setEmailStatus] = useState<boolean | undefined>(
    undefined
  );
  // const { saveUser } = useForgetPasswordContext();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    loading.start();

    const response = await passwordService.forget(formData);
    setResponse(response);
    setEmailStatus(response.success);
    loading.stop();
  };

  return (
    <Layout title={`${pageLinks.forgetPassword.name} - ${app.name}`}>
      <div className="container">
        <CenteredWrapper>
          <Card padding={componentSizes.large}>
            {!isEmailSent && (
              <SectionTitle
                title="Forgot Your Password?"
                description="Enter your email address to get reset password link"
              />
            )}

            {response && !isEmailSent && (
              <FormGroup>
                <Alert type="error" isFloatTop={false}>
                  {response.message}
                </Alert>
              </FormGroup>
            )}
            {response && isEmailSent && (
              <>
                <EmailSent />
                <Button
                  onClick={() => (window.location.href = pageLinks.login.link)}
                  isFullWidth
                >
                  Login
                </Button>
              </>
            )}
            {!isEmailSent && (
              <form onSubmit={onSubmit}>
                <FormGroup marginBottom={componentSizes.large}>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => onInputChange(e, formData, setFormData)}
                    placeholder="Enter Email"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Button type="submit" isFullWidth>
                    Send Email
                  </Button>
                </FormGroup>
                <FormGroup
                  isFlex={{ direction: "row", justify: "center" }}
                  marginBottom={componentSizes.large}
                  hasMargin={false}
                >
                  <Link to={pageLinks.login.link} size={componentSizes.small}>
                    Cancel
                  </Link>
                </FormGroup>
              </form>
            )}
          </Card>
        </CenteredWrapper>
      </div>
    </Layout>
  );
};

export const ForgetPassword = React.memo(UnmemoForgetPassword);
