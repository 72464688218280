import React from "react";
import { Layout } from "src/Layout";
import { Card, CenteredWrapper, SectionTitle } from "src/components";
import { app, pageLinks } from "src/configs";
import { ChangePasswordForm } from "src/sections";
import { componentSizes } from "src/types";

const UnmemoChangePassword: React.FC = () => {
  return (
    <Layout title={`${pageLinks.changePassword.name} - ${app.name}`}>
      <div className="container">
        <CenteredWrapper>
          <Card padding={componentSizes.large}>
            <SectionTitle
              title="Setup Your Password"
              description="Set a new secure and secret password for your account."
            />

            <ChangePasswordForm hasOldPassword={false} />
          </Card>
        </CenteredWrapper>
      </div>
    </Layout>
  );
};

export const ChangePassword = React.memo(UnmemoChangePassword);
