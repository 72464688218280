import React from "react";

import { Text } from "src/components";
import classes from "./Footer.module.scss";

const UnmemoFooter: React.FC = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.group}>
        <Text tag="h6">© Tripcater - All Rights Reserved</Text>
      </div>
      {/* <div className={classes.group}>
        <Link to={""} variant={componentVariants.secondary}>
          <Text tag="h6">Privacy</Text>
        </Link>
      </div>
      <div className={classes.group}>
        <Link to={""} variant={componentVariants.secondary}>
          <Text tag="h6">Terms</Text>
        </Link>
      </div>
      <div className={classes.group}>
        <Link to={""} variant={componentVariants.secondary}>
          <Text tag="h6">FAQ</Text>
        </Link>
      </div>
      <div className={classes.group}>
        <Link to={""} variant={componentVariants.secondary}>
          <Text tag="h6">Settings</Text>
        </Link>
      </div> */}
    </div>
  );
};

export const Footer = React.memo(UnmemoFooter);
