import React from "react";

import { Text } from "src/components";
import { Traveler } from "src/types/traveler";

type Props = {
  travelers: {
    id: Traveler["id"];
    name: string;
    profilePictureUrl: Traveler["profilePictureUrl"];
  }[];
};

const UnmemoTravelerName: React.FC<Props> = ({ travelers }) => {
  return (
    <Text tag="h5">
      {(travelers.length > 2 ? travelers.slice(0, 2) : travelers).map(
        ({ name }, i) => (
          <span key={i}>
            {name}
            {travelers.length !== i + 1 ? "," : ""}{" "}
          </span>
        )
      )}
      {travelers.length > 2 ? <> + {travelers.length - 2}</> : <></>}
    </Text>
  );
};

export const TravelerName = React.memo(UnmemoTravelerName);
