export const hasValidLength = (value: string) => value.length >= 8;

export const hasNumber = (value: string) => /\d/.test(value);

export const hasSpecialCharacter = (value: string) =>
  /[!@#$%^&*(),.?":{}|<>]/.test(value);

export const hasMixedCase = (value: string) => {
  const hasUppercase = /[A-Z]/.test(value);
  const hasLowercase = /[a-z]/.test(value);
  return hasUppercase && hasLowercase;
};

export const isPasswordValid = (value: string) => {
  let strength = 0;

  if (hasValidLength(value)) {
    strength += 1;
  }

  if (hasNumber(value)) {
    strength += 1;
  }

  if (hasSpecialCharacter(value)) {
    strength += 1;
  }

  if (hasMixedCase(value)) {
    strength += 1;
  }

  return strength;
};
