import { enGB } from "date-fns/locale";
import React, { useState, useEffect, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { reportDownload } from "src/services/Reporting/reportDownload";
import classes from "./ReportDownload.module.scss";

const UnmemoReportDownload: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !(dropdownRef.current as any).contains(event.target)) {
      setShowCalendar(false);
    }
  };

  const handleOptionSelect = async (option: string) => {
    setSelectedOption(option);
    setShowCalendar(option === "Custom");

    if (option !== "Custom") {
      await handleDownload(option);
    }
  };
    const handleDownload = async (option: string) => {
    try {
      let dateFrom = "";
      let dateTo = "";
      const currentDate = new Date();
      switch (option) {
        case "Last Week":
          const lastWeekStartDate = new Date(
            currentDate.getTime() - 6 * 24 * 60 * 60 * 1000
          ); // Start of last week (7 days ago)
          const lastWeekEndDate = new Date(
            currentDate.getTime() - 24 * 60 * 60 * 1000
          ); // End of last week (1 day ago)
          dateFrom = getFormattedDate(lastWeekStartDate);
          dateTo = getFormattedDate(lastWeekEndDate);
          break;
        case "Last Month":
          const lastMonthStartDate = new Date(
            currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
          ); // Start of last 30 days
          const lastMonthEndDate = new Date(
            currentDate.getTime() - 24 * 60 * 60 * 1000
          ); // End of yesterday
          dateFrom = getFormattedDate(lastMonthStartDate);
          dateTo = getFormattedDate(lastMonthEndDate);
          break;
        case "Last 3 Months":
          const last3MonthsStartDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - 3,
            currentDate.getDate()
          ); // Start of 3 months ago
          const last3MonthsEndDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
          ); // End of current month
          dateFrom = getFormattedDate(last3MonthsStartDate);
          dateTo = getFormattedDate(last3MonthsEndDate);
          break;
        default:
          break;
      }
  
      dateTo = getFormattedDate(currentDate);
  
      // Send the request only if dates are set
      if (dateFrom && dateTo) {
        await reportDownload(dateFrom, dateTo);
      } else {
        console.error("Please select dates.");
      }
    } catch (error) {
      console.error("Error downloading report:", error);
    }
  };
  
  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  const getFormattedDate = (date: Date) => {
    return formatDate(date);
  };
  const handleReportDownload = async () => {
    const { startDate, endDate } = dateRange[0];
    const formattedStartDate = getFormattedDate(startDate);
    const formattedEndDate = getFormattedDate(endDate);
    await reportDownload(formattedStartDate, formattedEndDate);
  };
  


  return (
    <div ref={dropdownRef}>
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic">
        {selectedOption || "Download Report"}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleOptionSelect("Last Week")}>
            Last Week
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleOptionSelect("Last Month")}>
            Last Month
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleOptionSelect("Last 3 Months")}>
            Last 3 Months
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleOptionSelect("Custom")}>
            Custom
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {showCalendar && (
        <div className={classes.calendarContainer}>
          <div className={classes.dateRangeWrapper}>
            <DateRange
              className={classes.range}
              editableDateInputs={true}
              onChange={(item) => {
                if (item.selection.startDate && item.selection.endDate) {
                  const startDate = item.selection.startDate as Date;
                  const endDate = item.selection.endDate as Date;
                  setDateRange([{ startDate, endDate, key: "selection" }]);
                }
              }}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              locale={enGB}
            />
            <Button
              className={classes.dateButton}
              onClick={handleReportDownload}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export const ReportDownload = React.memo(UnmemoReportDownload);
