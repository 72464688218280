import React, { ChangeEvent, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import {
  AirplaneInput,
  Button,
  ColorInput,
  FormGroup,
  Input,
  Label,
  SelectInput,
  Text,
  Toggle,
} from "src/components";
import { CABINS, ERROR_MESSAGE, SUCCESS_MESSAGE, pageLinks } from "src/configs";
import { useAppContext, useLoadingContext, useLoginContext } from "src/context";
import { TravelPolicyService } from "src/services";
import { componentVariants } from "src/types";
import { Airline } from "src/types/flight";
import {
  Colors,
  CreateTravelPolicyParams,
  TravelPolicyFull,
  budgetDirection,
  budgetType,
  cabin,
  colors,
} from "src/types/travelPolicy";
import { onInputChange } from "src/utils";
import classes from "./PolicyForm.module.scss";

const UnmemoPolicyForm: React.FC = () => {
  const location = useLocation();
  const urlQuery = new URLSearchParams(location.search);

  const editId = urlQuery.get("id");

  const { loading } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();
  const { updatePolicies } = useLoginContext();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<CreateTravelPolicyParams>({
    name: "",
    themeColor: colors.green,
    flightDynamicBudgetValue: "10",
    flightDynamicBudgetDirection: budgetDirection.moreThen,
    flightDynamicBudgetType: budgetType.median,
    flightMaxCabinClass: cabin.economy,
    flightLongerLegHours: 0,
    flightMaxCabinClassLongerLeg: cabin.economy,
    flightIsRefundable: false,
    flightExtrasRules: {
      preferredAirlines: [],
      restrictedAirlines: [],
    },
  });

  const getEditData = async (id: TravelPolicyFull["id"]) => {
    loading.start();

    const response = await TravelPolicyService.getToEdit(id);

    if (response.success && response.travelPolicy) {
      setFormData({ ...formData, ...response.travelPolicy });
    } else showError(response.message ? response.message : ERROR_MESSAGE);

    loading.stop();
  };

  useEffect(() => {
    if (editId) {
      getEditData(Number(editId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const onColorChange = (color: Colors) => {
    setFormData({ ...formData, themeColor: color });
  };

  const createPolicy = async (e: any) => {
    e.preventDefault();
    loading.start();

    const response = editId
      ? await TravelPolicyService.update({
          ...formData,
          flightLongerLegHours: Number(formData.flightLongerLegHours),
        })
      : await TravelPolicyService.create({
          ...formData,
          flightLongerLegHours: Number(formData.flightLongerLegHours),
        });
    if (response.success) {
      showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
      updatePolicies();
    } else showError(response.message ? response.message : ERROR_MESSAGE);

    loading.stop();
  };

  const onClose = () => {
    navigate(pageLinks.travelPolicy.link);
  };

  return (
    <div className={classes.formWrapper}>
      <form onSubmit={createPolicy}>
        <Label>Policy Name</Label>
        <div className="row">
          <div className="col-lg-9">
            <FormGroup>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={(e) => onInputChange(e, formData, setFormData)}
                placeholder="Enter Policy Name"
                required
              />
            </FormGroup>
          </div>
          <div className="col-lg-3">
            <FormGroup>
              <ColorInput
                type="text"
                name="themeColor"
                value={formData.themeColor}
                onColorChange={onColorChange}
                required
              />
            </FormGroup>
          </div>
        </div>

        {/* <Label>Maximum Budget is</Label>
        <div className="row">
          <div className="col-lg-3">
            <FormGroup>
              <SelectInput
                name="flightDynamicBudgetValue"
                required
                defaultValue={formData.flightDynamicBudgetValue}
                onChange={onSelectChange}
                options={BUDGET_VALUES}
              />
            </FormGroup>
          </div>
          <div className="col-lg-3">
            <FormGroup>
              <SelectInput
                name="flightDynamicBudgetDirection"
                required
                defaultValue={formData.flightDynamicBudgetDirection}
                onChange={onSelectChange}
                options={BUDGET_DIRECTIONS}
              />
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className={classes.horizontalLabel}>
                <Text>The</Text>
                <SelectInput
                  name="flightDynamicBudgetType"
                  required
                  defaultValue={formData.flightDynamicBudgetType}
                  onChange={onSelectChange}
                  options={BUDGET_TYPES}
                />
              </div>
            </FormGroup>
          </div>
        </div> */}

        <Label>Cabin Limit</Label>
        <div className="row">
          <div className="col-lg-6">
            <FormGroup>
              <SelectInput
                name="flightMaxCabinClass"
                required
                defaultValue={formData.flightMaxCabinClass}
                onChange={(e) => onInputChange(e, formData, setFormData)}
                options={CABINS}
              />
            </FormGroup>
          </div>
        </div>

        <FormGroup>
          <Text>Allow Cabin If flight Longer than this hours</Text>
        </FormGroup>

        <Label>Hours</Label>
        <div className="row">
          <div className="col-lg-3">
            <FormGroup>
              <Input
                type="text"
                name="flightLongerLegHours"
                value={formData.flightLongerLegHours}
                onChange={(e) => onInputChange(e, formData, setFormData)}
                placeholder="No of Hours"
              />
            </FormGroup>
          </div>
          <div className="col-lg-9">
            <FormGroup>
              <div className={classes.horizontalLabel}>
                <Text>Then</Text>
                <SelectInput
                  name="flightMaxCabinClassLongerLeg"
                  required
                  defaultValue={formData.flightMaxCabinClassLongerLeg}
                  onChange={(e) => onInputChange(e, formData, setFormData)}
                  options={CABINS}
                />
              </div>
            </FormGroup>
          </div>
        </div>

        <FormGroup>
          <Label isMainTitle>Ticket Refundable/ Non Refundable</Label>
          <Label>Choose the refund policy</Label>
        </FormGroup>
        <FormGroup>
          <div className={`${classes.horizontalLabel} ${classes.notFull}`}>
            <Text>No</Text>
            <Toggle
              name="flightIsRefundable"
              checked={formData.flightIsRefundable}
              onChange={onCheckChange}
            />
            <Text>Yes</Text>
          </div>
        </FormGroup>

        <FormGroup>
          <Label isMainTitle>More Options</Label>
        </FormGroup>

        <FormGroup>
          <Label>Preferred Airline</Label>
          <AirplaneInput
            selectedFlights={formData.flightExtrasRules?.preferredAirlines}
            onInputChange={(airlines: Airline[]) => {
              setFormData({
                ...formData,
                flightExtrasRules: {
                  ...formData.flightExtrasRules,
                  preferredAirlines: airlines,
                },
              });
            }}
          />
        </FormGroup>

        <FormGroup>
          <Label>Restricted Airline</Label>
          <AirplaneInput
            selectedFlights={formData.flightExtrasRules?.restrictedAirlines}
            onInputChange={(airlines: Airline[]) => {
              setFormData({
                ...formData,
                flightExtrasRules: {
                  ...formData.flightExtrasRules,
                  restrictedAirlines: airlines,
                },
              });
            }}
          />
        </FormGroup>

        <div className={classes.footer}>
          <Button
            variant={componentVariants.secondary}
            onClick={() => onClose()}
          >
            Close
          </Button>
          <Button type="submit">{editId ? "Update" : "Create"}</Button>
        </div>
      </form>
    </div>
  );
};

export const PolicyForm = React.memo(UnmemoPolicyForm);
