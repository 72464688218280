export const componentVariants = {
  primary: "primary",
  secondary: "secondary",
} as const;

export type ComponentVariant =
  (typeof componentVariants)[keyof typeof componentVariants];

export const componentSizes = {
  small: "small",
  regular: "regular",
  medium: "medium",
  large: "large",
} as const;

export type ComponentSize =
  (typeof componentSizes)[keyof typeof componentSizes];

export type CustomErrors = {
  hasError: boolean;
  message: string;
};

export type CustomSuccess = {
  message: string;
};

export type TableConfigs = {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  firstItem: number;
  lastItem: number;
};

export const orderBy = {
  ascending: "asc",
  descending: "desc",
} as const;

export type OrderBy = (typeof orderBy)[keyof typeof orderBy];

export type Pagination = TableConfigs & {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageIndex: number;
};

export type BasicResponse = {
  success: boolean;
  message?: string;
};
