import React from "react";

import { Accordion, Card, LabelValue, Profile, Text } from "src/components";
import { TravelerCheckout } from "src/types/checkout";
import classes from "./TravelerDetail.module.scss";

const UnmemoTravelerDetail: React.FC<TravelerCheckout> = ({
  birthDate,
  email,
  firstName,
  lastName,
  passport,
  phoneNumber,
  title,
  profilePictureUrl,
  loyaltyProgram,
}) => {
  return (
    <Card>
      <Accordion
        head={
          <Profile
            name={`${title ? `${title} ` : ""} ${firstName} ${lastName}`}
            profilePictureUrl={profilePictureUrl}
          />
        }
      >
        <Text tag="h3" className={classes.title}>
          Passenger details
        </Text>
        <div className={classes.details}>
          <div className={classes.detail}>
            <LabelValue
              label="Name"
              value={`${title ? `${title} ` : ""} ${firstName} ${lastName}`}
            />
          </div>
          <div className={classes.detail}>
            <LabelValue label="Email" value={email} />
          </div>
          <div className={classes.detail}>
            <LabelValue label="Date of Birth" value={birthDate} />
          </div>
          <div className={classes.detail}>
            <LabelValue label="Phone" value={phoneNumber} />
          </div>
        </div>

        <Text tag="h3" className={classes.title}>
          Passport details
        </Text>
        <div className={classes.details}>
          <div className={classes.detail}>
            <LabelValue label="Number" value={passport[0].number} />
          </div>
          <div className={classes.detail}>
            <LabelValue label="Expiry Date" value={passport[0].expiryDate} />
          </div>
          <div className={classes.detail}>
            <LabelValue label="Country" value={passport[0].issueCountry} />
          </div>
        </div>

        {loyaltyProgram ? (
          <>
            <Text tag="h3" className={classes.title}>
              Loyalty Program
            </Text>
            <div className={classes.details}>
              <div className={classes.detail}>
                <LabelValue
                  label="Program Name"
                  value={loyaltyProgram.programName}
                />
              </div>
              <div className={classes.detail}>
                <LabelValue
                  label="Program Number"
                  value={loyaltyProgram.programNumber}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </Accordion>
    </Card>
  );
};

export const TravelerDetail = React.memo(UnmemoTravelerDetail);
