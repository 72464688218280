import { TableConfigs } from "src/types";
import { TravelerInformation, roles, status } from "src/types/traveler";

export const DEFAULT_INFORMATION: TravelerInformation = {
  title: "",
  firstName: "",
  lastName: "",
  gender: "",
  birthDate: "",
  email: "",
  phoneNumber: "",
  roleId: 0,
  roleName: roles.guest,
  status: status.inActive,
  id: 0,
  profilePictureUrl: "",
  policyId: 0,
};

export const DEFAULT_TABLE_CONFIGS: TableConfigs = {
  pageNumber: 1,
  pageSize: 0,
  totalItems: 0,
  totalPages: 1,
  firstItem: 1,
  lastItem: 1,
};
