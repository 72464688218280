import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "src/Layout";
import {
  Alert,
  Button,
  Card,
  CenteredWrapper,
  Checkbox,
  FormGroup,
  Input,
  Label,
  Link,
  PasswordInput,
  SectionTitle,
  Image
} from "src/components";
import { app, pageLinks, assets } from "src/configs";
import { useLoadingContext, useLoginContext } from "src/context";
import { AuthService } from "src/services";
import { componentSizes } from "src/types";
import { Login as LoginData, LoginResponse } from "src/types/login";
import { onInputChange } from "src/utils";
import classes from "./Login.module.scss";

const UnmemoLogin: React.FC = () => {
  const { loading } = useLoadingContext();
  const navigate = useNavigate();
  const { login } = useLoginContext();

  const [formData, setFormData] = useState<LoginData>({
    email: "",
    password: "",
  });
  const [response, setResponse] = useState<LoginResponse | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    const response = await AuthService.login(formData);
    setResponse(response);

    if (response.success && response.token) {
      login(response.token);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (response?.success && response.token) {
      navigate(pageLinks.home.link);
    }
  }, [response, navigate]);

  return (
    <Layout title={`${pageLinks.login.name} - ${app.name}`}>
      {isLoading && ( // Full-page loading spinner
        <div className={classes.fullPageSpinner}>
          <Image src={assets.spinner} width={50} height={50} alt="Loading" />
        </div>
      )}
      <div className="container">
        <CenteredWrapper>
          <Card padding={componentSizes.large}>
            <SectionTitle
              title="Welcome Back"
              description="Please login to book and see your trips."
            />

            <form onSubmit={onSubmit}>
              {response && !response.success && (
                <FormGroup>
                  <Alert type="error" isFloatTop={false}>
                    {response.message}
                  </Alert>
                </FormGroup>
              )}
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => onInputChange(e, formData, setFormData)}
                  placeholder="Enter Email"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Password:</Label>
                <PasswordInput
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={(e) => onInputChange(e, formData, setFormData)}
                  placeholder="Enter Password"
                  required
                  showEye
                />
              </FormGroup>
              <FormGroup
                isFlex={{ direction: "row", justify: "space-between" }}
                marginBottom={componentSizes.large}
              >
                <Checkbox label="Remember Me" />
                <Link
                  to={pageLinks.forgetPassword.link}
                  size={componentSizes.small}
                >
                  Forget Password?
                </Link>
              </FormGroup>
              <Button type="submit" isFullWidth>
                Login
              </Button>
            </form>
          </Card>
        </CenteredWrapper>
      </div>
    </Layout>
  );
};

export const Login = React.memo(UnmemoLogin);