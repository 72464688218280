import React, { useState } from "react";
import {
  FormGroup,
  IconAndTitle,
  Input,
  Popup,
  SelectInput,
} from "src/components";
import { ERROR_MESSAGE, SUCCESS_MESSAGE, assets } from "src/configs";
import { useAppContext, useLoadingContext, useLoginContext } from "src/context";
import { TravelerService } from "src/services";
import { TravelPolicy } from "src/types/travelPolicy";
import { TravelerInviteParams, roles, rolesSystemName } from "src/types/traveler";
import { onInputChange } from "src/utils";

type Props = {
  onHide: () => void;
  travelPolicies?: TravelPolicy[];
};

const UnmemoInviteForm: React.FC<Props> = ({ onHide, travelPolicies }) => {
  const { loading } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();
  const { updateUsers } = useLoginContext();

  const [inviteFormData, setInviteFormData] = useState<TravelerInviteParams>({
    emails: "",
    roleName: "",
    travelPolicyId: 0,
  });

  const inviteUsers = async () => {
    loading.start();
    if (inviteFormData.emails && inviteFormData.roleName) {
      const response = await TravelerService.invite({
        ...inviteFormData,
      });
      if (response.success) {
        showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
        updateUsers();
      } else showError(response.message ? response.message : ERROR_MESSAGE);
    } else {
      showError("Please fill all fields!");
    }
    loading.stop();
  };
  return (
    <Popup
      title="Invite User"
      onClose={{ ctaAction: onHide }}
      cta={{ ctaName: "Invite User", ctaAction: inviteUsers }}
    >
      <IconAndTitle
        image={assets.inviteUser}
        description="Add your team to Trip Cater by inviting them via email."
      />

      <FormGroup>
        <Input
          type="text"
          name="emails"
          value={inviteFormData.emails}
          onChange={(e) => onInputChange(e, inviteFormData, setInviteFormData)}
          placeholder="Enter Emails (Separated by ',')"
          required
        />
      </FormGroup>

      {travelPolicies && travelPolicies.length > 0 && (
        <FormGroup>
          <SelectInput
            name="travelPolicyId"
            required
            defaultValue={
              inviteFormData.travelPolicyId === 0
                ? ""
                : inviteFormData.travelPolicyId
            }
            placeholder="Travel Policy to Assign (Optional)"
            onChange={(e) =>
              onInputChange(e, inviteFormData, setInviteFormData)
            }
            options={
              travelPolicies
                ? travelPolicies.map((policy) => ({
                    name: policy.name,
                    props: { value: policy.id },
                  }))
                : []
            }
          />
        </FormGroup>
      )}

      <FormGroup>
        <SelectInput
          name="roleName"
          required
          defaultValue={inviteFormData.roleName}
          placeholder="Role to Assign"
          onChange={(e) => onInputChange(e, inviteFormData, setInviteFormData)}
          options={[
            {
              name: roles.corporateAdmin,
              props: { value: rolesSystemName.corporateAdmin },
            },
            {
              name: roles.travelerManager,
              props: { value: rolesSystemName.travelManager },
            },
            { name: roles.traveler, props: { value: rolesSystemName.traveler } },
            { name: roles.guest, props: { value: rolesSystemName.guest } },
          ]}
        />
      </FormGroup>
    </Popup>
  );
};

export const InviteForm = React.memo(UnmemoInviteForm);
