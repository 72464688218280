import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Button, FormGroup, Label, PasswordInput } from "src/components";
import { useAppContext, useLoadingContext, useLoginContext } from "src/context";
import { passwordService } from "src/services";
import { componentSizes } from "src/types";
import { ChangePassword, ChangePasswordResponse } from "src/types/password";

import { isPasswordValid } from "src/components/Input/components/utils";
import { ERROR_MESSAGE, SUCCESS_MESSAGE, pageLinks } from "src/configs";
import { onInputChange } from "src/utils";
import classes from "./ChangePasswordForm.module.scss";

type Props = {
  ctaText?: string;
  isCTAFullWidth?: boolean;
  isInApp?: boolean;
  hasOldPassword?: boolean;
};

const UnmemoChangePasswordForm: React.FC<Props> = ({
  ctaText,
  isCTAFullWidth = true,
  isInApp = false,
  hasOldPassword = true,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const { loading } = useLoadingContext();
  const { user } = useLoginContext();
  const { showError, showSuccess } = useAppContext();

  const [formData, setFormData] = useState<ChangePassword>({
    confirmPassword: "",
    newPassword: "",
    oldPassword: "",
    token: searchParams.get("token") || "",
    travelerIdentifier: searchParams.get("travelerIdentifier") || "",
    id: isInApp ? user.id : 0,
  });

  const [response, setResponse] = useState<ChangePasswordResponse | undefined>(
    undefined
  );

  const onSubmit = async (e: any) => {
    e.preventDefault();
    loading.start();

    const response = isInApp
      ? await passwordService.changeInApp(formData)
      : await passwordService.change(formData);
    setResponse(response);

    if (response.success) {
      showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
      if (!isInApp) navigate(pageLinks.login.link);
    } else {
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  const isEqual = formData.newPassword === formData.confirmPassword;

  const isValid = isPasswordValid(formData.newPassword) === 4;

  return (
    <>
      {response && !response.success && (
        <FormGroup>
          <Alert type="error" isFloatTop={false}>
            {response.message}
          </Alert>
        </FormGroup>
      )}

      <form onSubmit={onSubmit}>
        {hasOldPassword && (
          <FormGroup>
            <Label>Old Password:</Label>
            <PasswordInput
              type="password"
              name="oldPassword"
              value={formData.oldPassword}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              placeholder="Enter Password"
              required
              showEye
            />
          </FormGroup>
        )}
        <FormGroup>
          <Label>New Password:</Label>
          <PasswordInput
            type="password"
            name="newPassword"
            value={formData.newPassword}
            onChange={(e) => onInputChange(e, formData, setFormData)}
            placeholder="Enter Password"
            required
            showEye
            checkPassword
          />
        </FormGroup>
        {!isInApp && (
          <FormGroup marginBottom={componentSizes.large}>
            <Label>Confirm Password:</Label>
            <PasswordInput
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={(e) => onInputChange(e, formData, setFormData)}
              placeholder="Enter Password"
              required
              showEye
              error={
                isEqual
                  ? undefined
                  : "The confirm password should be equal to new password"
              }
            />
          </FormGroup>
        )}
        <div className={classes.btnBlock}>
          <Button
            type="submit"
            isFullWidth={isCTAFullWidth}
            disabled={!(isInApp ? isValid : isEqual && isValid)}
          >
            {ctaText || "Sign Up"}
          </Button>
        </div>
      </form>
    </>
  );
};

export const ChangePasswordForm = React.memo(UnmemoChangePasswordForm);
