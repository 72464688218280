import React from "react";

import classes from "./DataTableLayout.module.scss";

type Props = {
  children: React.ReactNode;
  input: React.ReactNode;
  actions: React.ReactNode;
};

const UnmemoDataTableLayout: React.FC<Props> = ({
  children,
  input,
  actions,
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.input}>{input}</div>
        <div className={classes.actions}>{actions}</div>
      </div>
      <div className={classes.body}>{children}</div>
    </div>
  );
};

export const DataTableLayout = React.memo(UnmemoDataTableLayout);
